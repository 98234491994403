<div class="h-12 w-full flex mt-2 toolbar relative pb-2">
	<img src="/assets/mmi-logo.jpg" alt="MMI Logo" class="h-12" />
	<div
		(click)="navigate(data)"
		*ngFor="let data of navOptions"
		class="ml-4 flex items-center hover:bg-gray-100 hover:rounded-lg ml-4 p-2 cursor-pointer"
	>
		<div class="flex items-center">
			<span class="w-max text-md md:text-lg">{{ data.text }}</span>
		</div>
	</div>
	<div class="w-full"></div>
	<button
		matBadgeSize="small"
		matBadge="2"
		matBadgeColor="warn"
		[matBadgeHidden]="false"
		mat-icon-button
		[matMenuTriggerFor]="notifications"
	>
		<mat-icon> notification_important</mat-icon>
	</button>
	<mat-menu #notifications="matMenu">
		<div class="p-2">
			<div class="mt-2">
				<button mat-button>New Quote for Adidas Created</button>
			</div>
			<div class="mt-2">
				<button mat-button>Quote for Adidas Approved</button>
			</div>
		</div>
		<button mat-menu-item>Clear Notifications</button>
	</mat-menu>

	<button mat-icon-button [matMenuTriggerFor]="adminOptions">
		<mat-icon> settings</mat-icon>
	</button>
	<mat-menu #adminOptions="matMenu">
		<button disabled mat-menu-item>Clients</button>
		<button routerLink="/users" mat-menu-item>Users</button>
		<button disabled mat-menu-item>Contact</button>
		<button disabled mat-menu-item>Parts</button>
		<button disabled mat-menu-item>Price Level</button>
		<button disabled mat-menu-item>Install Locations</button>
		<button disabled mat-menu-item>Carriers</button>
		<button disabled mat-menu-item>Accessorials</button>
		<button disabled mat-menu-item>Handling Units</button>
	</mat-menu>

	<button mat-icon-button [matMenuTriggerFor]="userOptions" class="mr-4">
		<div
			class="h-8 w-8 relative -top-1 -left-1 text-white text-sm items-center justify-center flex rounded-[50%]"
			[style.background-color]="getColor(user?.name)"
		>
			{{ getInitials(user?.name) }}
		</div>
	</button>
	<mat-menu #userOptions="matMenu">
		<button (click)="logout()" mat-menu-item>Logout</button>
	</mat-menu>
</div>
