import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {ShipmentComponent} from './projects/project-freight/shipment/shipment.component';
import {LoginComponent} from './core/components/login/login.component';
import {ProjectComponent} from './project/project.component';
import {ProjectsComponent} from './projects/projects.component';
import {ProjectRackSolidComponent} from './projects/project-rack-solid/project-rack-solid.component';
import {ProjectQuoteComponent} from './projects/project-quote/project-quote.component';
import {ProjectCreateComponent} from './project-create/project-create.component';
import {ProjectFreightComponent} from './projects/project-freight/project-freight.component';
import {ProjectDraftingComponent} from './project/project-drafting/project-drafting.component';
import {ProjectOverviewComponent} from './project/project-overview/project-overview.component';
import {ProjectActivityComponent} from './project/project-activity/project-activity.component';
import {ProjectSchedulingComponent} from './projects/project-scheduling/project-scheduling.component';
import {ProjectEstimateComponent} from './projects/project-estimate/project-estimate.component';
import {UsersListComponent} from './users/users-list/users-list.component';
import {QuoteHeaderListComponent} from './quote/quote-header-list/quote-header-list.component';
import {QuoteComponent} from './quote/quote.component';
import {ChangePasswordRequestComponent} from './core/components/change-password-request/change-password-request.component';
import {FirstLoginResetPasswordComponent} from './core/components/first-login-reset-password/first-login-reset-password.component';

const routes: Routes = [
	{
		path: '',
		redirectTo: 'projects',
		pathMatch: 'full'
	},
	{path: 'login', component: LoginComponent},
	{path: 'forgot-password', component: ChangePasswordRequestComponent},
	{path: 'new-login', component: FirstLoginResetPasswordComponent},

	{
		path: 'project/:projectId',
		component: ProjectComponent,
		children: [
			{
				path: '',
				redirectTo: 'overview',
				pathMatch: 'full'
			},
			{
				path: 'overview',
				component: ProjectOverviewComponent
			},
			{
				path: 'activity',
				component: ProjectActivityComponent
			},
			{
				path: 'drafting',
				component: ProjectDraftingComponent
			},
			{
				path: 'quote',
				children: [
					{path: '', component: ProjectQuoteComponent},
					{path: 'new', component: ProjectQuoteComponent},
					{path: ':quoteId', component: ProjectQuoteComponent},
					{path: ':quoteId/rack-solid', component: ProjectRackSolidComponent},
					{path: ':quoteId/create-order', component: ProjectQuoteComponent}
				]
			},
			{
				path: 'freight',
				children: [
					{path: '', component: ProjectFreightComponent},
					{path: 'shipment', component: ShipmentComponent, children: [{path: ':shipmentId', component: ShipmentComponent}]}
				]
			},
			{
				path: 'create-order',
				component: ProjectQuoteComponent
			},
			{
				path: 'estimate',
				component: ProjectEstimateComponent
			},
			{
				path: 'scheduling',
				component: ProjectSchedulingComponent
			}
		]
	},
	{path: 'projects', component: ProjectsComponent},
	{path: 'create-project', component: ProjectCreateComponent},
	{
		path: 'quote',
		component: QuoteComponent,
		children: [
			{
				path: '',
				pathMatch: 'full',
				component: QuoteHeaderListComponent
			},
			{
				path: 'new',
				component: ProjectQuoteComponent
			}
		]
	},
	{path: 'rack-solid', component: ProjectRackSolidComponent},
	{path: 'users', component: UsersListComponent}
];

@NgModule({
	imports: [
		RouterModule.forRoot(routes, {
			paramsInheritanceStrategy: 'always',
			scrollPositionRestoration: 'enabled'
		})
	],
	exports: [RouterModule]
})
export class AppRoutingModule {}
