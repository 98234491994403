import {Component, OnInit, AfterViewInit} from '@angular/core';
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {Quote} from '../../core/models/quote.interface';
import {QuoteService} from '../../core/services/quote.service';
import {PriceLevel} from '../../core/models/price-level.interface';
import {PriceLevelService} from '../../core/services/price-level.service';
import {Observable} from 'rxjs';
import {PartService} from '../../core/services/part.service';
import {Part} from '../../core/models/part.interface';
import {QuotePart} from '../../core/models/quote-part.interface';
import {ConfigTypeEnum} from '../../core/enums/config-type.enum';
import {SnackbarActionEnum} from '../../core/enums/snackbar-action.enum';
import {ConfigType} from '../../core/models/type-options.model';
import {Location, TitleCasePipe} from '@angular/common';
import {QuoteConfigShelf} from '../../core/models/quote-config-shelf.interface';
import {QuoteConfig} from '../../core/models/quote-config.interface';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Project} from '../../core/models/project.interface';
import {MatDialog} from '@angular/material/dialog';
import {ProjectsService} from '../../core/services/projects.service';
import {Template} from '../../core/models/template.interface';
import {TemplatesService} from '../../core/services/templates.service';
import {ClientService} from '../../core/services/client.service';
import {TemplateCreateDialogComponent} from '../../shared/components/template-create-dialog/template-create-dialog.component';
import {TemplateTypeEnum} from '../../core/enums/template-type.enum';
import {QuoteTemplateCreate} from '../../core/models/quote-template-create.interface';

@Component({
	selector: 'app-project-rack-solid',
	templateUrl: './project-rack-solid.component.html',
	styleUrls: ['./project-rack-solid.component.scss']
})
export class ProjectRackSolidComponent implements OnInit, AfterViewInit {
	quoteForm: FormGroup = new FormGroup({
		projectId: new FormControl(null),
		id: new FormControl(null),
		isShelvingOnly: new FormControl(false),
		needsExtraParts: new FormControl(false),
		b2bKits: new FormControl(null),
		staticStarters: new FormControl(null),
		staticAdders: new FormControl(null),
		priceLevelId: new FormControl(null),
		configs: this.fb.array([]),
		parts: new FormControl([])
	});
	quote: Quote = {};
	project: Project | null;
	backupProject: Project;
	selectedTemplate: Template;
	templateOptions: Template[] = [];
	isLoading: Observable<boolean>;
	projects: Observable<Project[]>;
	partCatalog: Observable<Part[]> = new Observable<Part[]>();
	priceLevels: Observable<PriceLevel[]> = new Observable<PriceLevel[]>();
	typeOptions: ConfigType[] = [];
	partArray: Part[] = [];
	partArrayErp: Part[] = [];
	typePlaceholder: ConfigTypeEnum = ConfigTypeEnum.WIRE_GRID;

	constructor(
		private fb: FormBuilder,
		private partService: PartService,
		private quoteService: QuoteService,
		private projectsService: ProjectsService,
		private priceLevelService: PriceLevelService,
		private templatesService: TemplatesService,
		private clientService: ClientService,
		private titleCase: TitleCasePipe,
		private route: ActivatedRoute,
		private router: Router,
		private location: Location,
		private dialog: MatDialog,
		private snackbar: MatSnackBar
	) {}

	ngOnInit() {
		this.isLoading = this.projectsService.projectsWithQuotesLoading;
		this.partCatalog = this.partService.partCatalog;
		this.priceLevels = this.priceLevelService.priceLevels;
		this.buildTypeOptions();
		this.getParts();

		this.route.params.subscribe((params: Params) => {
			if (params['projectId']) {
				this.projectsService.project.subscribe({
					next: (project: Project | null) => (this.project = project),
					error: (err) => console.error(err)
				});
			}
			if (params['quoteId']) {
				this.quoteService.findOne(params['quoteId']).subscribe((quote: Quote) => {
					this.quote = quote;
					this.initializeQuote();
					if (this.project) {
						this.initializeTemplates(this.project.clientId);
					}
				});
			}
		});
	}

	ngAfterViewInit() {
		setTimeout((_) => this.initializeQuote());
	}

	getParts(): void {
		this.partCatalog.subscribe({
			next: (parts: Part[]) => {
				this.partArray = parts;
			},
			error: (err) => {
				console.error({err});
			}
		});
	}

	get shelves(): Part[] {
		return this.partArray.filter((part: Part) => part.category === 'shelves');
	}

	buildTypeOptions(): void {
		const enumKeys: string[] = Object.keys(ConfigTypeEnum);
		const enumValues: ConfigTypeEnum[] = Object.values(ConfigTypeEnum);
		enumKeys.forEach((enumKey, index) => {
			this.typeOptions.push({
				name: this.titleCase.transform(enumKey.replace('_', ' ')),
				type: enumValues[index]
			});
		});
	}

	buildQuoteForm(quote: Quote): void {
		this.quoteForm.patchValue(quote);

		this.configs.clear();
		if (!quote.configs) {
			this.addConfig();
		}

		this.addConfigControls(quote.configs ?? []);
		this.quoteForm.get('parts')?.patchValue(this.quote.parts);
	}

	addConfigControls(configs: QuoteConfig[]) {
		configs?.forEach((config: QuoteConfig) => {
			const configForm: FormGroup = this.fb.group({
				name: [config.name, [Validators.required]],
				height: [config.height],
				shelves: [config.shelves],
				type: [config.type, [Validators.required]],
				levelsToSplit: [config.levelsToSplit],
				thickLevels: [config.thickLevels],
				hangNoFront: [config.hangNoFront],
				hangFront: [config.hangFront],
				hangShelf: [config.hangShelf],
				addFtbDrh: [config.addFtbDrh],
				quoteConfigShelves: this.fb.array([])
			});
			config.quoteConfigShelves?.forEach((shelfConfig: QuoteConfigShelf) => {
				const shelfConfigForm: FormGroup = this.fb.group({
					partId: [shelfConfig.partId, [Validators.required]],
					starters: [shelfConfig.starters],
					adders: [shelfConfig.adders]
				});
				(configForm.controls['quoteConfigShelves'] as FormArray).push(shelfConfigForm);
			});
			this.configs.push(configForm);
		});
	}

	initializeQuote(): void {
		if (this.quote) {
			this.buildQuoteForm(this.quote);
			return;
		}
		this.addConfig();
	}

	initializeTemplates(clientId: string): void {
		this.clientService.findAllTemplatesForClient(clientId, 'quote_config').subscribe((options) => {
			this.templateOptions = options;
		});
	}

	templateSelection(template: Template) {
		//Make call to the template service to get the project template
		this.templatesService.findTemplateEntity<Quote>(template.id).subscribe({
			next: (quote: Quote) => {
				delete quote.id;
				this.buildQuoteForm(quote);
				this.quoteForm.markAsDirty();
				this.snackbar.open(`${template.description} loaded.`, SnackbarActionEnum.SUCCESS);
			},
			error: (error) => {
				console.error(error);
				this.snackbar.open('Failed to Load Template', SnackbarActionEnum.ERROR);
			}
		});
	}

	saveTemplate(): void {
		const dialog = this.dialog.open(TemplateCreateDialogComponent);
		dialog.componentInstance.templateOptions = this.templateOptions;

		dialog.afterClosed().subscribe((result) => {
			if (result?.templateName) {
				this.createTemplate(result.templateName);
			}
			if (result?.selectedTemplate) {
				this.updateTemplate(result.selectedTemplate);
			}
		});
	}

	getQuoteTemplateValues(): Quote {
		const currentQuote: Quote = this.quoteForm.value;
		return {
			isShelvingOnly: currentQuote.isShelvingOnly,
			b2bKits: currentQuote.b2bKits,
			needsExtraParts: currentQuote.needsExtraParts,
			staticStarters: currentQuote.staticStarters,
			staticAdders: currentQuote.staticAdders,
			configs: currentQuote.configs
		};
	}

	createTemplate(description: string): void {
		const templateCreate: QuoteTemplateCreate = {
			quote: this.getQuoteTemplateValues(),
			template: {
				clientId: this.project?.clientId,
				description,
				type: TemplateTypeEnum.QUOTE_CONFIG
			}
		};
		this.quoteService.createTemplate(this.quote.id ?? 0, templateCreate).subscribe({
			next: (template: Template) => {
				this.templateOptions.push(template);
				this.selectedTemplate = template;
				this.snackbar.open('Template created.', SnackbarActionEnum.SUCCESS);
			},
			error: (err) => {
				console.error(err);
				this.snackbar.open('Failed to Save Quote Template', SnackbarActionEnum.ERROR);
			}
		});
	}

	updateTemplate(template: Template): void {
		const templateUpdate: QuoteTemplateCreate = {
			quote: this.getQuoteTemplateValues(),
			template
		};
		this.quoteService.updateTemplate(this.quote.id ?? 0, templateUpdate).subscribe({
			next: (template: Template) => {
				this.selectedTemplate = template;
			},
			error: (err) => {
				console.error(err);
				this.snackbar.open('Failed to Update Quote Template', SnackbarActionEnum.ERROR);
			}
		});
	}

	returnToQuote() {
		if (this.project) {
			this.router.navigate([`/project/${this.project.id}/quote/${this.quote.id}`]);
		}
	}

	get quoteFormControl() {
		return this.quoteForm.controls;
	}

	addConfig(): void {
		const config: FormGroup = this.fb.group({
			name: ['', [Validators.required]],
			height: [''],
			shelves: [null],
			type: [this.typePlaceholder, [Validators.required]],
			levelsToSplit: [null],
			thickLevels: [null],
			hangNoFront: [null],
			hangFront: [null],
			hangShelf: [null],
			addFtbDrh: [null],
			quoteConfigShelves: this.fb.array([])
		});
		this.configs.push(config);
	}

	get configs(): FormArray {
		return this.quoteForm.controls['configs'] as FormArray;
	}

	copyConfig(configIndex: number): void {
		const oldConfig: QuoteConfig = this.configs.at(configIndex).getRawValue();
		this.addConfigControls([oldConfig]);
	}

	deleteConfig(configIndex: number): void {
		this.configs.removeAt(configIndex);
	}

	updateQuoteParts(quoteParts: QuotePart[]): void {
		this.quoteForm.controls['parts'].setValue(quoteParts);
	}

	saveQuote() {
		if (this.isPristine(true)) {
			return;
		}

		if (this.formIsValid()) {
			Object.assign(this.quote, this.quoteForm.value);

			this.quote.configs?.forEach((config, configIndex) =>
				config.quoteConfigShelves?.forEach((shelfConfig, shelfConfigIndex) => {
					if (shelfConfig.partId === null) {
						if (this.quote.configs) {
							this.quote.configs[configIndex].quoteConfigShelves?.splice(shelfConfigIndex, 1);
						}
					}
				})
			);

			!this.quote.id ? this.createQuote(true) : this.updateQuote(true);
		}
	}

	addItemsToQuote() {
		if (this.isPristine(false)) {
			return;
		} else {
			if (this.formIsValid()) {
				Object.assign(this.quote, this.quoteForm.value);
				!this.quote.id ? this.createQuote(false) : this.updateQuote(false);
			}
		}
	}

	formIsValid(): boolean {
		if (!this.quoteForm.valid) {
			this.displayMessage('formErrors');
			return false;
		}
		return true;
	}

	isPristine(stayOnPage: boolean): boolean {
		if (this.quoteForm.pristine && !stayOnPage) {
			this.returnToQuote();
		} else if (this.quoteForm.pristine && stayOnPage) {
			this.displayMessage('upToDate');
			return true;
		}
		return false;
	}

	updateQuote(stayOnPage: boolean): void {
		this.quoteService.update(this.quote).subscribe({
			next: (quoteResponse) => {
				this.quote = quoteResponse;
				this.quoteForm.markAsPristine();
				this.projectsService.updateLocalQuoteByProjectId(quoteResponse.projectId, quoteResponse);
				!stayOnPage ? this.returnToQuote() : this.displayMessage('quoteSaved');
			},
			error: (err) => {
				console.error(err);
				this.displayMessage('saveFailed');
			}
		});
	}

	createQuote(stayOnPage: boolean) {
		this.quoteService.create(this.quote).subscribe({
			next: (quoteResponse) => {
				this.quote = quoteResponse;
				this.quoteForm.markAsPristine();
				!stayOnPage ? this.returnToQuote() : this.displayMessage('quoteSaved');
				this.projectsService.updateLocalQuoteByProjectId(quoteResponse.projectId, quoteResponse);
			},
			error: (err) => {
				this.displayMessage('saveFailed');
				console.error(err);
			}
		});
	}

	setPlaceholder(type: ConfigTypeEnum) {
		this.typePlaceholder = type;
	}

	displayMessage(option: string) {
		switch (option) {
			case 'quoteSaved':
				this.snackbar.open('Successfully saved quote', SnackbarActionEnum.SUCCESS);
				break;
			case 'upToDate':
				this.snackbar.open('Quote is up to date', SnackbarActionEnum.ALERT);
				break;
			case 'saveFailed':
				this.snackbar.open('Failed to save quote', SnackbarActionEnum.ERROR);
				break;
			case 'formErrors':
				this.snackbar.open('Please fix form errors before continuing', SnackbarActionEnum.ERROR);
				break;
		}
	}
}
