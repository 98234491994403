<div *ngIf="isLoading" class="w-full h-full flex justify-center m-2">
	<mat-spinner></mat-spinner>
</div>
<div class="p-4" *ngIf="!isLoading">
	<div class="mb-4 flex h-8 justify-start items-center">
		<span class="font-medium text-xl mr-2">Project Details</span>
		<div
			[className]="
				'status-indicator p-3 rounded-full flex w-fit h-full text-white justify-center items-center ' +
				getPillBackgroundColor(project.createdTs)
			"
		>
			<h1 class="">{{ getDaysAtStatus(project.createdTs) }}</h1>
			<p class="text-xs">days at <br />current status</p>
		</div>
	</div>
	<div class="flex">
		<div class="flex flex-wrap w-1/2">
			<div class="detail">
				<b>Site Address</b>
				<p>
					{{ project.address1 | titlecase }}<br />
					{{ project.address2 ? (project.address2 | titlecase) : null }}<br *ngIf="project.address2" />
					{{ project.city | titlecase }}&nbsp;{{ project.state | uppercase }}, &nbsp;{{ project.postal }}
				</p>
			</div>
			<div class="detail">
				<b>Project Creation Date</b>
				<p>{{ project.createdTs ? (project.createdTs | date) : 'N/A' }}</p>
			</div>
			<div class="detail">
				<b>Install Locations</b>
				<p *ngIf="!project.locations.length">None</p>
				<p *ngFor="let location of project.locations">{{ location.type.name | titlecase }} <br /></p>
			</div>
			<div class="detail grid">
				<b>Recent Activity</b>
				<span>{{ activitySorted.length >= 1 ? (activitySorted[0].description | titlecase) : 'None' }}</span>
				<i>{{
					activitySorted.length >= 1
						? (activitySorted[0].dateTime | date) + ' at ' + (activitySorted[0].dateTime | date : 'shortTime')
						: null
				}}</i>
			</div>
		</div>
		<app-project-quick-status [project]="project" class="h-full w-1/2"></app-project-quick-status>
	</div>
	<button class="font-semibold" mat-flat-button color="accent" [routerLink]="'/project/' + project.id.toString()">View Project</button>
</div>
