import {Component, EventEmitter, Input, Output} from '@angular/core';
import {User} from '../../../core/models/user.interface';
import {MatCheckboxChange} from '@angular/material/checkbox';

@Component({
	selector: 'app-user-card',
	templateUrl: './user-card.component.html',
	styleUrls: ['./user-card.component.scss']
})
export class UserCardComponent {
	@Input() readOnly: boolean = false;
	@Input() user: User;
	@Output() checked = new EventEmitter<User>();
	@Output() unchecked = new EventEmitter<number>();

	concatClientNames(): string {
		let clientNames: string[] = [];
		this.user.clients.forEach((client) => {
			clientNames.push(client.name);
		});

		return 'Clients: ' + clientNames.join(', ');
	}

	getInitials(name: string): string {
		return name
			.split(' ')
			.map((n) => n[0])
			.join('');
	}

	getColor(name: string): string {
		const color = name.split('').reduce((acc, curr) => acc + curr.charCodeAt(0), 0) % 360;
		return `hsl(${color}, 70%, 50%)`;
	}

	userSelected(event: MatCheckboxChange) {
		if (event.checked) {
			this.checked.emit(this.user);
		} else {
			this.unchecked.emit(this.user.id);
		}
	}
}
