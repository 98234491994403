<div *ngIf="isLoading" class="w-full h-full flex justify-center items-center">
	<mat-spinner></mat-spinner>
</div>
<div *ngIf="!isLoading" class="pb-4">
	<section class="w-full flex justify-center mb-2 h-6">
		<button (click)="clearActiveFilter()" mat-fab color="accent" class="text-xl absolute left-2 font-bold z-10 cursor-default">
			{{ projectPreviews.length }}
		</button>
		<mat-button-toggle-group aria-label="Statuses" class="right-2 w-3/4 mt-4 h-full z-0">
			<mat-button-toggle
				*ngFor="let status of nonZeroStatuses; index as i"
				[className]="' bg-' + colors[i] + ' h-full'"
				[ngClass]="activeFilter && activeFilter !== status.description ? 'grayed-out' : 'bg-' + colors[i]"
				[ngStyle]="{width: getWidth(status, i), minWidth: getWidth(status, i) + 1}"
				(click)="filterTable(status.id); setActiveFilter(status)"
			>
			</mat-button-toggle>
		</mat-button-toggle-group>
	</section>

	<section class="w-full h-10 flex items-center justify-center">
		<div class="w-3/4 mt-4 flex justify-around">
			<div
				*ngFor="let status of nonZeroStatuses; index as i"
				class="flex justify-center cursor-pointer min-w-fit"
				[ngClass]="activeFilter && activeFilter !== status.description ? 'gray-text' : ''"
				[ngStyle]="{width: getWidth(status, i)}"
				(click)="filterTable(status.id); setActiveFilter(status)"
			>
				<span
					[id]="status.description.toLowerCase() + '-number-indicator'"
					class="h-full text-4xl font-bold mr-1"
					*ngIf="status.projectCount !== 0"
				>
					{{ statuses[i].projectCount }}
				</span>
				<div *ngIf="status.projectCount !== 0" class="grid mt-1">
					<span class="text-md font-semibold">{{ status.description | uppercase }}</span>
					<span class="text-xs relative bottom-1">project(s)</span>
				</div>
			</div>
		</div>
	</section>

	<section class="flex flex-col justify-center items-center mt-4 h-[80vh] overflow-auto">
		<div class="flex w-full justify-center">
			<mat-form-field appearance="outline" class="w-1/2 mt-1" color="primary">
				<mat-label>Search</mat-label>
				<input id="client-searchbar" type="text" (keyup)="applyFilter($event)" placeholder="Search by project name" matInput #input />
			</mat-form-field>
			<button mat-fab color="primary" class="mt-1 ml-2" routerLink="/create-project"><mat-icon>add</mat-icon></button>
		</div>
		<div class="w-3/4 border border-gray-700 border-2 rounded h-full overflow-auto">
			<table mat-table [dataSource]="dataSource" multiTemplateDataRows class="w-full h-full" matSort>
				<!--Name Column-->
				<ng-container matColumnDef="name">
					<th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by name">Name</th>
					<td mat-cell *matCellDef="let project">
						{{ project.name }}
					</td>
				</ng-container>

				<!--Client Column-->
				<ng-container matColumnDef="clientId">
					<th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by client">Client</th>
					<td mat-cell *matCellDef="let project">
						{{ project.clientId | titlecase }}
					</td>
				</ng-container>

				<!--Stage Column-->
				<ng-container matColumnDef="stage">
					<th mat-header-cell *matHeaderCellDef>Stage</th>
					<td mat-cell *matCellDef="let project">
						{{ getStage(project.workflowStatus.subParentId) | uppercase }}
					</td>
				</ng-container>

				<!--Status Column-->
				<ng-container matColumnDef="project.workflowStatus.description">
					<th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by status">Status</th>
					<td mat-cell *matCellDef="let project">
						{{ project.workflowStatus.description }}
					</td>
				</ng-container>

				<!--Date Column-->
				<ng-container matColumnDef="installDate">
					<th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by date">Install Date</th>
					<td mat-cell *matCellDef="let project">
						{{ project.installDate ? (project.installDate | date : 'MM/dd/y') : getEstimateText(project.estimateDate) }}
					</td>
				</ng-container>

				<!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
				<ng-container matColumnDef="expandedDetail">
					<td mat-cell *matCellDef="let project" [attr.colspan]="columnsToDisplay.length">
						<div class="overflow-hidden flex" [@detailExpand]="project.expanded ? 'expanded' : 'collapsed'">
							<app-project-detail class="w-full" [projectId]="project.id" *ngIf="project.expanded"></app-project-detail>
						</div>
					</td>
				</ng-container>

				<tr mat-header-row *matHeaderRowDef="columnsToDisplay; sticky: true"></tr>
				<tr
					mat-row
					*matRowDef="let project; let i = dataIndex; columns: columnsToDisplay"
					class="project-row"
					(click)="project.expanded = !project.expanded"
				></tr>
				<tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="detail-row"></tr>
				<!-- Row shown when there is no matching data. -->
				<tr class="mat-row flex" *matNoDataRow>
					<td class="mat-cell p-4" colspan="4">No data matching the filter "{{ input.value }}"</td>
				</tr>
			</table>
		</div>
	</section>
</div>
