import {Component, OnInit} from '@angular/core';
import {ProjectsService} from '../../core/services/projects.service';
import {ProjectActivity} from '../../core/models/project-activity.interface';
import {ActivatedRoute, Params} from '@angular/router';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
	selector: 'app-project-activity',
	templateUrl: './project-activity.component.html',
	styleUrls: ['./project-activity.component.scss']
})
export class ProjectActivityComponent implements OnInit {
	projectActivities: ProjectActivity[] = [];
	projectId: number;

	constructor(private projectService: ProjectsService, private route: ActivatedRoute, private snackbar: MatSnackBar) {}

	ngOnInit(): void {
		this.route.params.subscribe((params: Params) => {
			if (params['projectId']) {
				this.projectId = params['projectId'];
				this.projectService.getProjectActivities(this.projectId).subscribe({
					next: (activities) => {
						this.projectActivities = activities;
						this.projectActivities.forEach((activity) => {
							activity.userInitial = activity.user ? activity.user.name.charAt(0).toUpperCase() : '?';
							const date = new Date(activity.dateTime);
							activity.dateTimeFormatted = `on ${date.toLocaleDateString()} at ${date.toLocaleTimeString()}`;
						});
					},
					error: (error) => {
						this.snackbar.open('Failed to load Project Activities: ' + error.error.message);
					}
				});
			}
		});
	}
}
