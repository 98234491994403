import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {UsersListComponent} from './users-list/users-list.component';
import {RouterModule} from '@angular/router';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatTableModule} from '@angular/material/table';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatSortModule} from '@angular/material/sort';
import {SharedModule} from '../shared/shared.module';
import {UsersListDetailComponent} from './users-list-detail/users-list-detail.component';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';
import {ReactiveFormsModule} from '@angular/forms';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatDialogModule} from '@angular/material/dialog';
import {MatRadioModule} from '@angular/material/radio';
import {CreateUserComponent} from './create-user/create-user.component';
import {MatSelectModule} from '@angular/material/select';

@NgModule({
	declarations: [UsersListComponent, UsersListDetailComponent, CreateUserComponent],
	imports: [
		CommonModule,
		RouterModule,
		MatProgressSpinnerModule,
		ReactiveFormsModule,
		MatButtonModule,
		MatFormFieldModule,
		MatIconModule,
		MatInputModule,
		MatTableModule,
		MatPaginatorModule,
		MatSnackBarModule,
		MatSortModule,
		SharedModule,
		MatRadioModule,
		MatDialogModule,
		MatSelectModule
	]
})
export class UsersModule {}
