import {Component, Input, OnInit} from '@angular/core';
import {Project} from '../../core/models/project.interface';
import {ProjectsService} from '../../core/services/projects.service';
import {ProjectActivity} from '../../core/models/project-activity.interface';
import {formatDistanceToNowStrict} from 'date-fns';

@Component({
	selector: 'app-project-detail',
	templateUrl: './project-detail.component.html',
	styleUrls: ['./project-detail.component.scss']
})
export class ProjectDetailComponent implements OnInit {
	@Input() projectId: number;
	project: Project;
	activitySorted: ProjectActivity[];
	isLoading: boolean = true;

	constructor(private projectsService: ProjectsService) {}

	ngOnInit() {
		this.projectsService.findOne(this.projectId).subscribe((response) => {
			this.project = response;
			this.activitySorted = this.project.activity.sort((a: ProjectActivity, b: ProjectActivity) => {
				return new Date(b.dateTime).getTime() - new Date(a.dateTime).getTime();
			});
			this.isLoading = false;
		});
	}

	getDaysAtStatus(createdTs: Date): number {
		if (!this.activitySorted.length) {
			const created = new Date(createdTs);
			return +formatDistanceToNowStrict(created).split(' ')[0];
		} else {
			const lastChangedDate = new Date(this.activitySorted[0].dateTime);
			return +formatDistanceToNowStrict(lastChangedDate, {unit: 'day'}).split(' ')[0];
		}
	}

	getPillBackgroundColor(createdTs: Date): string {
		const difference = this.getDaysAtStatus(createdTs);
		switch (true) {
			case difference <= 3:
				return 'bg-lightGreen';
			case difference > 3 && difference <= 7:
				return 'bg-yellow';
			case difference > 7:
				return 'bg-red';
			default:
				return 'bg-gray';
		}
	}
}
