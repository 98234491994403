import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ClientSiteComponent} from './components/client-site/client-site.component';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatSelectModule} from '@angular/material/select';
import {MatInputModule} from '@angular/material/input';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {ContactComponent} from './components/contact/contact.component';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';
import {HandlingUnitComponent} from './components/handling-unit/handling-unit.component';
import {AccessorialsComponent} from './components/accessorials/accessorials.component';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatChipsModule} from '@angular/material/chips';
import {AutoFocusDirective} from './directives/auto-focus.directive';
import {IsDecimalDirective} from './directives/is-decimal-directive.directive';
import {UserCardComponent} from './components/user-card/user-card.component';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatCardModule} from '@angular/material/card';
import {MatTooltipModule} from '@angular/material/tooltip';
import {ConfirmationDialogComponent} from './components/confirmation-dialog/confirmation-dialog.component';
import {MatDialogModule} from '@angular/material/dialog';
import {ValidationErrorsComponent} from './components/validation-errors/validation-errors.component';
import {CommaRemovalPipe} from './pipes/comma-removal.pipe';
import {ContactsComponent} from './components/contacts/contacts.component';
import {ContactsInputComponent} from './components/contacts/contacts-input/contacts-input.component';
import {SortPipe} from './pipes/sort.pipe';
import {StatePipe} from './pipes/state.pipe';
import {TemplateCreateDialogComponent} from './components/template-create-dialog/template-create-dialog.component';
import {PhonePipe} from './pipes/phone.pipe';

@NgModule({
	declarations: [
		ClientSiteComponent,
		ContactComponent,
		AccessorialsComponent,
		HandlingUnitComponent,
		AutoFocusDirective,
		IsDecimalDirective,
		UserCardComponent,
		ConfirmationDialogComponent,
		ValidationErrorsComponent,
		CommaRemovalPipe,
		ContactsComponent,
		ContactsInputComponent,
		SortPipe,
		StatePipe,
		TemplateCreateDialogComponent,
		PhonePipe
	],
	exports: [
		ClientSiteComponent,
		ContactComponent,
		AccessorialsComponent,
		HandlingUnitComponent,
		AutoFocusDirective,
		IsDecimalDirective,
		UserCardComponent,
		ValidationErrorsComponent,
		ContactsComponent,
		StatePipe,
		PhonePipe
	],
	imports: [
		CommonModule,
		MatFormFieldModule,
		MatInputModule,
		MatSelectModule,
		ReactiveFormsModule,
		MatProgressSpinnerModule,
		MatIconModule,
		MatButtonModule,
		MatAutocompleteModule,
		MatChipsModule,
		MatCheckboxModule,
		MatCardModule,
		MatTooltipModule,
		MatDialogModule,
		FormsModule
	],
	providers: [CommaRemovalPipe, SortPipe]
})
export class SharedModule {}
