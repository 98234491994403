import {Component, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {Project, ProjectUpdate} from '../core/models/project.interface';
import {ProjectsService} from '../core/services/projects.service';
import {MatTabGroup} from '@angular/material/tabs';
import {ProjectDraftingComponent} from './project-drafting/project-drafting.component';
import {ProjectStopModalComponent} from './project-stop-modal/project-stop-modal.component';
import {MatDialog} from '@angular/material/dialog';
import {ConfirmationDialogComponent} from '../shared/components/confirmation-dialog/confirmation-dialog.component';
import {SnackbarActionEnum} from '../core/enums/snackbar-action.enum';
import {MatSnackBar} from '@angular/material/snack-bar';
import {QuickStatusEditComponent} from '../projects/project-quick-status/project-quick-status-edit/quick-status-edit.component';
import {combineLatestWith} from 'rxjs';
import {Status} from '../core/models/status.interface';
import {StatusService} from '../core/services/status.service';

@Component({
	selector: 'app-project',
	templateUrl: './project.component.html',
	styleUrls: ['./project.component.scss']
})
export class ProjectComponent implements OnInit {
	@ViewChild(ProjectDraftingComponent) draftingComponent: ProjectDraftingComponent;
	@ViewChild(MatTabGroup) matTabGroup: MatTabGroup;
	project: Project | undefined;
	projectId: number;
	form: FormGroup;
	existingEstimate: boolean = false;
	freightLink: string;
	currentTabIndex = 0;
	activeTab = 'overview';
	statuses: Status[] = [];
	stage: Status | undefined;
	constructor(
		private fb: FormBuilder,
		private route: ActivatedRoute,
		private projectsService: ProjectsService,
		private dialog: MatDialog,
		private snackBar: MatSnackBar,
		private router: Router,
		private statusService: StatusService
	) {}

	ngOnInit() {
		const currentRoute = this.router.url;
		this.getCurrentActiveTab(currentRoute);

		this.route.params.subscribe((params) => {
			this.projectId = params['projectId'];
			this.projectsService.setProject(this.projectId);
		});
		this.projectsService.project.pipe(combineLatestWith(this.statusService.statuses)).subscribe(([project, statuses]) => {
			if (project) {
				this.project = project;
			}
			if (project && statuses) {
				this.stage = this.statuses.find((status) => status.id === this.project?.workflowStatus.subParentId)!;
			}
			this.statuses = statuses;
		});
	}

	getCurrentActiveTab(route: string) {
		if (route.includes('freight')) {
			this.activeTab = 'freight';
		}
		if (route.includes('overview')) {
			this.activeTab = 'overview';
		}
		if (route.includes('quote')) {
			this.activeTab = 'quote';
		}
		if (route.includes('activity')) {
			this.activeTab = 'activity';
		}
		if (route.includes('drafting')) {
			this.activeTab = 'drafting';
		}
		if (route.includes('create-order')) {
			this.activeTab = 'create-order';
		}
	}

	getPillBackgroundColor(createdTs: Date): string {
		return 'bg-lightGreen';
	}

	mergeAndUpdateProject() {
		const mergedProject = {...this.project, ...this.form.getRawValue()};
		this.updateProject(mergedProject);
	}

	updateProject(project: ProjectUpdate) {
		this.projectsService.updateOnly(project).subscribe(
			(response) => {
				this.project = response;
			},
			(error) => {
				this.displayErrorSnackBar();
			}
		);
	}

	haltProject() {
		//If project is already halted, show confirmation modal to unhalt
		if (this.project?.halted) {
			const confirm = this.dialog.open(ConfirmationDialogComponent, {
				data: {
					title: 'Resume Project',
					message: 'Are you sure you would like to resume this project?'
				}
			});
			confirm.afterClosed().subscribe((result) => {
				if (result) {
					this.updateProject({id: this.project?.id, haltComment: '', halted: false});
				}
			});
		} else {
			//If we're HALTING then display modal to enter in comment
			//Open Stop Project Modal
			const haltDialogRef = this.dialog.open(ProjectStopModalComponent, {
				width: '500px'
			});

			haltDialogRef.afterClosed().subscribe((result) => {
				if (result) {
					this.updateProject({id: this.project?.id, haltComment: result.reason, halted: true});
				}
			});
		}
	}
	displayErrorSnackBar() {
		this.snackBar.open('Error Updating Project', SnackbarActionEnum.ERROR);
	}
	openStatusModal() {
		const dialogRef = this.dialog.open(QuickStatusEditComponent, {
			width: '300px',
			disableClose: true
		});
		dialogRef.componentInstance.currentStatusId = this.project?.workflowStatus.id ?? 0;
		dialogRef.componentInstance.currentDepartmentId = this.stage?.id ?? 0;
		dialogRef.componentInstance.allStatuses = this.statuses;

		//Listen for the update event which will
		dialogRef.componentInstance.updatesStatusId.subscribe((result) => {
			const project = <Project>this.project;
			project.workflowStatus.id = result;
			//Send only the new status ID
			this.projectsService.update({id: project.id, workflowStatus: project.workflowStatus});
			dialogRef.componentInstance.closeModal();
		});
	}
}
