import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormArray, FormGroup, FormGroupDirective} from '@angular/forms';
import {CodeService} from '../../../core/services/code.service';
import {Code} from '../../../core/models/code.model';
import {DimensionUnitEnum} from '../../../core/enums/dimension-unit.enum';
import {WeightUnitEnum} from '../../../core/enums/weight-unit.enum';

@Component({
	selector: 'app-handling-unit',
	templateUrl: './handling-unit.component.html',
	styleUrls: ['./handling-unit.component.scss']
})
export class HandlingUnitComponent implements OnInit {
	@Input() index: number = 0;
	@Input() componentFormGroupName: string = '';
	@Input() componentFormArrayName: string = '';
	@Input() huType: string = 'huType';
	@Input() length: string = 'length';
	@Input() width: string = 'width';
	@Input() height: string = 'height';
	@Input() dUnit: string = 'dUnit';
	@Input() weight: string = 'weight';
	@Input() wUnit: string = 'wUnit';
	@Input() qty: string = 'qty';
	@Input() readOnly: boolean = false;
	@Output() deleteHandlingUnitEvent = new EventEmitter<number>();
	@Output() recalculateFreightDetails = new EventEmitter<void>();
	handlingUnitForm: FormGroup | null = null;

	huTypeList: Code[] = [];
	dUnitList = Object.values(DimensionUnitEnum);
	wUnitList = Object.values(WeightUnitEnum);

	constructor(private rootFormGroup: FormGroupDirective, private codeService: CodeService) {}

	ngOnInit() {
		this.huTypeList = this.codeService.getCodes('HUTYPE');

		this.handlingUnitForm = this.rootFormGroup.form as FormGroup;
		if (this.componentFormGroupName > '') {
			this.handlingUnitForm = this.handlingUnitForm.get(this.componentFormGroupName) as FormGroup;
		}
		if (this.componentFormArrayName > '') {
			this.handlingUnitForm = (this.handlingUnitForm.controls[this.componentFormArrayName] as FormArray).controls[this.index] as FormGroup;
		}
		if (this.readOnly) {
			this.handlingUnitForm.controls[this.huType].disable();
			this.handlingUnitForm.controls[this.dUnit].disable();
			this.handlingUnitForm.controls[this.wUnit].disable();
		}
	}

	deleteHandlingUnit(): void {
		this.deleteHandlingUnitEvent.emit(this.index);
	}

	recalcFreight() {
		this.recalculateFreightDetails.emit();
	}
}
