import {Component, OnDestroy, OnInit, ViewChild, Output, EventEmitter} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {MatSort} from '@angular/material/sort';
import {MatPaginator} from '@angular/material/paginator';
import {Subscription} from 'rxjs';
import {Client} from '../../core/models/client.interface';
import {ClientService} from '../../core/services/client.service';
import {ClientPreview} from '../../core/models/client-preview.interface';

@Component({
	selector: 'app-client-search',
	templateUrl: './client-search.component.html',
	styleUrls: ['./client-search.component.scss']
})
export class ClientSearchComponent implements OnInit, OnDestroy {
	@ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
	@ViewChild(MatSort, {static: false}) sort: MatSort;
	@Output() selectedClientEmitter: EventEmitter<Client> = new EventEmitter<Client>();
	selectedClient: Client | undefined;
	clientList: ClientPreview[];
	clientsSubscription: Subscription;
	isLoadingClients: boolean = true;
	displayedColumns: string[] = ['select', 'id', 'contactName', 'location'];
	dataSource: MatTableDataSource<any>;

	constructor(private clientService: ClientService) {}

	ngOnInit() {
		this.clientsSubscription = this.clientService.findAllFromErp().subscribe((response) => {
			this.clientList = response;
			this.dataSource = new MatTableDataSource(response);
			this.dataSource.paginator = this.paginator;
			this.dataSource.sort = this.sort;
			this.isLoadingClients = false;
		});
	}

	setSelectedClient(client: Client) {
		if (this.selectedClient && this.selectedClient.id === client.id) {
			this.selectedClient = undefined;
		} else {
			this.selectedClient = client;
		}
		this.selectedClientEmitter.emit(this.selectedClient);
	}

	applyFilter(event: Event) {
		const filterValue = (event.target as HTMLInputElement).value;
		this.dataSource.filter = filterValue.trim().toLowerCase();
		if (this.dataSource.paginator) {
			this.dataSource.paginator.firstPage();
		}
	}

	ngOnDestroy(): void {
		this.clientsSubscription.unsubscribe();
	}
}
