import {Component} from '@angular/core';
import {AuthService} from '../../services/auth.service';
import {FormControl, FormGroup} from '@angular/forms';
import {MatDialog} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {HttpStatusCode} from '@angular/common/http';
import {SnackbarActionEnum} from '../../enums/snackbar-action.enum';
import {Router} from '@angular/router';

@Component({
	selector: 'app-login',
	templateUrl: './login.component.html',
	styleUrls: ['./login.component.scss']
})
export class LoginComponent {
	hide = true;
	loginForm = new FormGroup({
		username: new FormControl(''),
		password: new FormControl('')
	});

	constructor(private loginService: AuthService, private dialog: MatDialog, private snackbar: MatSnackBar, private router: Router) {}

	ngOnInit() {
		if (this.loginService.userLoggedIn) {
			this.router.navigate(['/projects']);
		}
	}
	login() {
		this.loginService.login(this.loginForm.getRawValue()).subscribe({
			next: (value) => {
				//call get current
				if (value) {
					this.loginService.getCurrentUser().subscribe((response) => {
						this.loginService.userDataSource.next(response);
					});
					this.dialog.closeAll();
				}
			},
			error: (error) => {
				this.snackbar.open(error.error?.message ?? 'Login Failed', SnackbarActionEnum.ERROR, {duration: 10000});
			}
		});
	}

	forgotPasswordRequest() {
		this.router.navigate(['/forgot-password']);
	}
}
