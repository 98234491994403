<div class="m-4">
	<div class="flex justify-end">
		<button mat-raised-button (click)="calculateTasks()" [disabled]="busy">Calc Tasks</button>
		<button mat-raised-button (click)="getTasks()" [disabled]="busy">Get Tasks</button>
	</div>
	<div class="flex flex-col self-start rounded-lg m-4" *ngIf="projectTaskForm" [formGroup]="projectTaskForm">
		<ng-container formArrayName="projectTasks">
			<div class="flex flex-row" *ngFor="let task of projectTasks.controls; let i = index" formGroupName="{{ i }}">
				<div class="flex w-20 justify-end mt-4 mr-2 font-bold">{{ taskDescription(i) }}:</div>
				<mat-form-field appearance="outline" class="w-1/2 mr-2 flex-1">
					<mat-label>Value</mat-label>
					<input matInput type="text" formControlName="value" (change)="recalculateHours(i)" />
					<mat-error>Value <strong>required</strong>.</mat-error>
				</mat-form-field>
				<mat-form-field appearance="outline" class="w-1/2 mr-2 flex-1">
					<mat-label>Hours</mat-label>
					<input matInput type="text" formControlName="hours" (change)="dirtyRecalculate(i)" />
					<mat-error>Hours <strong>required</strong>.</mat-error>
				</mat-form-field>
				<button mat-button class="mt-2 ml-2" (click)="recalculateHours(i)" [disabled]="disableCalc[i]">
					<mat-icon class="my-2">calculate</mat-icon>
				</button>
			</div>
		</ng-container>
	</div>
	<div class="flex justify-end">
		<mat-spinner *ngIf="busy" class="spinner m-2" diameter="20"></mat-spinner>
		<button mat-raised-button color="primary" (click)="updateTasks()" class="self-end" [disabled]="busy">Save</button>
	</div>
</div>
