<div class="flex flex-col flex-auto overflow-hidden max-h-full">
	<div class="flex flex-wrap p-4 gap-4 pb-0">
		<mat-form-field class="search-field" appearance="outline">
			<mat-label>Search</mat-label>
			<input matInput [(ngModel)]="filterValue" (keyup)="applyFilter()" placeholder="Broker/Carrier, Email, Status" #input />
		</mat-form-field>
		<button mat-raised-button color="primary" [disabled]="isLoading" (click)="addNewRow()" class="m-2.5">+New</button>
	</div>
	<form [formGroup]="brokerForm" (ngSubmit)="requestRates()" autocomplete="off" class="flex-shrink overflow-x-auto">
		<ng-container formArrayName="formRows">
			<table mat-table [dataSource]="dataSource" class="mat-elevation-z8 broker-table">
				<ng-container matColumnDef="select">
					<th mat-header-cell *matHeaderCellDef>
						<mat-checkbox
							(change)="$event ? toggleAllRows() : null"
							[checked]="!isNoneSelected() && isAllSelected()"
							[indeterminate]="!isNoneSelected() && !isAllSelected()"
						>
						</mat-checkbox>
					</th>
					<td mat-cell *matCellDef="let element" [formGroup]="element">
						<mat-checkbox [formControlName]="'selected'" (click)="$event.stopPropagation()" [checked]="element.value.selected">
						</mat-checkbox>
					</td>
				</ng-container>

				<!-- Name Column -->
				<ng-container matColumnDef="name">
					<th mat-header-cell *matHeaderCellDef>Broker/Carrier</th>
					<td mat-cell *matCellDef="let element; let i = index" [formGroup]="element">
						<div (click)="toggleToEdit(element, element.controls['name'])" *ngIf="!getEditable(element.controls['name']); else elseBlock">
							{{ element.controls['name'].controls['value'].value }}
						</div>
						<ng-template #elseBlock>
							<mat-form-field class="no-wrap-mat-form-field" appearance="outline" [formGroup]="element.controls['name']">
								<input appAutoFocus (blur)="toggleFromEdit(element.controls['name'])" matInput type="text" formControlName="value" />
							</mat-form-field>
						</ng-template></td
				></ng-container>

				<!-- Email Column -->
				<ng-container matColumnDef="email">
					<th mat-header-cell *matHeaderCellDef>Email</th>
					<td mat-cell *matCellDef="let element; let i = index" [formGroup]="element">
						<div (click)="toggleToEdit(element, element.controls['email'])" *ngIf="!getEditable(element.controls['email']); else elseBlock">
							{{ element.controls['email'].controls['value'].value }}
						</div>
						<ng-template #elseBlock>
							<mat-form-field class="no-wrap-mat-form-field" appearance="outline" [formGroup]="element.controls['email']">
								<input appAutoFocus (blur)="toggleFromEdit(element.controls['email'])" matInput type="text" formControlName="value" />
							</mat-form-field>
						</ng-template>
					</td>
				</ng-container>

				<!-- Status Column -->
				<ng-container matColumnDef="status">
					<th mat-header-cell *matHeaderCellDef>Status</th>
					<td mat-cell *matCellDef="let element; let i = index" [formGroup]="element">
						<div [ngClass]="getStatusClass(element)">{{ element.controls.brokerStatus.value }}</div>
					</td>
				</ng-container>

				<ng-container matColumnDef="delete">
					<th mat-header-cell *matHeaderCellDef></th>
					<td mat-cell *matCellDef="let element; let i = index" [formGroup]="element">
						<button *ngIf="element.controls?.['canDelete']" (click)="deleteRow(i)" type="button" class="ml-2">
							<mat-icon>delete</mat-icon>
						</button>
					</td>
				</ng-container>

				<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
				<tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>

				<!-- Row shown when there is no matching data. -->
				<tr class="mat-row" *matNoDataRow>
					<td class="mat-cell" colspan="4">No data matching the filter "{{ input.value }}"</td>
				</tr>
			</table>
		</ng-container>
	</form>
	<div class="flex flex-col flex-fit flex-shrink-0 flex-grow justify-end">
		<button
			class="p-4 mat-elevation-z8 get-rates"
			[disabled]="isLoading || shippingDetailsInvalid || selectedBrokers.length < 1"
			mat-raised-button
			color="primary"
			(click)="requestRates()"
		>
			GET RATES
		</button>
	</div>
	<mat-card *ngIf="isLoading" class="flex justify-center items-center">
		<mat-progress-spinner color="primary" mode="indeterminate"> </mat-progress-spinner>
	</mat-card>
</div>
