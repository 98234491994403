import {Component, Input, OnInit} from '@angular/core';
import {ControlContainer, FormBuilder, FormControl, FormGroup, FormGroupDirective} from '@angular/forms';
import {CurrencyPipe, DecimalPipe} from '@angular/common';
import {Subject} from 'rxjs';
import {CommaRemovalPipe} from '../../../shared/pipes/comma-removal.pipe';

@Component({
	selector: 'app-freight-install',
	templateUrl: './freight-install.component.html',
	styleUrls: ['./freight-install.component.scss'],
	viewProviders: [
		{
			provide: ControlContainer,
			useExisting: FormGroupDirective
		}
	]
})
export class FreightInstallComponent implements OnInit {
	@Input() itemTotal: number = 0;
	@Input() itemTotalSubject: Subject<number> = new Subject<number>();
	@Input() submitted: boolean = false;

	freightInstallForm: FormGroup = new FormGroup({});
	percentageGroup: FormGroup = this.fb.group({
		percentageControl: new FormControl<string>('27.5')
	});
	noItemTotalProvided: boolean = false;

	constructor(
		private controlContainer: ControlContainer,
		private decimalPipe: DecimalPipe,
		private fb: FormBuilder,
		private currencyPipe: CurrencyPipe,
		private format: CommaRemovalPipe
	) {}

	ngOnInit() {
		this.freightInstallForm = this.controlContainer.control as FormGroup;

		if (this.submitted) {
			this.percentageControl.disable();
		} else {
			if (!this.installCostControl.value) {
				this.zeroValueCheck();
			}
		}

		this.itemTotalSubject.subscribe((itemTotal: number) => {
			this.itemTotal = itemTotal;
			this.zeroValueCheck();
		});
	}

	zeroValueCheck() {
		if (this.itemTotal === 0) {
			this.noItemTotalProvided = true;
			this.percentageControl.disable();
			this.installCostControl.setValue('0.00');
		} else {
			this.setInstallCostValue(this.itemTotal * this.percentageValue);
		}
	}

	get installCostControl() {
		return this.freightInstallForm.controls['installCost'];
	}

	get percentageControl() {
		return this.percentageGroup.controls['percentageControl'];
	}

	get percentageValue(): number {
		return parseFloat(this.percentageControl.value) / 100.0;
	}

	setInstallCostValue(value: number) {
		value = parseFloat(value.toFixed(2));
		this.freightInstallForm.get('installCost')?.setValue(this.decimalPipe.transform(value, '.2'));
	}

	setPercentageValue(value: number) {
		this.percentageGroup.get('percentageControl')?.setValue(this.decimalPipe.transform(value.toFixed(2), '.2'));
	}

	percentageChanged() {
		this.setInstallCostValue(this.itemTotal * this.percentageValue);
	}

	estimatedCostChanged() {
		if (!this.noItemTotalProvided && this.installCostControl.value !== '') {
			this.setPercentageValue((parseFloat(this.format.transform(this.installCostControl.value, true)) / this.itemTotal) * 100.0);
		}
	}

	trailingZeroCheck() {
		this.setInstallCostValue(parseFloat(this.format.transform(this.freightInstallForm.get('installCost')?.value, true)));
	}

	get installCost(): string | null {
		const value = this.format.transform(this.installCostControl.value, true);
		return this.currencyPipe.transform(parseFloat(value));
	}
}
