import {Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {User} from '../models/user.interface';

@Injectable({
	providedIn: 'root'
})
export class UserService {
	usersSubject: Subject<User[]> = new Subject();
	constructor(private http: HttpClient) {}

	findAll(): void {
		this.http.get<any>(`${environment.url}/users`).subscribe((users) => {
			this.usersSubject.next(users);
		});
	}

	findOne(id: number): Observable<any> {
		return this.http.get<any>(`${environment.url}/users/${id}`);
	}

	findAllExternal(): Observable<any> {
		return this.http.get<any>(`${environment.url}/users/external`);
	}

	update(id: number, user: Partial<User>): Observable<any> {
		return this.http.patch<any>(`${environment.url}/users/${user.id}`, user);
	}

	create(user: Partial<User>): Observable<User> {
		return this.http.post<any>(`${environment.url}/users`, user);
	}
}
