import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {Project, ProjectUpdate} from '../models/project.interface';
import {MatSnackBar} from '@angular/material/snack-bar';
import {SnackbarActionEnum} from '../enums/snackbar-action.enum';
import {ProjectTask} from '../models/project-task.model';
import {ProjectSchedule} from '../models/project-schedule.interface';
import {ProjectActivity} from '../models/project-activity.interface';
import {Quote} from '../models/quote.interface';
import {QuoteHeader} from '../models/quote-header.interface';

@Injectable({
	providedIn: 'root'
})
export class ProjectsService {
	baseUrl: string = `${environment.url}/project`;
	projectsWithQuotesLoaded: boolean = false;

	private projectsDataSource: BehaviorSubject<Project[]> = new BehaviorSubject<Project[]>([]);
	readonly projects: Observable<Project[]> = this.projectsDataSource.asObservable();

	public projectDataSource: BehaviorSubject<Project | null> = new BehaviorSubject<Project | null>(null);
	readonly project: Observable<Project | null> = this.projectDataSource.asObservable();

	private projectsWithQuotesDataSource: BehaviorSubject<Project[]> = new BehaviorSubject<Project[]>([]);
	readonly projectsWithQuotes: Observable<Project[]> = this.projectsWithQuotesDataSource.asObservable();

	private projectsWithQuotesLoadingDataSource: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	readonly projectsWithQuotesLoading: Observable<boolean> = this.projectsWithQuotesLoadingDataSource.asObservable();

	private dateRangeOptions: any[] = [];

	constructor(private http: HttpClient, private snackbar: MatSnackBar) {
		this.createDateRangeOptions();
	}

	findAll(): void {
		this.http.get<any>(this.baseUrl).subscribe((projects: Project[]) => {
			this.projectsDataSource.next(projects);
		});
	}

	findAllWithQuotes(reload?: boolean): void {
		if (!this.projectsWithQuotesLoaded || reload) {
			this.projectsWithQuotesLoadingDataSource.next(true);
			this.http.get<Project[]>(`${this.baseUrl}/quotes`).subscribe((projects: Project[]) => {
				this.projectsWithQuotesDataSource.next(projects);
				this.projectsWithQuotesLoadingDataSource.next(false);
				this.projectsWithQuotesLoaded = true;
			});
		}
	}

	findQuotesAssociatedWithProject(id: number): Observable<QuoteHeader[]> {
		return this.http.get<QuoteHeader[]>(`${this.baseUrl}/${id}/quotes`);
	}

	updateLocalQuoteByProjectId(id: number | undefined, quote: Quote): void {
		// Get the current array of projects from the behavior subject
		const currentProjects = this.projectsWithQuotesDataSource.getValue();

		// Find the project with the matching ID
		const projectToUpdate = currentProjects.find((project) => project.id === id);

		if (!projectToUpdate) {
			return;
		}

		if (projectToUpdate?.quotes) {
			if (projectToUpdate?.quotes?.length) {
				// Update the project's quote attribute with the passed in Quote object
				let index: number = projectToUpdate.quotes.findIndex((_quote: Quote) => _quote.id === quote.id) ?? -1;
				if (index >= 0) {
					// Quote was found in array
					projectToUpdate.quotes[index] = quote;
				}
			} else {
				// Quote does not exist in array, add it
				projectToUpdate.quotes.push(quote);
			}
		} else {
			// Array does not exist, create it
			projectToUpdate.quotes = [quote];
		}
		// Emit the updated array of projects to the behavior subject
		this.projectsWithQuotesDataSource.next(currentProjects);
	}

	findOne(id: number): Observable<Project> {
		return this.http.get<any>(`${this.baseUrl}/${id}`);
	}

	clearProject(): void {
		this.projectDataSource.next(null);
	}

	setProject(id: number) {
		this.http.get<any>(`${this.baseUrl}/${id}`).subscribe((response) => {
			this.projectDataSource.next(response);
		});
	}

	update(project: ProjectUpdate, showSnackbarMessage?: string) {
		this.http.put<any>(`${this.baseUrl}/${project.id}`, project).subscribe(
			(updateProject) => {
				{
					//Update the projects with the newly updated project
					const projects: Project[] = this.projectsDataSource.getValue();
					let projectToUpdate: number = projects.findIndex((project: Project) => {
						return project.id === updateProject.id;
					});
					if (projectToUpdate !== -1) {
						//Merge original and updated project to maintain any UI set fields
						projects[projectToUpdate] = {...projects[projectToUpdate], ...updateProject};
						this.projectsDataSource.next(projects);
					}
					this.projectDataSource.next(updateProject);
					this.snackbar.open(showSnackbarMessage ? showSnackbarMessage : 'Project Updated', SnackbarActionEnum.SUCCESS);
				}
			},
			(error) => {
				this.snackbar.open('Error Updating Project', SnackbarActionEnum.ERROR);
			}
		);
	}

	updateOnly(project: ProjectUpdate): Observable<Project> {
		return this.http.put<any>(`${this.baseUrl}/${project.id}`, project);
	}

	create(project: FormData | Project): Observable<Project> {
		return this.http.post<Project>(`${this.baseUrl}`, project);
	}

	getDateRangeOptions(): any[] {
		return this.dateRangeOptions;
	}

	createDateRangeOptions() {
		let now = new Date();
		const currentMonth = now.getMonth();
		//If the date is > 15, set it to the 1st so that first half of month still an option
		now.setHours(0, 0, 0, 0);
		if (now.getUTCDate() < 15) {
			now.setDate(1);
		}

		for (let i = currentMonth; i < currentMonth + 12; i++) {
			const targetMonth = i % 12;
			const targetYear = now.getFullYear() + Math.floor(i / 12);

			const firstOfMonth = new Date(targetYear, targetMonth, 1);
			const fifteenthOfMonth = new Date(targetYear, targetMonth, 15);

			if (now <= firstOfMonth) {
				this.dateRangeOptions.push({
					date: firstOfMonth,
					description: firstOfMonth.toLocaleString('default', {month: 'long'}) + ' - First Half'
				});
			}
			if (now <= fifteenthOfMonth) {
				this.dateRangeOptions.push({
					date: fifteenthOfMonth,
					description: firstOfMonth.toLocaleString('default', {month: 'long'}) + ' - Second Half'
				});
			}
		}
	}

	getProjectTasks(id: number): Observable<ProjectTask[]> {
		return this.http.get<ProjectTask[]>(`${this.baseUrl}/${id}/tasks`);
	}

	// TODO: this shouldn't be exposed, calculation should happen on the backend when order is created
	calculateProjectTasks(id: number): Observable<ProjectTask[]> {
		return this.http.get<ProjectTask[]>(`${this.baseUrl}/${id}/calculate-tasks`);
	}

	updateProjectTasks(id: number, projectTasks: ProjectTask[]): Observable<ProjectTask[]> {
		return this.http.put<ProjectTask[]>(`${this.baseUrl}/${id}/tasks`, projectTasks);
	}

	getProjectSchedule(id: number): Observable<ProjectSchedule> {
		return this.http.get<ProjectSchedule>(`${this.baseUrl}/${id}/schedule`);
	}

	updateProjectSchedule(id: number, projectTasks: ProjectTask[]): Observable<ProjectSchedule> {
		return this.http.put<ProjectSchedule>(`${this.baseUrl}/${id}/schedule`, projectTasks);
	}

	getProjectActivities(id: number): Observable<ProjectActivity[]> {
		return this.http.get<ProjectActivity[]>(`${this.baseUrl}/${id}/activities`);
	}
}
