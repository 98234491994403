import {Component, EventEmitter, Output} from '@angular/core';
import {UserService} from '../../core/services/user.service';
import {User} from '../../core/models/user.interface';

@Component({
	selector: 'app-team-members',
	templateUrl: './team-members.component.html',
	styleUrls: ['./team-members.component.scss']
})
export class TeamMembersComponent {
	users: User[];
	filteredList: User[] = [];
	searchTerm: string;
	@Output() addUser = new EventEmitter<User>();
	@Output() removeUser = new EventEmitter<number>();

	constructor(private userService: UserService) {}

	ngOnInit() {
		this.userService.findAllExternal().subscribe((response) => {
			this.users = response;
			this.filteredList = this.users;
		});
	}

	filterUsers() {
		//Matches on if either users name, or a client they belong to startsWith the searched term
		if (!this.searchTerm) {
			this.filteredList = this.users;
			return;
		}
		// Filter the users array based in event value
		this.filteredList = this.filteredList.filter((user) => {
			//for each user clients[] entry, check the name field
			let clientMatch = false;
			user.clients.forEach((client) => {
				if (client.name.toLowerCase().startsWith(this.searchTerm.toLowerCase())) {
					clientMatch = true;
				}
			});
			return clientMatch || user.name.toLowerCase().startsWith(this.searchTerm.toLowerCase());
		});
	}

	userChecked(user: User) {
		this.addUser.emit(user);
	}

	userUnchecked(id: number) {
		this.removeUser.emit(id);
	}
}
