<div class="m-4" *ngIf="projectScheduleForm" [formGroup]="projectScheduleForm">
	<div class="flex justify-between">
		<mat-form-field appearance="outline" class="w-1/2 mr-2" subscriptSizing="dynamic">
			<mat-label class="font-medium">Install Date</mat-label>
			<input matInput [min]="minDate" [matDatepicker]="datepicker" formControlName="installDate" />
			<mat-datepicker-toggle matIconSuffix [for]="datepicker"></mat-datepicker-toggle>
			<mat-datepicker #datepicker>
				<mat-datepicker-actions>
					<button mat-button matDatepickerCancel>Cancel</button>
					<button mat-raised-button color="primary" matDatepickerApply>Apply</button>
				</mat-datepicker-actions>
			</mat-datepicker>
			<mat-error>Install Date <strong>required</strong>.</mat-error>
		</mat-form-field>
		<button mat-raised-button color="primary" [disabled]="projectScheduleForm.invalid" (click)="saveSchedule()">Save</button>
	</div>
	<div class="flex flex-col self-start rounded-lg m-4 overflow-y-auto">
		<mat-divider [ngStyle]="{width: cellWidth(visibleHeaderLength)}"></mat-divider>
		<div class="flex justify-end calendar-bg" [ngStyle]="{width: cellWidth(visibleHeaderLength)}">
			<ng-container *ngFor="let head of header">
				<div class="grow-0 shrink-0 text-center font-bold" [ngStyle]="{width: cellWidth(1)}" *ngIf="head.show">
					{{ head.value | date : 'EEEEEE' }}
					<br />
					{{ head.value | date : 'M/d' }}
				</div>
			</ng-container>
		</div>
		<mat-divider [ngStyle]="{width: cellWidth(visibleHeaderLength)}"></mat-divider>
		<div class="flex justify-end calendar-bg" [ngStyle]="{width: cellWidth(visibleHeaderLength)}">
			<div class="flex flex-col mt-2 mb-2" [ngStyle]="{width: cellWidth(transitTotal + 3)}">
				<div class="flex">
					<div class="text-center" [ngStyle]="{width: cellWidth(1)}">
						<mat-icon>inventory_2</mat-icon>
					</div>
					<div [ngStyle]="{width: cellWidth(1)}"></div>
					<div class="text-center" [ngStyle]="{width: cellWidth(1)}">
						<mat-icon>local_shipping</mat-icon>
					</div>
					<div [ngStyle]="{width: cellWidth(transitTotal - 1)}"></div>
					<div class="text-center" [ngStyle]="{width: cellWidth(1)}">
						<mat-icon>place</mat-icon>
					</div>
				</div>
				<div class="flex">
					<div class="shipping-buffer" [ngStyle]="{width: cellWidth(shippingBuffer)}"></div>
					<div class="flex justify-end transit-time" [ngStyle]="{width: cellWidth(transitTotal + 1)}">
						<button *ngIf="transitTotal > 1" mat-icon-button (click)="decreaseTransit()">
							<mat-icon class="text-sm">remove_circle</mat-icon>
						</button>
						<div class="flex flex-col justify-center items-center">
							<span class="text-xs">Transit:</span>
							<span class="font-bold">{{ transitDays }} day{{ transitDays > 1 ? 's' : '' }}</span>
						</div>
						<button mat-icon-button (click)="increaseTransit()"><mat-icon class="text-sm">add_circle</mat-icon></button>
					</div>
				</div>
			</div>
		</div>
		<ng-container formArrayName="projectTasks">
			<ng-container *ngFor="let task of projectTasks.controls; let i = index">
				<mat-divider [ngStyle]="{width: cellWidth(visibleHeaderLength)}"></mat-divider>
				<div class="flex justify-end pt-2 pb-2 calendar-bg" [ngStyle]="{width: cellWidth(visibleHeaderLength)}">
					<ng-container *ngFor="let day of productionHours | keyvalue; let pI = index">
						<div
							class="grow-0 shrink-0 text-center font-bold text-xs"
							[ngClass]="task.value.name + '-hours'"
							[ngStyle]="{width: cellWidth(1)}"
							*ngIf="header[pI].show"
						>
							{{ getProductionSchedule(header[pI].value, task.value) }}/{{ getProductionHours(header[pI].value, task.value) }}
						</div>
					</ng-container>
				</div>
				<div class="flex justify-end pb-4 calendar-bg" [ngStyle]="{width: cellWidth(visibleHeaderLength)}" [formGroupName]="i">
					<div
						class="flex flex-col"
						[ngStyle]="{width: cellWidth(1)}"
						*ngIf="projectTaskSchedule(task).length + projectTaskPadSize(task).value < visibleHeaderLength"
					>
						<div class="flex flex-col items-end">
							<button mat-icon-button (click)="shiftTaskBack(task)">
								<mat-icon class="text-sm">arrow_back</mat-icon>
							</button>
							<button mat-icon-button (click)="addDayBefore(task)">
								<mat-icon class="text-sm">add_circle</mat-icon>
							</button>
						</div>
					</div>
					<div
						class="flex flex-col bg-opacity-10 pb-2 h-24"
						[ngClass]="task.value.name"
						[ngStyle]="{width: cellWidth(projectTaskSchedule(task).length)}"
					>
						<div class="flex font-bold p-2 h-8 items-center">
							{{ task.value.name }}
							<span class="ml-2" [class.hours-scheduled-invalid]="task.get('hoursScheduled')?.invalid">
								{{ taskHours(task) }}
							</span>
							<mat-error *ngIf="task.invalid" class="ml-2 align-top">
								<mat-icon>warning</mat-icon>
							</mat-error>
						</div>
						<div class="flex">
							<ng-container formArrayName="schedule" *ngIf="projectTaskSchedule(task)">
								<div
									class="flex flex-col items-center"
									*ngFor="let schedule of projectTaskSchedule(task).controls; let sI = index"
									[ngStyle]="{width: cellWidth(1)}"
									[formGroupName]="sI"
								>
									<!-- div>
                    {{schedule.value.date | date: 'M/d'}}
                  </div -->
									<input
										matInput
										type="number"
										formControlName="hours"
										(focus)="inputFocused($event)"
										(change)="updateHours(task)"
										class="w-14 border-2 p-2 rounded text-center hours"
									/>
									<div *ngIf="schedule.invalid" class="flex items-start text-xs font-bold hours-scheduled-invalid">
										<span class="max-label">Max:</span>
										{{ maxHours(i, schedule) }}
									</div>
								</div>
							</ng-container>
						</div>
					</div>
					<div class="flex justify-start" [ngStyle]="{width: cellWidth(projectTaskPadSize(task).value)}">
						<div class="flex flex-col" *ngIf="projectTaskPadSize(task).value > transitTotal + 3">
							<button mat-icon-button (click)="shiftTaskForward(task)">
								<mat-icon class="text-sm">arrow_forward</mat-icon>
							</button>
							<button mat-icon-button (click)="addDayAfter(task)">
								<mat-icon class="text-sm">add_circle</mat-icon>
							</button>
						</div>
					</div>
				</div>
			</ng-container>
		</ng-container>
	</div>
</div>
