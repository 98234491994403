import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Code} from '../models/code.model';

@Injectable({
	providedIn: 'root'
})
export class CodeService {
	constructor(private http: HttpClient) {}

	codeMaster: IMasterData = {
		ETYPE: [
			{value: '', description: 'Any', data: 'assistant'},
			{value: 'FTL', description: 'Truck Load', data: 'local_shipping'},
			{value: 'LTL', description: 'LTL Shipment', data: 'inventory_2'}
		],
		STERM: [
			{value: 'PRE', description: 'Prepaid'},
			{value: '3RD', description: '3rd Party'}
		],
		SDIR: [
			{value: 'OUT', description: 'Outbound'},
			{value: 'IN', description: 'Inbound'}
		],
		HUTYPE: [{value: 'PALLET', description: 'Pallet', default: 1}]
	};

	getCodes(type: string): Code[] {
		return this.codeMaster[type as keyof IMasterData];
	}

	getDefault(type: string) {
		return this.codeMaster[type as keyof IMasterData].find((code) => (code.default = 1))?.value;
	}
}

interface ICode {
	id?: number;
	type?: string;
	value?: string;
	description?: string;
	data?: string;
	default?: number;
}

interface IMasterData {
	ETYPE: ICode[];
	STERM: ICode[];
	SDIR: ICode[];
	HUTYPE: ICode[];
}
