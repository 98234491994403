export class ClientSite {
	id?: number;
	clientId?: string;
	name?: string;
	address1?: string;
	address2?: string;
	city?: string;
	state?: string;
	postal?: string;
	country?: string;
}
