import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {ControlContainer, FormArray, FormGroup} from '@angular/forms';
import {DecimalPipe} from '@angular/common';
import {QuoteLine} from '../../../core/models/quote-line.interface';

@Component({
	selector: 'app-associated-costs',
	templateUrl: './associated-costs.component.html',
	styleUrls: ['./associated-costs.component.scss']
})
export class AssociatedCostsComponent implements OnInit {
	@Output() totalCostChanged = new EventEmitter<number>();
	freightInstallForm: FormGroup = new FormGroup({});
	associatedCostsTotal: number;

	constructor(private controlContainer: ControlContainer, private decimalPipe: DecimalPipe) {}

	ngOnInit() {
		this.freightInstallForm = this.controlContainer.control as FormGroup;
		this.calculateTotal();
		this.freightInstallForm.get('associatedCosts')?.valueChanges.subscribe(() => {
			this.calculateTotal();
		});
	}

	get associatedCostFormArray() {
		return this.freightInstallForm.controls['associatedCosts'] as FormArray;
	}

	calculateTotal(): void {
		const values: QuoteLine[] = this.freightInstallForm.get('associatedCosts')?.value;
		let total: number = 0;
		values.forEach((value: QuoteLine) => {
			if (value.price) {
				const valueWithCommaRemoved = this.format(value.price.toString());
				total += parseFloat(valueWithCommaRemoved);
			}
		});
		this.associatedCostsTotal = total;
		this.totalCostChanged.emit(total);
	}

	format(input: string): string {
		return input !== null && input.includes(',') ? input.replaceAll(',', '') : input;
	}

	checkForTrailingZero(index: number): void {
		const control = this.associatedCostFormArray.at(index).get('price');
		const costValue: number = parseFloat(this.format(control?.value.toString()));
		control?.setValue(this.decimalPipe.transform(costValue, '.2'));
	}
}
