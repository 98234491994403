<section class="p-2 editable" [formGroup]="form" (ngSubmit)="onSubmit()">
	<div class="mb-4 flex h-8 justify-start items-center">
		<span class="font-medium text-xl mr-2">User Detail</span>
		<div
			[ngStyle]="{backgroundColor: user.active === 1 ? 'green' : 'red'}"
			class="py-1 px-2 ml-2 rounded-full text-center text-white cursor-pointer"
			(click)="showToggleInactiveModal(pill.style.backgroundColor)"
			#pill
		>
			<p class="no-margin">{{ user.active === 1 ? 'Active' : 'Inactive' }}</p>
		</div>
		<button mat-icon-button (click)="form.enable()" [disabled]="form.enabled">
			<mat-icon>edit</mat-icon>
		</button>
	</div>
	<div class="flex pt-4">
		<div class="w-2/5 mr-2">
			<mat-form-field class="w-full mb-2" appearance="outline" (click)="form.enable()" tabindex="0">
				<mat-label>Phone No</mat-label>
				<input matInput formControlName="phone" type="tel" />
			</mat-form-field>

			<mat-form-field class="w-full" appearance="outline" (click)="form.enable()" tabindex="0">
				<mat-label>Phone Ext</mat-label>
				<input matInput formControlName="phoneExt" />
			</mat-form-field>
		</div>
		<div class="w-2/5">
			<mat-form-field class="w-full mb-2" appearance="outline" (click)="form.enable()" tabindex="0">
				<mat-label>Email</mat-label>
				<input matInput formControlName="email" />
			</mat-form-field>

			<mat-form-field class="w-full" appearance="outline" (click)="form.enable()" tabindex="0">
				<mat-label>Role</mat-label>
				<mat-select formControlName="roleId">
					<mat-option *ngFor="let role of roles" [value]="role.id">{{ role.name }}</mat-option>
				</mat-select>
			</mat-form-field>
		</div>
	</div>
	<fieldset class="w-3/5 mt-2 border border-gray-500 rounded" (click)="form.enable()">
		<legend class="ml-6 px-1 font-semibold text-gray-600">User Type*</legend>
		<mat-radio-group aria-label="Select an option" color="primary" formControlName="type" class="flex justify-around items-center">
			<mat-radio-button class="w-1/3 pl-2" [value]="option" *ngFor="let option of userTypeOptions">{{
				option | titlecase
			}}</mat-radio-button>
		</mat-radio-group>
	</fieldset>
	<div class="flex justify-end">
		<button
			*ngIf="form.enabled"
			[disabled]="form.invalid"
			mat-flat-button
			color="primary"
			type="button"
			(click)="form.dirty ? onSubmit() : form.disable()"
			class="mt-2"
		>
			Save
		</button>
	</div>
</section>
