import {Component} from '@angular/core';
import {map, Observable, startWith, switchMap} from 'rxjs';
import {Project} from '../../core/models/project.interface';
import {FormControl} from '@angular/forms';
import {ProjectsService} from '../../core/services/projects.service';
import {Router} from '@angular/router';
import {MatDialogRef} from '@angular/material/dialog';

@Component({
	selector: 'app-project-select-dialog',
	templateUrl: './project-select-dialog.component.html',
	styleUrls: ['./project-select-dialog.component.scss']
})
export class ProjectSelectDialogComponent {
	projects: Observable<Project[]>;
	filteredProjects: Observable<Project[]>;
	projectControl: FormControl = new FormControl(null);
	backupProject: Project;
	isLoading: Observable<boolean>;

	constructor(
		private projectsService: ProjectsService,
		private router: Router,
		private dialogRef: MatDialogRef<ProjectSelectDialogComponent>
	) {}

	ngOnInit() {
		this.projectsService.findAll();
		this.projects = this.projectsService.projects;

		this.filteredProjects = this.projectControl.valueChanges.pipe(
			startWith(''),
			map((value) => (typeof value === 'string' ? value : value.name)),
			switchMap((name) => (name ? this.filterProjects(name) : this.projects))
		);
	}

	filterProjects(name: string): Observable<Project[]> {
		const filterValue: string = name.toLowerCase();
		return this.projects.pipe(map((projects) => projects.filter((project) => project.name.trim().toLowerCase().match(filterValue))));
	}

	onProjectSelection(): void {
		const project = this.projectControl.value;
		if (project) {
			this.router.navigate([`/project/${project.id}/quote/new`]);
			this.dialogRef.close();
		}
	}

	projectControlDisplay(project: Project): string {
		return project?.name;
	}

	newProject(): void {
		this.router.navigate(['/create-project']);
	}
}
