<mat-expansion-panel [formGroup]="parentForm">
	<mat-expansion-panel-header>
		<mat-panel-title> Additional Info </mat-panel-title>
	</mat-expansion-panel-header>

	<span class="ml-2 mb-2"> Tax Info </span>

	<div class="flex flex-row space-x-2 mt-2">
		<mat-form-field class="w-1/2">
			<mat-label> Tax Amount </mat-label>
			<input matInput IsDecimal formControlName="taxes" #taxes (blur)="checkForTrailingZero(taxes)" />
		</mat-form-field>

		<mat-form-field class="w-1/2">
			<mat-label> Amount Exempt from Taxes </mat-label>
			<input matInput IsDecimal formControlName="taxExemptAmount" #taxExempt (blur)="checkForTrailingZero(taxExempt)" />
		</mat-form-field>
	</div>

	<div class="flex flex-row space-x-2">
		<mat-form-field class="w-1/2">
			<mat-label> Amount Subject to Sales Tax </mat-label>
			<input matInput IsDecimal formControlName="taxSubjectAmount" #taxSubject (blur)="checkForTrailingZero(taxSubject)" />
		</mat-form-field>
		<div class="w-1/2"></div>
	</div>

	<span class="ml-2 mb-2"> Team Members </span>

	<div class="flex flex-row space-x-2 w-full mt-2">
		<mat-form-field class="w-1/2">
			<mat-label> Sales Person</mat-label>
			<input matInput formControlName="salesperson" />
		</mat-form-field>

		<mat-form-field class="w-1/2">
			<mat-label> Quoter</mat-label>
			<input matInput formControlName="quoter" />
		</mat-form-field>
	</div>

	<div class="flex flex-row space-x-2 w-full">
		<mat-form-field class="w-1/2">
			<mat-label> Account Manager </mat-label>
			<input matInput formControlName="accountManager" />
		</mat-form-field>
		<div class="w-1/2"></div>
	</div>

	<span class="ml-2 mb-2">External Notes</span>

	<mat-form-field class="w-full mt-2">
		<mat-label> Description </mat-label>
		<textarea rows="3" formControlName="note" matInput></textarea>
	</mat-form-field>
</mat-expansion-panel>
