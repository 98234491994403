<div class="freight">
	<div class="flex flex-row justify-between items-center p-4">
		<mat-label class="text-2xl font-bold">Freight</mat-label>
		<div class="flex">
			<mat-spinner *ngIf="busy" class="spinner m-2" diameter="20"></mat-spinner>
			<button mat-stroked-button class="mr-2" [disabled]="busy" (click)="getFreight()"><mat-icon>refresh</mat-icon>Refresh</button>
			<button mat-raised-button color="primary" [routerLink]="['/project', projectId, 'freight', 'shipment']">New Shipment</button>
		</div>
	</div>
	<div class="flex flex-row">
		<mat-accordion class="w-3/5">
			<mat-expansion-panel
				#freightPanel
				*ngFor="let freight of freightList; let i = index"
				[(expanded)]="freight.expanded"
				(expandedChange)="expandChange($event, i)"
				[ngClass]="{'z-0 bg-gray-50': !freight.expanded, 'z-20 border-r-0': freight.expanded}"
			>
				<mat-expansion-panel-header [collapsedHeight]="'auto'" [expandedHeight]="'auto'">
					<mat-panel-title class="font-medium text-lg">
						<div>{{ freight.deliveryDate }}</div>
						<div class="flex flex-1 flex-col items-center p-4">
							<span>{{ freight.deliveryName }}</span>
							<span>{{ freight.deliveryAddress1 }}</span>
							<span>{{ freight.deliveryAddress2 }}</span>
							<div class="flex flex-row">
								{{ freight.deliveryCity }},
								{{ freight.deliveryState }}
								{{ freight.deliveryPostal }}
								{{ freight.deliveryCountry }}
							</div>
						</div>
						<div class="flex items-center">
							<div *ngIf="freight.freightId === 0" class="flex flex-col items-end">
								<span>{{ validShipments(freight) }} of {{ freight.shipments.length }}</span>
								<span>Ready to Rate</span>
							</div>
							<div *ngIf="freight.freightId && !freight.booked" class="flex flex-col items-end">
								<span>{{ freight.bidCount }} of {{ freight.rateRequestCount }}</span>
								<span>Rates Received</span>
							</div>
							<div *ngIf="freight.booked && !freight.bookAccepted" class="flex flex-col items-end">
								<span>Await Booking Confirmation</span>
							</div>
							<div *ngIf="freight.booked && freight.bookAccepted" class="flex flex-col items-end">
								<span>Booked</span>
							</div>
							<mat-icon color="warn" *ngIf="!freight.ready" class="m-2">error</mat-icon>
						</div>
					</mat-panel-title>
				</mat-expansion-panel-header>
				<mat-accordion multi>
					<mat-expansion-panel *ngIf="freight.booked" expanded>
						<mat-expansion-panel-header> Booking</mat-expansion-panel-header>
						<div class="flex flex-col">
							<mat-divider></mat-divider>
							<div *ngFor="let booking of freight.bookings; let bI = index" class="flex flex-col sm:border-b">
								<div class="flex justify-between font-bold text-xl pt-4">
									<span *ngIf="booking.freightBooking">Booked</span>
									<span *ngIf="booking.bidStatus === 'booking-requested'">Awaiting Response</span>
									<span>{{ booking.amount | currency }}</span>
								</div>
								<div class="flex justify-between">
									<div class="flex flex-col p-4">
										<span>
											Carrier: <span class="font-bold">{{ booking.carrierName }}</span>
										</span>
										<span>
											SCAC: <span class="font-bold">{{ booking.carrierScac }}</span>
										</span>
										<span>
											Frieght Bid: <span class="font-bold">{{ booking.freightBidNo }}</span>
										</span>
									</div>
									<div class="flex flex-col p-4">
										<span>
											Broker: <span class="font-bold">{{ booking.brokerName }}</span>
										</span>
										<span>
											SCAC: <span class="font-bold">{{ booking.brokerScac }}</span>
										</span>
									</div>
									<div class="flex flex-col items-end flex-grow p-4">
										<span>
											Pickup Date: <span class="font-bold">{{ booking.pickupDatetime | date : 'short' }}</span></span
										>
										<span>
											Delivery Date: <span class="font-bold">{{ booking.deliveryDatetime | date : 'short' }}</span></span
										>
										<span *ngIf="booking.transitTime">
											Transit Time: <span class="font-bold">{{ booking.transitTime }}</span>
										</span>
									</div>
								</div>
								<div *ngIf="booking.freightBooking?.comment" class="pb-2">Comments: {{ booking.freightBooking?.comment }}</div>
							</div>
						</div>
					</mat-expansion-panel>
					<mat-expansion-panel *ngIf="freight.freightId" [expanded]="!freight.booked">
						<mat-expansion-panel-header> Bids</mat-expansion-panel-header>
						<div class="flex flex-col">
							<mat-divider></mat-divider>
							<div *ngIf="freight.bidCount === 0" class="flex font-bold">No Bids</div>
							<div *ngFor="let bid of freight.bids; let bI = index" class="flex sm:border-b justify-between">
								<div class="flex flex-col p-4">
									<span>
										Carrier: <span class="font-bold">{{ bid.carrierName }}</span>
									</span>
									<span>
										SCAC: <span class="font-bold">{{ bid.carrierScac }}</span>
									</span>
									<span>
										Frieght Bid: <span class="font-bold">{{ bid.freightBidNo }}</span>
									</span>
								</div>
								<div class="flex flex-col p-4">
									<span>
										Broker: <span class="font-bold">{{ bid.brokerName }}</span>
									</span>
									<span>
										SCAC: <span class="font-bold">{{ bid.brokerScac }}</span>
									</span>
								</div>
								<div class="flex flex-col items-end flex-grow p-4">
									<span>
										Pickup Date: <span class="font-bold">{{ bid.pickupDatetime | date : 'short' }}</span></span
									>
									<span>
										Delivery Date: <span class="font-bold">{{ bid.deliveryDatetime | date : 'short' }}</span></span
									>
									<span *ngIf="bid.transitTime">
										Transit Time: <span class="font-bold">{{ bid.transitTime }}</span>
									</span>
								</div>
								<div class="flex flex-col p-4">
									<span class="text-xl font-bold">{{ bid.amount | currency }}</span>
									<button mat-raised-button color="primary" (click)="bookFreight(i, bI)" *ngIf="!freight.booked">Book</button>
								</div>
							</div>
						</div>
					</mat-expansion-panel>
					<mat-expansion-panel [expanded]="!freight.freightId">
						<mat-expansion-panel-header> Details</mat-expansion-panel-header>
						<div class="flex flex-col">
							<mat-divider></mat-divider>
							<div *ngFor="let shipment of freight.shipments; let sI = index" class="flex sm:border-b justify-between">
								<div class="flex flex-col items-center p-4">
									<span>{{ shipment.originName }}</span>
									<span>{{ shipment.originAddress1 }}</span>
									<span>{{ shipment.originAddress2 }}</span>
									<div class="flex flex-row">
										{{ shipment.originCity }},
										{{ shipment.originState }}
										{{ shipment.originPostal }}
										{{ shipment.originCountry }}
									</div>
								</div>
								<div class="flex flex-col flex-grow items-end p-4">
									<span *ngFor="let handlingUnitDetail of handlingUnitSummary(shipment.shipmentHandlingUnits) | keyvalue">
										{{ handlingUnitDetail.value }} {{ handlingUnitDetail.key }}
									</span>
									<span *ngIf="shipment.totalLinearDimension"> {{ shipment.totalLinearDimension }} {{ shipment.dUnit }} </span>
									<span *ngIf="shipment.totalWeight"> {{ shipment.totalWeight }} {{ shipment.wUnit }} </span>
								</div>
								<div class="flex items-center w-40 p-4 justify-end">
									<mat-icon color="warn" *ngIf="!shipment.valid">error</mat-icon>
									<button mat-icon-button (click)="deleteShipment(i, sI)" *ngIf="!shipment.freightId">
										<mat-icon>delete</mat-icon>
									</button>
									<button mat-icon-button [routerLink]="['/project', projectId, 'freight', 'shipment', shipment.id]">
										<mat-icon *ngIf="!shipment.freightId">edit</mat-icon>
										<mat-icon *ngIf="shipment.freightId">info</mat-icon>
									</button>
								</div>
							</div>
						</div>
					</mat-expansion-panel>
				</mat-accordion>
			</mat-expansion-panel>
		</mat-accordion>
		<div
			class="flex flex-col w-2/5 self-start sticky top-0 max-h-[calc(100vh-8rem)] mat-elevation-z8 rounded-lg z-10"
			*ngIf="selectedFreight && !selectedFreight.booked"
		>
			<div>
				<div class="flex flex-row justify-between">
					<div class="flex flex-1 justify-center items-center font-bold p-4">
						<mat-icon class="mr-2">calendar_today</mat-icon>
						{{ selectedFreight.deliveryDate }}
					</div>
					<mat-divider vertical></mat-divider>
					<div class="flex flex-1 justify-center items-center font-bold p-4">
						<mat-icon class="mr-2">schedule</mat-icon>
						{{ deliveryTime }}
					</div>
				</div>
				<mat-divider></mat-divider>
				<div class="flex flex-row justify-between">
					<div class="flex flex-1 flex-col items-center p-4" *ngIf="selectedFreight.shipments && selectedFreight.shipments.length">
						<mat-icon>place</mat-icon>
						<span>{{ selectedFreight.shipments[0].originName }}</span>
						<span>{{ selectedFreight.shipments[0].originAddress1 }}</span>
						<span>{{ selectedFreight.shipments[0].originAddress2 }}</span>
						<div class="flex flex-row">
							{{ selectedFreight.shipments[0].originCity }}
							{{ selectedFreight.shipments[0].originState }}
							{{ selectedFreight.shipments[0].originPostal }}
							{{ selectedFreight.shipments[0].originCountry }}
						</div>
					</div>
					<mat-divider vertical></mat-divider>
					<div class="flex flex-1 flex-col items-center p-4">
						<mat-icon>tour</mat-icon>
						<span>{{ selectedFreight.deliveryName }}</span>
						<span>{{ selectedFreight.deliveryAddress1 }}</span>
						<span>{{ selectedFreight.deliveryAddress2 }}</span>
						<div class="flex flex-row">
							{{ selectedFreight.deliveryCity }},
							{{ selectedFreight.deliveryState }}
							{{ selectedFreight.deliveryPostal }}
							{{ selectedFreight.deliveryCountry }}
						</div>
					</div>
				</div>
				<mat-divider></mat-divider>
				<div class="flex flex-1 justify-between font-bold p-4">
					<div class="flex flex-col">
						<span *ngFor="let handlingUnit of selectedHandlingUnits | keyvalue">{{ handlingUnit.value }} {{ handlingUnit.key }}</span>
					</div>
					<div class="flex flex-row">
						{{ selectedTotalLinearDimensions }}
						{{ selectedDUnit }}
					</div>
					<div class="flex flex-row">
						{{ selectedTotalWeight }}
						{{ selectedWUnit }}
					</div>
				</div>
			</div>
			<mat-divider></mat-divider>
			<app-broker-carrier
				class="flex relative h-full overflow-hidden"
				[projectId]="projectId"
				[shipmentIds]="validShipmentIds"
			></app-broker-carrier>
		</div>
	</div>
</div>
