<div class="m-4">
	<app-project-details
		[selectedTemplate]="selectedTemplate"
		[templateOptions]="templateOptions"
		[existingEstimate]="existingEstimate"
		[form]="form"
	></app-project-details>
	<div class="mt-4">
		<mat-expansion-panel [expanded]="false">
			<mat-expansion-panel-header>
				<mat-panel-title> External Team Members</mat-panel-title>
			</mat-expansion-panel-header>
			<div class="flex">
				<app-user-card [readOnly]="true" *ngFor="let member of project?.members" [user]="member.userInfo"></app-user-card>
				<span *ngIf="project?.members?.length === 0">No External Team Members</span>
			</div>
		</mat-expansion-panel>
	</div>
	<div class="w-full pr-4 flex justify-end items-end">
		<button (click)="saveTemplate()" class="mt-2 mr-4" mat-button>SAVE AS TEMPLATE</button>
		<button [disabled]="form.invalid" (click)="updateProject()" class="mt-2" mat-flat-button color="primary">SAVE CHANGES</button>
	</div>
</div>
