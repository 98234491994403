<mat-expansion-panel [formGroup]="parentForm" expanded>
	<mat-expansion-panel-header>
		<mat-panel-title> Approval Info </mat-panel-title>
	</mat-expansion-panel-header>
	<div *ngIf="loading">
		<mat-spinner class="scale-50"></mat-spinner>
	</div>
	<div *ngIf="!loading">
		<div *ngIf="approval" class="mb-4 ml-2 flex flex-col">
			<div class="flex flex-row space-x-2">
				<span class="font-bold"> Approved By: </span>
				<span> {{ approval.approverName }}</span>
			</div>
			<div class="flex flex-row space-x-2">
				<span class="font-bold"> Approval Date: </span>
				<span> {{ approval.createdTs | date }} </span>
			</div>
			<div *ngIf="parentForm.get('po')?.value" class="flex flex-row space-x-2">
				<span class="font-bold">Purchase Order:</span>
				<span>{{ approval.po }}</span>
			</div>
		</div>

		<div class="flex flex-row space-x-2">
			<mat-form-field *ngIf="!parentForm.get('po')?.value">
				<mat-label>PO Number</mat-label>
				<input matInput formControlName="po" />
			</mat-form-field>
			<button mat-raised-button color="primary" *ngIf="poUrl" class="align-self-end" (click)="downloadPoUpload()">VIEW PO UPLOAD</button>
		</div>
	</div>
</mat-expansion-panel>
