<div class="flex flex-col w-full items-center">
	<h1 class="flex text-2xl pt-4">Success</h1>
	<div class="content">
		<div *ngIf="!isSuccessful" class="text-base text-center">
			<p>There was an issue creating the Sales Order in Navision.</p>
			<p>{{ error.message }}</p>
		</div>
		<div class="flex flex-row justify-around" *ngIf="isSuccessful">
			<div class="flex flex-col p-4 border-solid rounded-xl border-black border-4">
				<div class="text-3xl text-center mb-2">Navision Sales Order</div>
				<hr class="h-1 mb-5 bg-dark" />
				<div class="flex flex-row">
					<div class="flex flex-col column-items pr-5">
						<div>Sales Order #</div>
						<div class="font-medium text-xl">{{ salesOrder.id }}</div>
						<div>Account Manager</div>
						<div class="font-medium text-xl">{{ salesOrder.accountManager }}</div>
						<div>Additional Charge</div>
						<div class="font-medium text-xl">{{ salesOrder.additionalChargeAmount }}</div>
						<div>Bill To Contact</div>
						<div class="font-medium text-xl">{{ salesOrder.billToContact }}</div>
						<div>Bill To Client Id</div>
						<div class="font-medium text-xl">{{ salesOrder.billToClientId }}</div>
					</div>
					<div class="flex flex-col column-items pr-5">
						<div>Bill To Name</div>
						<div class="font-medium text-xl">{{ salesOrder.billToName }}</div>
						<div>Payment Terms Code</div>
						<div class="font-medium text-xl">{{ salesOrder.paymentTermsCode }}</div>
						<div>Total Gross Weight</div>
						<div class="font-medium text-xl">{{ salesOrder.totalGrossWeight }}</div>
						<div>Total Great Weight</div>
						<div class="font-medium text-xl">{{ salesOrder.totalNetWeight }}</div>
						<div>Sell To Contact</div>
						<div class="font-medium text-xl">{{ salesOrder.sellToContact }}</div>
					</div>
					<div class="flex flex-col column-items">
						<div>Sell To Client Id</div>
						<div class="font-medium text-xl">{{ salesOrder.sellToClientId }}</div>
						<div>Sell To Name</div>
						<div class="font-medium text-xl">{{ salesOrder.sellToName }}</div>
						<div>Ship To Contact</div>
						<div class="font-medium text-xl">{{ salesOrder.shipToContact }}</div>
						<div>Ship To Name</div>
						<div class="font-medium text-xl">{{ salesOrder.shipToName }}</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="flex-box p-2">
		<button mat-raised-button class="ok-button" (click)="closeModal()">Ok</button>
	</div>
</div>
