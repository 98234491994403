<div class="p-2 flex justify-center">
	<mat-form-field appearance="outline" class="w-1/2" color="primary" subscriptSizing="dynamic">
		<mat-label>Search</mat-label>
		<input
			id="quote-searchbar"
			type="text"
			(keyup)="applyFilter($event)"
			placeholder="Search by quote number, client, or date."
			matInput
			#searchInput
		/>
	</mat-form-field>
	<button mat-raised-button color="primary" class="absolute left-0 top-0 mt-2 ml-2" (click)="openProjectSelectDialog()">
		<mat-icon>add</mat-icon>
	</button>
</div>

<div class="w-3/4 {{ projectId ? '' : 'h-[80vh]' }} border border-gray-700 border-2 rounded h-full overflow-auto m-auto">
	<table mat-table [dataSource]="dataSource" multiTemplateDataRows class="w-full" matSort *ngIf="!isLoadingQuotes">
		<!--Quote Number Column-->
		<ng-container matColumnDef="quoteNumber">
			<th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by quote number">Quote No.</th>
			<td mat-cell *matCellDef="let quote">
				{{ quote.quoteNumber ? quote.quoteNumber : 'N/A' }}
			</td>
		</ng-container>

		<!--Quote Date Column-->
		<ng-container matColumnDef="quoteDate">
			<th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by quote date">Quote Date</th>
			<td mat-cell *matCellDef="let quote">
				{{ quote.quoteDate ? (quote.quoteDate | date : 'MM/dd/y') : 'N/A' }}
			</td>
		</ng-container>

		<!--Client/Project Column-->
		<ng-container matColumnDef="project.clientId">
			<th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by client">Client</th>
			<td mat-cell *matCellDef="let quote">
				{{ quote.project.clientId | titlecase }}
			</td>
		</ng-container>

		<ng-container matColumnDef="create-order">
			<th mat-header-cell *matHeaderCellDef></th>
			<td mat-cell *matCellDef="let quote"><button mat-raised-button color="primary" (click)="goToOrder(quote)">CREATE ORDER</button></td>
		</ng-container>

		<tr mat-header-row *matHeaderRowDef="columnsToDisplay; sticky: true"></tr>
		<tr
			mat-row
			*matRowDef="let quote; let i = dataIndex; columns: columnsToDisplay"
			class="quote-row cursor-pointer"
			routerLink="/project/{{ quote.projectId }}/quote/{{ quote.id }}"
		></tr>
		<!-- Row shown when there is no matching data. -->
		<tr class="mat-row flex" *matNoDataRow>
			<td class="mat-cell p-4" colspan="4" *ngIf="!isLoadingQuotes">No data matching the filter "{{ searchInput.value }}"</td>
		</tr>
	</table>
	<div class="h-full w-full flex justify-center align-middle items-center" *ngIf="isLoadingQuotes">
		<mat-spinner></mat-spinner>
	</div>
</div>
