import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {Part} from '../models/part.interface';
import {BehaviorSubject, Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {QuoteLine} from '../models/quote-line.interface';
import {PartData} from '../models/part-data.interface';
import {QuoteLineCategoryEnum} from '../enums/quote-line-category.enum';
import {PartCategoryEnum} from '../enums/part-category.enum';
import {ConfigTypeEnum} from '../enums/config-type.enum';

@Injectable({
	providedIn: 'root'
})
export class PartService {
	baseUrl: string = `${environment.url}/part`;

	private partCatalogDataSource: BehaviorSubject<Part[]> = new BehaviorSubject<Part[]>([]);
	readonly partCatalog: Observable<Part[]> = this.partCatalogDataSource.asObservable();

	private partsDataSource: BehaviorSubject<PartData[]> = new BehaviorSubject<PartData[]>([]);
	readonly parts: Observable<PartData[]> = this.partsDataSource.asObservable();

	constructor(private http: HttpClient) {
		this.findAll();
		this.findAllFromErp();
	}

	findAll(): void {
		this.http.get<Part[]>(this.baseUrl).subscribe((parts: Part[]) => {
			this.partCatalogDataSource.next(parts);
		});
	}

	findAllFromErp(): void {
		this.http.get<QuoteLine[]>(this.baseUrl + '/erp').subscribe((parts: QuoteLine[]) => {
			const data: PartData[] = [];
			const rackSolidParts: Part[] = [];
			parts.forEach((part) => {
				// Create Parts for Manual Parts on Quote
				data.push({
					part: part,
					selected: false
				});
				// Create Parts for Rack Solid Selection
				if (part.category === QuoteLineCategoryEnum.RACK_SOLID) {
					let shelfDetails: ShelfDetails = {};

					if (part.type === PartCategoryEnum.SHELVES && part.erpItemRef) {
						shelfDetails = PartService.getShelfDetailsFromPartNumber(part.erpItemRef);
					}
					rackSolidParts.push({
						...shelfDetails,
						partNumber: part.erpItemRef,
						description: part.description,
						price: part.price,
						weight: part.weight,
						category: part.type as PartCategoryEnum,
						erpItemRef: part.erpItemRef
					});
				}
			});
			// TODO Have rack solid parts replace part catalog call
			this.partsDataSource.next(data);
		});
	}

	private static getShelfDetailsFromPartNumber(partNumber: string): ShelfDetails {
		const match = partNumber.match(/^([A-Z]+)(\d{2})(\d{2})$/);
		if (!match) {
			console.error(`Invalid input string: ${partNumber}`);
			return {};
		}

		const [, type, widthStr, depthStr] = match;
		const width = parseInt(widthStr, 10);
		const depth = parseInt(depthStr, 10);

		return {type: type as ConfigTypeEnum, width, depth};
	}
}

interface ShelfDetails {
	type?: ConfigTypeEnum;
	width?: number;
	depth?: number;
}
