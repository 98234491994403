<mat-accordion [formGroup]="parentForm">
	<mat-expansion-panel expanded="{{ this.startExpanded }}">
		<mat-expansion-panel-header>
			<mat-panel-title> Manual Part Entry </mat-panel-title>
			<mat-label *ngIf="lineItemPartsFormArray.length">{{ lineItemPartsFormArray.length }} items added</mat-label>
		</mat-expansion-panel-header>
		<mat-slide-toggle class="mb-2" color="primary" (toggleChange)="flipMobilePartsFilter()" [disabled]="submitted"
			>Show only mobile parts</mat-slide-toggle
		>
		<app-manual-parts-input
			[submitted]="submitted"
			[previouslySelectedParts]="lineItemPartsFormArray.value ?? []"
			[partDeletedSubject]="partDeletedSubject"
			[filterMobilePartsSubject]="filterMobilePartsSubject"
			(result)="selectChange($event)"
			(loadedEvent)="loaded = $event"
		>
		</app-manual-parts-input>

		<ng-container *ngIf="!lineItemPartsFormArray.length">
			<span class="ml-2 my-2 font-bold">No Parts added</span>
		</ng-container>

		<ng-container *ngIf="lineItemPartsFormArray.length">
			<span class="text-xl"> Selected {{ partType }} Parts </span>

			<div class="flex flex-row space-x-4 font-bold my-2">
				<span class="w-1/4"> Item No. </span>
				<span class="w-1/3"> Description </span>
				<span class="w-1/6"></span>
				<span class="w-1/6"></span>
			</div>

			<mat-divider class="mb-2"></mat-divider>

			<div class="mt-2">
				<div
					formArrayName="lineItemParts"
					*ngFor="let part of this.lineItemPartsFormArray.controls; let i = index"
					[formGroup]="parentForm"
				>
					<div class="flex flex-row space-x-4 space-y-1 items-center" [formGroupName]="i">
						<span class="w-1/4">{{ part.value.erpItemRef }}</span>
						<span class="w-1/3">{{ part.value.description }}</span>
						<mat-form-field class="w-1/6 mb-2">
							<mat-label> Qty </mat-label>
							<input type="number" matInput formControlName="qty" />
						</mat-form-field>
						<span class="w-1/6 -translate-y-2.5">
							<button
								(click)="delete(i)"
								[disabled]="submitted || !loaded"
								matTooltip="Disabled until parts are loaded"
								[matTooltipDisabled]="loaded"
							>
								<mat-icon *ngIf="submitted" class="text-gray-500">delete</mat-icon>
								<mat-icon *ngIf="!submitted">delete</mat-icon>
							</button>
						</span>
					</div>
				</div>
			</div>

			<div class="ml-2"></div>
		</ng-container>

		<div class="flex flex-row place-content-end space-x-2 mt-4">
			<button mat-flat-button (click)="showPartListEvent.emit(false)">HIDE FULL PART LIST</button>
			<button mat-flat-button color="primary" (click)="showPartListEvent.emit(true)">SHOW FULL PART LIST</button>
		</div>
	</mat-expansion-panel>
</mat-accordion>
