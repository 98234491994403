<div class="max-w-6xl m-4">
	<div *ngIf="project?.locations?.length">
		<app-project-file-upload
			*ngFor="let location of project.locations"
			[type]="location.type"
			[projectId]="project.id"
			[uploadedFiles]="project.documents"
			[fileChanges]="uploadedFiles"
			(fileAddEmitter)="handleFileAddEmit($event)"
			(fileRemoveEmitter)="handleFileRemoveEmit($event)"
			(documentDtoEmitter)="handleDocumentDtoEmit($event)"
		></app-project-file-upload>
	</div>
	<div *ngIf="!project?.locations?.length" class="text-center">
		<h2>No Install Locations Selected!</h2>
		<p>To upload drafting files, first select an install location on the 'Overview' tab.</p>
	</div>
	<div class="w-full pr-4 flex justify-end items-end">
		<button (click)="updateProjectDocs()" class="mt-2 mr-4" mat-flat-button color="primary" [disabled]="!files.length">SAVE</button>
		<button (click)="updateProjectStatus()" class="mt-2" mat-flat-button color="primary">READY FOR QUOTING</button>
	</div>
</div>
<div id="spinner-container" *ngIf="isLoading">
	<mat-spinner></mat-spinner>
</div>
