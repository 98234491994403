<mat-card class="w-36 h-48 m-4">
	<div class="mt-6 h-full flex flex-col justify-center text-center items-center">
		<div class="h-16 w-16 text-white text-lg items-center justify-center flex rounded-[50%]" [style.background-color]="getColor(user.name)">
			{{ getInitials(user.name) }}
		</div>
		<span class="font-semibold text-lg">{{ user.name }}</span>
		<div class="h-6">
			<div class="block" matTooltip="{{ user.clients && user.clients.length > 1 ? concatClientNames() : '' }}">
				<span class="font-medium">{{ user.clients && user.clients.length ? user.clients[0].name : '' }}</span>
				<span class="ml-2 absolute" *ngIf="user.clients && user.clients.length > 1"> +{{ user.clients.length - 1 }}</span>
			</div>
		</div>
		<span class="text-gray-500">{{ user.description }}</span>
	</div>
	<div *ngIf="!readOnly" class="flex justify-end w-full">
		<mat-checkbox color="primary" (change)="userSelected($event)"></mat-checkbox>
	</div>
</mat-card>
