import {Component, EventEmitter, Input, Output} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {Status} from '../../../core/models/status.interface';

@Component({
	selector: 'app-quick-status-edit',
	templateUrl: './quick-status-edit.component.html',
	styleUrls: ['./quick-status-edit.component.scss']
})
export class QuickStatusEditComponent {
	@Input() currentStatusId: number = 0;
	@Input() currentDepartmentId: number = 0;
	@Input() allStatuses: Status[] = [];
	@Output() updatesStatusId = new EventEmitter<number>();

	constructor(private dialog: MatDialog) {}

	ngOnInit() {}

	updateProject(): void {
		this.updatesStatusId.emit(this.currentStatusId);
		// Call service with new statusId
	}

	closeModal(): void {
		this.dialog.closeAll();
	}

	get filteredStatusOptions(): any {
		// TODO update type
		return this.allStatuses.find((status: any) => {
			return status.id === this.currentDepartmentId;
		});
	}
}
