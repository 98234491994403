import {Component, Input, OnInit} from '@angular/core';
import {ControlContainer, FormBuilder, FormControl, FormGroup, FormGroupDirective} from '@angular/forms';
import {Quote} from '../../../core/models/quote.interface';
import {CurrencyPipe, DecimalPipe} from '@angular/common';

@Component({
	selector: 'app-freight-estimator',
	templateUrl: './freight-estimator.component.html',
	styleUrls: ['./freight-estimator.component.scss'],
	viewProviders: [
		{
			provide: ControlContainer,
			useExisting: FormGroupDirective
		}
	]
})
export class FreightEstimatorComponent implements OnInit {
	@Input() quote: Quote = {};
	@Input() submitted: boolean;

	freightInstallForm: FormGroup = new FormGroup({});
	noFreightCostForm: FormGroup;

	constructor(
		private fb: FormBuilder,
		private controlContainer: ControlContainer,
		private decimalPipe: DecimalPipe,
		private currencyPipe: CurrencyPipe
	) {}

	ngOnInit(): void {
		this.freightInstallForm = this.controlContainer.control as FormGroup;

		this.noFreightCostForm = this.fb.group({
			noFreightCost: new FormControl(false)
		});

		if (this.submitted) {
			this.noFreightCostForm.get('noFreightCost')?.disable();
		}

		if (!this.quote.accessorials) {
			this.quote.accessorials = [];
		}

		this.noFreightCostForm.valueChanges.subscribe((value) => {
			if (value.noFreightCost) {
				this.freightInstallForm.controls['freightCost'].setValue('0.00');
				this.freightInstallForm.controls['freightCost'].disable();
			}

			if (!value.noFreightCost) {
				this.freightInstallForm.controls['freightCost'].enable();
			}
		});
	}

	setFreightCostValue(value: number) {
		this.freightCostControl.setValue(this.decimalPipe.transform(value, '.2'));
	}

	get freightCostControl() {
		return this.freightInstallForm.controls['freightCost'];
	}

	get freightCost(): string | null {
		const value = this.format(this.freightCostControl.value.toString());
		return this.currencyPipe.transform(parseFloat(value));
	}

	checkForTrailingZero() {
		const value: string = this.format(this.freightCostControl.value);
		this.setFreightCostValue(parseFloat(value));
	}

	format(input: string): string {
		return input !== null && input.includes(',') ? input.replaceAll(',', '') : input;
	}
}
