import {Component, EventEmitter, Input, Output} from '@angular/core';
import {map, Observable, startWith, Subject} from 'rxjs';
import {FormControl} from '@angular/forms';
import {QuotePart} from '../../../../core/models/quote-part.interface';
import {PartData} from '../temporary-part-data/manualPartsData.interface';
import {QuoteLine} from '../../../../core/models/quote-line.interface';
import {PartService} from '../../../../core/services/part.service';
import {QuoteLineCategoryEnum} from '../../../../core/enums/quote-line-category.enum';

@Component({
	selector: 'app-manual-parts-input',
	templateUrl: './manual-parts-input.component.html',
	styleUrls: ['./manual-parts-input.component.scss']
})
export class ManualPartsInputComponent {
	@Input() previouslySelectedParts: QuoteLine[] = [];
	@Input() key: string = '';
	@Input() filterMobilePartsSubject: Subject<boolean>;
	@Input() partDeletedSubject: Subject<string>;
	@Input() submitted: boolean;
	@Output() result = new EventEmitter<{key: string; data: QuotePart[]}>();
	@Output() loadedEvent: EventEmitter<boolean> = new EventEmitter<boolean>();

	rawPartsData: PartData[] = [];
	filteredData: Observable<PartData[]>;
	selectedParts: PartData[] = [];

	selectControl = new FormControl();
	filterString: string = '';
	loading: boolean = true;

	constructor(private partService: PartService) {}

	ngOnInit() {
		if (this.submitted) {
			this.selectControl.disable();
		} else {
			this.partService.parts.subscribe((parts: PartData[]) => {
				this.rawPartsData = parts;
				this.buildOptions();
				this.startFilter(false);
			});

			this.partDeletedSubject.subscribe((event) => {
				let index: number = this.rawPartsData.findIndex((data) => data.part.description === event);
				this.rawPartsData[index].selected = false;

				index = this.selectedParts.findIndex((data) => data.part.description === event);
				this.selectedParts.splice(index);
			});

			this.filterMobilePartsSubject.subscribe((filterMobileParts: boolean) => {
				this.startFilter(filterMobileParts, this.selectControl.value);
			});
		}
	}

	startFilter(filterMobileParts: boolean, filterString?: string): void {
		this.filteredData = this.selectControl.valueChanges.pipe(
			startWith(filterString ? filterString : ''),
			map((value) => (typeof value === 'string' ? value : this.filterString)),
			map((filter) => this.filter(filter, filterMobileParts))
		);
	}

	buildOptions(): void {
		this.previouslySelectedParts.forEach((part: QuoteLine) => {
			const index: number = this.rawPartsData.findIndex((data: PartData) => part.erpItemRef === data.part.erpItemRef);

			if (index !== -1) {
				this.rawPartsData[index].selected = true;
			}
			this.selectedParts.push({selected: true, part});
		});
		if (this.rawPartsData.length !== 0) {
			this.loading = false;
			this.loadedEvent.emit(true);
		}
	}

	ngOnDestroy(): void {
		this.partDeletedSubject.unsubscribe();
		this.filterMobilePartsSubject.unsubscribe();
	}

	filter(filter: string, filterMobileParts: boolean): PartData[] {
		this.filterString = filter;
		if (filter.length > 0) {
			return this.rawPartsData.filter((option) => {
				if (filterMobileParts) {
					if (
						option.part.item &&
						option.part.description &&
						(option.part.category === QuoteLineCategoryEnum.MOBILE_PART || option.selected)
					) {
						return (
							option.part.item.toLowerCase().indexOf(filter.toLowerCase()) >= 0 ||
							option.part.description.toLowerCase().indexOf(filter.toLowerCase()) >= 0
						);
					} else {
						return;
					}
				} else {
					if (option.part.item && option.part.description) {
						return (
							option.part.item.toLowerCase().indexOf(filter.toLowerCase()) >= 0 ||
							option.part.description.toLowerCase().indexOf(filter.toLowerCase()) >= 0
						);
					} else {
						return;
					}
				}
			});
		} else {
			return filterMobileParts
				? this.rawPartsData.filter((data: PartData) => data.part.category === QuoteLineCategoryEnum.MOBILE_PART).slice()
				: this.rawPartsData.slice();
		}
	}

	displayFn(): string {
		return '';
	}

	optionClicked(event: Event, mobilePart: PartData): void {
		event.stopPropagation();
		this.toggleSelection(mobilePart);
	}

	toggleSelection(mobilePart: PartData): void {
		mobilePart.selected = !mobilePart.selected;
		if (mobilePart.selected) {
			this.selectedParts.push(mobilePart);
		} else {
			const i = this.selectedParts.findIndex((value) => value.part === mobilePart.part);
			this.selectedParts.splice(i, 1);
		}
		this.emitAdjustedData();
	}

	emitAdjustedData(): void {
		const results: QuotePart[] = [];
		this.selectedParts.forEach((data: any) => {
			results.push(data.part);
		});
		this.result.emit({key: this.key, data: results});
	}

	onClicked(): void {
		if (!this.selectControl.value) {
			this.selectControl.setValue('');
		}
	}
}
