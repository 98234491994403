import {ChangeDetectorRef, Component, OnInit, QueryList, ViewChildren} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {ShipmentService} from '../../core/services/shipment.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Shipment} from '../../core/models/shipment.model';
import {startOfDay} from 'date-fns';
import {ShipmentHandlingUnit} from '../../core/models/shipment-handling-unit.model';
import {ConfirmationDialogComponent} from '../../shared/components/confirmation-dialog/confirmation-dialog.component';
import {MatDialog} from '@angular/material/dialog';
import {Freight} from '../../core/models/freight.model';
import {FreightBroker} from '../../core/models/freight-broker.interface';
import {FreightDetailCalculationService} from '../../core/services/freight-detail-calculation.service';
import {FreightBid} from '../../core/models/freight-bid.interface';
import {MatExpansionPanel} from '@angular/material/expansion';
import {FreightService} from '../../core/services/freight.service';

export interface FreightListEntry {
	freightId?: number;
	deliveryDate?: string;
	deliveryName?: string;
	deliveryAddress1?: string;
	deliveryAddress2?: string;
	deliveryCity?: string;
	deliveryState?: string;
	deliveryPostal?: string;
	deliveryCountry?: string;
	booked?: boolean;
	bookAccepted?: boolean;
	ready: boolean;
	shipments: Shipment[];
	freightBrokers?: FreightBroker[];
	bidCount?: number;
	rateRequestCount?: number;
	bids?: FreightBid[];
	expanded?: boolean;
	bookings?: FreightBid[];
}
@Component({
	selector: 'app-project-freight',
	templateUrl: './project-freight.component.html',
	styleUrls: ['./project-freight.component.scss']
})
export class ProjectFreightComponent implements OnInit {
	@ViewChildren('freightPanel') freightPanels: QueryList<MatExpansionPanel>;
	projectId: number | undefined;

	freightList: FreightListEntry[] = [];
	selectedFreight: FreightListEntry | null = null;
	selectedHandlingUnits: {[key: string]: number} = {};
	selectedTotalLinearDimensions: number = 0;
	selectedDUnit: string = '';
	selectedTotalWeight: number = 0;
	selectedWUnit: string = '';
	validShipmentIds: number[] = [];

	busy = false;

	constructor(
		private route: ActivatedRoute,
		private shipmentService: ShipmentService,
		private _snackBar: MatSnackBar,
		private dialog: MatDialog,
		private changeDetector: ChangeDetectorRef,
		private freightDetailCalculationService: FreightDetailCalculationService,
		private freightService: FreightService
	) {}

	ngOnInit() {
		let currentRoute = this.route;
		while (currentRoute.children[0]) {
			currentRoute = currentRoute.children[0];
		}
		currentRoute.params.subscribe({
			next: (params) => {
				this.projectId = params['projectId'];
				this.getFreight();
			}
		});
	}

	getFreight() {
		if (this.projectId) {
			this.busy = true;
			this.freightList = [];
			this.selectedFreight = null;
			this.shipmentService.getProjectShipments(this.projectId).subscribe({
				next: (projectFreight) => {
					this.addFreight(projectFreight.freights);
					this.buildFreightList(projectFreight.shipments);
					this.freightList.sort((a, b) => {
						let aDate = new Date(0);
						let bDate = new Date(0);
						if (a.deliveryDate) {
							aDate = new Date(a.deliveryDate);
						}
						if (b.deliveryDate) {
							bDate = new Date(b.deliveryDate);
						}
						if (!a.freightId && !b.freightId) {
							return aDate > bDate ? 1 : -1;
						}
						if (a.freightId && !b.freightId) {
							return -1;
						}
						if (!a.freightId && b.freightId) {
							return 1;
						}
						return aDate > bDate ? 1 : -1;
					});
					this.busy = false;
				},
				error: (err) => {
					this._snackBar.open('Get Project Shipments Failed: ' + err.error.message);
					this.busy = false;
				}
			});
		}
	}

	addFreight(freights: Freight[]) {
		freights.forEach((freight) => {
			if (freight.shipments && freight.shipments.length) {
				let deliveryDate: string = 'Not Scheduled';
				const shipments: Shipment[] = [];
				freight.shipments.forEach((shipment) => {
					shipment.valid = true;
					shipments.push(shipment);
				});
				if (freight.shipments[0].deliveryDateTime) {
					deliveryDate = startOfDay(new Date(freight.shipments[0].deliveryDateTime)).toLocaleDateString();
				}
				let requestCount = 0;
				let bidCount = 0;
				let bids: FreightBid[] = [];
				let bookings: FreightBid[] = [];
				let booked = false;
				let bookAccepted = false;
				if (freight.freightBrokers) {
					requestCount = freight.freightBrokers.length;
					freight.freightBrokers.forEach((broker) => {
						if (broker.freightBids && broker.freightBids.length) {
							bidCount += 1;
							bids.push(
								...broker.freightBids.filter(
									(bid) =>
										bid.bidStatus === 'submitted' ||
										bid.bidStatus === 'booking-requested' ||
										bid.bidStatus === 'booked' ||
										bid.freightBooking
								)
							);
						}
					});
					booked = bids.filter((bid) => bid.bidStatus === 'booking-requested' || bid.bidStatus === 'booked').length > 0;
					bookAccepted = bids.filter((bid) => bid.freightBooking).length > 0;
					bookings = bids.filter((bid) => bid.freightBooking || bid.bidStatus === 'booking-requested');
				}
				this.freightList.push({
					freightId: freight.id,
					deliveryDate: deliveryDate,
					deliveryName: freight.shipments[0].destinationName,
					deliveryAddress1: freight.shipments[0].destinationAddress1,
					deliveryAddress2: freight.shipments[0].destinationAddress2,
					deliveryCity: freight.shipments[0].destinationCity,
					deliveryState: freight.shipments[0].destinationState,
					deliveryPostal: freight.shipments[0].destinationPostal,
					deliveryCountry: freight.shipments[0].destinationCountry,
					ready: true,
					shipments: shipments,
					freightBrokers: freight.freightBrokers,
					rateRequestCount: requestCount,
					bidCount: bidCount,
					bids: bids,
					booked: booked,
					bookAccepted: bookAccepted,
					bookings: bookings
				});
			}
		});
	}

	buildFreightList(shipments: Shipment[]) {
		shipments.forEach((shipment) => {
			let deliveryDate: string = 'Not Scheduled';
			let freight: FreightListEntry | undefined;
			if (shipment.deliveryDateTime) {
				deliveryDate = startOfDay(new Date(shipment.deliveryDateTime)).toLocaleDateString();
			}
			freight = this.freightList.find(
				(freight) =>
					freight.freightId === 0 &&
					freight.deliveryDate === deliveryDate &&
					freight.deliveryName === shipment.destinationName &&
					freight.deliveryAddress1 === shipment.destinationAddress1 &&
					freight.deliveryAddress2 === shipment.destinationAddress2 &&
					freight.deliveryCity === shipment.destinationCity &&
					freight.deliveryState === shipment.destinationState &&
					freight.deliveryPostal === shipment.destinationPostal &&
					freight.deliveryCountry === shipment.destinationCountry
			);
			shipment.valid = this.shipmentService.validateShipment(shipment);
			if (freight) {
				freight.shipments?.push(shipment);
				freight.ready = this.freightReady(freight);
			} else {
				const ready =
					deliveryDate !== 'Not Scheduled' &&
					shipment.destinationName &&
					shipment.destinationAddress1 &&
					shipment.destinationCity &&
					shipment.destinationState &&
					shipment.destinationPostal &&
					shipment.destinationCountry &&
					shipment.valid;
				this.freightList.push({
					freightId: 0,
					deliveryDate: deliveryDate,
					deliveryName: shipment.destinationName,
					deliveryAddress1: shipment.destinationAddress1,
					deliveryAddress2: shipment.destinationAddress2,
					deliveryCity: shipment.destinationCity,
					deliveryState: shipment.destinationState,
					deliveryPostal: shipment.destinationPostal,
					deliveryCountry: shipment.destinationCountry,
					ready: !!ready,
					shipments: [shipment]
				});
			}
		});
	}

	selectFreight(index: number) {
		this.selectedFreight = this.freightList[index];
		if (this.selectedFreight) {
			this.selectedHandlingUnits = {};
			this.selectedTotalLinearDimensions = 0;
			this.selectedDUnit = '';
			this.selectedTotalWeight = 0;
			this.selectedWUnit = '';
			this.selectedFreight.shipments.forEach((shipment) => {
				if (shipment.shipmentHandlingUnits) {
					shipment.shipmentHandlingUnits.forEach((handlingUnit) => {
						if (handlingUnit.huType && handlingUnit.qty) {
							if (!this.selectedHandlingUnits[handlingUnit.huType]) {
								this.selectedHandlingUnits[handlingUnit.huType] = handlingUnit.qty;
							} else {
								this.selectedHandlingUnits[handlingUnit.huType] += handlingUnit.qty;
							}
						}
					});
				}
				if (this.selectedDUnit === '' && shipment.dUnit) {
					this.selectedDUnit = shipment.dUnit;
				}
				if (this.selectedWUnit === '' && shipment.wUnit) {
					this.selectedWUnit = shipment.wUnit;
				}
				if (shipment.totalLinearDimension && shipment.dUnit) {
					this.selectedTotalLinearDimensions += this.freightDetailCalculationService.convertDimension(
						shipment.totalLinearDimension,
						shipment.dUnit,
						this.selectedDUnit
					);
				}
				if (shipment.totalWeight && shipment.wUnit) {
					this.selectedTotalWeight += this.freightDetailCalculationService.convertWeight(
						shipment.totalWeight,
						shipment.wUnit,
						this.selectedWUnit
					);
				}
			});
			const validShipments = this.selectedFreight?.shipments.filter((shipment) => shipment.valid).map((shipment) => shipment.id);
			const shipmentIds: number[] = [];
			validShipments?.forEach((id) => {
				if (id) {
					shipmentIds.push(id);
				}
			});
			this.validShipmentIds = shipmentIds;
		}
	}

	get deliveryTime(): string {
		const deliveryDateTime = this.selectedFreight?.deliveryDate;
		if (deliveryDateTime) {
			return new Date(deliveryDateTime).toLocaleTimeString('us-EN');
		}
		return '';
	}

	freightReady(freight: FreightListEntry) {
		const validShipmentCount = this.validShipments(freight);
		return !!(
			freight.deliveryDate !== 'Not Scheduled' &&
			freight.deliveryName &&
			freight.deliveryAddress1 &&
			freight.deliveryCity &&
			freight.deliveryState &&
			freight.deliveryPostal &&
			freight.deliveryCountry &&
			validShipmentCount > 0
		);
	}

	validShipments(freight: FreightListEntry): number {
		const validShipments = freight.shipments?.filter((shipment) => shipment.valid);
		let validShipmentCount = 0;
		if (validShipments) {
			validShipmentCount = validShipments.length;
		}
		return validShipmentCount;
	}

	handlingUnitSummary(handlingUnits: ShipmentHandlingUnit[] | undefined): {[key: string]: number} {
		const handlingUnitSummary: {[key: string]: number} = {};
		if (handlingUnits) {
			handlingUnits.forEach((handlingUnit) => {
				if (handlingUnit.huType && handlingUnit.qty) {
					if (!handlingUnitSummary[handlingUnit.huType]) {
						handlingUnitSummary[handlingUnit.huType] = handlingUnit.qty;
					} else {
						handlingUnitSummary[handlingUnit.huType] += handlingUnit.qty;
					}
				}
			});
		}
		return handlingUnitSummary;
	}

	deleteShipment(freightIndex: number, shipmentIndex: number) {
		const confirm = this.dialog.open(ConfirmationDialogComponent, {
			data: {
				title: 'Confirm Delete Shipment',
				message: 'Shipment will be permanently deleted. Are you sure?'
			}
		});
		confirm.afterClosed().subscribe((result) => {
			if (result) {
				const id = this.freightList[freightIndex].shipments[shipmentIndex].id;
				if (id) {
					this.busy = true;
					this.shipmentService.remove(id).subscribe({
						next: () => {
							this._snackBar.open('Shipment Deleted.');
							this.freightList[freightIndex].shipments.splice(shipmentIndex, 1);
							if (!this.freightList[freightIndex].shipments.length) {
								this.freightList.splice(freightIndex, 1);
							}
							this.busy = false;
						},
						error: (err) => {
							this._snackBar.open('Delete Shipment Failed: ' + err.error.message);
							this.busy = false;
						}
					});
				}
			}
		});
	}

	bookFreight(freightIndex: number, bidIndex: number) {
		let bid: FreightBid = {};
		const freight = this.freightList[freightIndex];
		if (freight.bids && freight.bids[bidIndex].id) {
			bid = freight.bids[bidIndex];
		}
		if (bid.id) {
			this.busy = true;
			this.freightService.bookFreight(bid, this.projectId).subscribe({
				next: (bid) => {
					this._snackBar.open('Booking Sent');
					this.busy = false;
				},
				error: (err) => {
					this._snackBar.open('Booking Failed: ' + err.error.message);
					this.busy = false;
				}
			});
		}
	}

	expandChange(expanded: boolean, freightIndex: number) {
		const expandedPanelCount = this.freightPanels.filter((panel) => panel.expanded).length;
		if (expanded || !expandedPanelCount) {
			this.selectedFreight = null;
			this.changeDetector.detectChanges();
			if (expanded && this.freightList[freightIndex].ready) {
				this.selectFreight(freightIndex);
			}
		}
	}
}
