import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {InstallLocation} from '../models/install-location.interface';

@Injectable({
	providedIn: 'root'
})
export class InstallLocationService {
	baseUrl: string = `${environment.url}/core/install-locations`;
	constructor(private http: HttpClient) {}

	findAll(): Observable<InstallLocation[]> {
		return this.http.get<InstallLocation[]>(this.baseUrl);
	}
}
