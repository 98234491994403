import {Component, OnInit, Input, OnDestroy} from '@angular/core';
import {Project} from '../../core/models/project.interface';
import {MatSnackBar} from '@angular/material/snack-bar';
import {SnackbarActionEnum} from '../../core/enums/snackbar-action.enum';
import {ProjectsService} from '../../core/services/projects.service';
import {StatusService} from '../../core/services/status.service';
import {Status} from '../../core/models/status.interface';
import {ProjectDocument} from '../../core/models/project-document.interface';
import {ProjectDocumentService} from '../../core/services/project-document.service';
import {NavigationStart, Router} from '@angular/router';
import {Subscription} from 'rxjs';

@Component({
	selector: 'app-project-drafting',
	templateUrl: './project-drafting.component.html',
	styleUrls: ['./project-drafting.component.scss']
})
export class ProjectDraftingComponent implements OnInit, OnDestroy {
	@Input() files: File[] = [];
	project: Project;
	statuses: Status[];
	uploadedFiles: ProjectDocument[] = [];
	projectDocumentDtos: ProjectDocument[] = [];
	isLoading: boolean = false;
	navigationSubscription: Subscription;

	constructor(
		private projectService: ProjectsService,
		private statusesService: StatusService,
		private projectDocumentService: ProjectDocumentService,
		private snackbar: MatSnackBar,
		private router: Router
	) {}

	ngOnInit() {
		this.statusesService.statuses.subscribe((statuses) => {
			this.statuses = statuses;
		});

		this.navigationSubscription = this.router.events.subscribe((event) => {
			if (event instanceof NavigationStart && this.files.length > 0) {
				//TODO Up this to use the confirmation modal
				if (!confirm('You have unsaved files! Are you sure you want to leave?')) {
					this.router.navigate([this.router.url]);
					this.navigationSubscription.unsubscribe();
				}
			}
		});

		this.projectService.project.subscribe((project) => {
			if (project) {
				this.project = project;
			}
		});
	}

	updateProjectStatus() {
		let nextStatusId = -1;
		//Update the status to be the first step of the department proceeding drafting
		//find status that has a 'status' of 210
		for (let department of this.statuses) {
			for (let status of department.statuses) {
				//when match, get the current department
				if (status.status === 130) {
					nextStatusId = status.id;
				}
			}
		}

		this.project.workflowStatus.id = nextStatusId;
		//Save changes
		this.projectService.update({id: this.project.id, workflowStatus: this.project.workflowStatus});
	}

	updateProjectDocs() {
		this.isLoading = true;
		const newDocs = new FormData();
		for (let i = 0; i < this.files.length; i++) {
			newDocs.append('files', this.files[i], this.files[i].name);
		}
		let newDtos = this.projectDocumentDtos.filter((each) => this.files.filter((file) => file.name === each.documentName).length);
		newDocs.append('body', JSON.stringify(newDtos));
		this.projectDocumentService.create(newDocs, this.project.id).subscribe(
			(response) => {
				//I know this looks stupid, but the arrays' reference needs to change to trigger change detection in the file upload component
				let newUploadedFiles = this.uploadedFiles.filter((each: ProjectDocument) => true);
				response.forEach((document: ProjectDocument, i: number) => {
					let spliceIndex = this.files.findIndex((doc, i) => {
						return doc.name === response[i].documentName;
					});
					newUploadedFiles.push(response[i]);
					this.files.splice(spliceIndex, 1);
				});
				this.uploadedFiles = newUploadedFiles;
				this.isLoading = false;
				//add newly upload files to projectService Project Subject.
				const currentProject = this.projectService.projectDataSource.getValue();
				response.forEach((each: ProjectDocument) => currentProject?.documents.push(each));
				this.projectService.projectDataSource.next(currentProject);
				this.snackbar.open('Document Uploaded', SnackbarActionEnum.SUCCESS);
			},
			(error) => {
				this.isLoading = false;
				this.snackbar.open('Failed to Upload Document', SnackbarActionEnum.ERROR);
				throw new Error('Something went wrong with your request. Please try again soon.');
			}
		);
	}

	handleFileAddEmit(files: File[]) {
		files.forEach((file: File) => {
			if (!this.files.length) {
				this.files.push(file);
			} else {
				if (this.files.filter((eachFile: File) => file.name === eachFile.name).length === 0) {
					this.files.push(file);
				}
			}
		});
	}

	handleFileRemoveEmit(file: File) {
		this.files.splice(this.files.indexOf(file), 1);
		let documentDto = this.projectDocumentDtos.find((dto: ProjectDocument) => dto.documentName === file.name);
		this.projectDocumentDtos.splice(this.projectDocumentDtos.indexOf(documentDto!), 1);
	}

	handleDocumentDtoEmit(documents: ProjectDocument[]) {
		documents.forEach((document: ProjectDocument) => {
			if (!this.projectDocumentDtos.length) {
				this.projectDocumentDtos.push(document);
			} else {
				if (this.projectDocumentDtos.filter((eachFile: ProjectDocument) => document.documentName === eachFile.documentName).length === 0) {
					this.projectDocumentDtos.push(document);
				}
			}
		});
	}

	ngOnDestroy() {
		if (this.navigationSubscription) {
			this.navigationSubscription.unsubscribe();
		}
	}
}
