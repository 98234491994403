import {Component, Input, OnInit} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {QuoteApprovalInfo} from '../../../core/models/quote-approval-info.interface';
import {QuoteService} from '../../../core/services/quote.service';

@Component({
	selector: 'app-approval-info',
	templateUrl: './approval-info.component.html',
	styleUrls: ['./approval-info.component.scss']
})
export class ApprovalInfoComponent implements OnInit {
	@Input() parentForm: FormGroup;
	@Input() approvals: QuoteApprovalInfo[];
	@Input() quoteId: number;
	approval: QuoteApprovalInfo;
	poUrl: string;
	loading: boolean = true;

	constructor(private quoteService: QuoteService) {}

	ngOnInit(): void {
		if (this.approvals) {
			this.approval = this.approvals[0];
			if (this.approval?.documentName) {
				this.quoteService.getPoUpload(this.quoteId).subscribe({
					next: (response: any) => {
						this.poUrl = response.url;
						this.loading = false;
					},
					error: (err: any) => console.error(err)
				});
			} else {
				this.loading = false;
			}
		}
	}

	downloadPoUpload() {
		const a = document.createElement('a');
		a.style.display = 'none';
		a.href = this.poUrl;
		a.download = this.poUrl;
		document.body.appendChild(a);
		a.click();
		window.URL.revokeObjectURL(a.href);
		document.body.removeChild(a);
	}
}
