<div>
	<div class="flex flex-col">
		<app-contacts-input
			*ngIf="!busy"
			[hidden]="createContact"
			placeholder="Select contacts to be emailed here..."
			[contactsOptions]="contacts"
			(result)="selectChange($event)"
			[contactDeletedSubject]="contactDeletedSubject"
			[contactCreatedSubject]="contactCreatedSubject"
		></app-contacts-input>

		<fieldset class="border border-gray-500 rounded p-1 mt-2">
			<legend class="ml-1 p-1 text-gray-500 text-sm">Quote Approvers</legend>
			<mat-chip-listbox>
				<mat-chip (click)="onCreateContact()"> <span class="font-bold">+</span> Add New Contact </mat-chip>
				<mat-chip *ngFor="let contact of selectedContacts" matTooltip="{{ contact.email }}">
					{{ contact.name }} <button matChipRemove (click)="delete(contact)"><mat-icon>cancel</mat-icon></button>
				</mat-chip>
			</mat-chip-listbox>
		</fieldset>
	</div>

	<div *ngIf="createContact" [formGroup]="contactForm" class="border border-gray-500 rounded flex flex-col p-4 my-2">
		<span class="text-gray-500"> New Contact Info </span>

		<mat-form-field appearance="outline" class="mt-2">
			<mat-label> Name </mat-label>
			<input matInput formControlName="name" />
			<mat-error *ngIf="contactForm.get('name')?.touched && contactForm.get('name')?.hasError('required')">
				This is a required field
			</mat-error>
		</mat-form-field>

		<mat-form-field appearance="outline">
			<mat-label> Email </mat-label>
			<input matInput formControlName="email" />
			<mat-error *ngIf="contactForm.get('email')?.touched && contactForm.get('email')?.hasError('required')">
				This is a required field
			</mat-error>
			<mat-error *ngIf="contactForm.get('email')?.touched && contactForm.get('email')?.hasError('pattern')">
				Please enter a valid email address
			</mat-error>
		</mat-form-field>

		<mat-form-field appearance="outline">
			<mat-label> Phone </mat-label>
			<input matInput formControlName="phone" />
		</mat-form-field>

		<mat-form-field appearance="outline">
			<mat-label> Ext. </mat-label>
			<input matInput formControlName="phoneExt" />
		</mat-form-field>

		<div class="flex flex-row self-end space-x-2 mt-2">
			<button mat-raised-button (click)="onCancelCreate()">CANCEL</button>
			<button mat-raised-button color="primary" (click)="saveContact()">CREATE CONTACT</button>
		</div>
	</div>
</div>
