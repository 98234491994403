<div *ngIf="isLoading | async" class="w-full h-full flex justify-center m-2">
	<mat-spinner></mat-spinner>
</div>
<button
	*ngIf="project && !viewQuoteList"
	mat-raised-button
	routerLink="/project/{{ project.id }}/quote/"
	color="primary"
	class="ml-4 mt-2 mb-0"
>
	See Full Quote LIst
</button>
<div *ngIf="!(isLoading | async)">
	<div *ngIf="project && viewQuoteList">
		<app-quote-header-list [projectId]="project.id"></app-quote-header-list>
	</div>

	<div *ngIf="quoteSelected && !viewQuoteList" class="xl:flex flex-row flex-wrap space-x-4">
		<div class="flex flex-col w-full lg:w-2/3 xl:w-5/12 py-4 pl-4 space-y-2" [formGroup]="freightInstallForm">
			<app-approval-info
				*ngIf="createOrderMode"
				[parentForm]="freightInstallForm"
				[approvals]="this.quote.approvals ?? []"
				[quoteId]="quote.id!"
			></app-approval-info>
			<div *ngIf="createOrderMode" class="flex flex-row space-x-2 place-content-end">
				<button mat-raised-button (click)="cancelEdit()">CANCEL</button>
				<button mat-raised-button color="primary" (click)="editMode = true">EDIT QUOTE</button>
			</div>
			<div class="flex flex-col space-y-2 {{ !createOrderMode || editMode ? '' : 'hidden' }}">
				<app-manual-part-entry
					*ngIf="calculated"
					[submitted]="submitted"
					partTypeCategory="lineItemParts"
					[startExpanded]="true"
					(showPartListEvent)="showFullPartList = $event"
					[parentForm]="freightInstallForm"
				></app-manual-part-entry>
				<div class="flex flex-col mt-4">
					<mat-accordion>
						<mat-expansion-panel expanded="false" class="w-full">
							<mat-expansion-panel-header>
								<mat-panel-title> Rack Solid </mat-panel-title>
								<mat-label class="mr-4">{{ rackSolidItemTotal | currency }}</mat-label>
							</mat-expansion-panel-header>
							<app-part-list *ngIf="quote.parts?.length" [quote]="quote" [useReadOnlyView]="true"></app-part-list>
							<div *ngIf="!submitted" class="flex justify-center">
								<button class="w-4/5" mat-raised-button color="primary" (click)="goToRackSolid()">Configure Rack Solid</button>
							</div>
						</mat-expansion-panel>
					</mat-accordion>
				</div>
				<app-freight-estimator [quote]="quote" *ngIf="this.quote" [submitted]="submitted"></app-freight-estimator>
				<app-freight-install
					*ngIf="calculated"
					[submitted]="submitted"
					[itemTotal]="this.rackSolidItemTotal + this.manuallyAddedPartTotal"
					[itemTotalSubject]="itemTotalSubject"
				></app-freight-install>
				<app-associated-costs class="mt-4" (totalCostChanged)="this.associatedCostTotal = $event"></app-associated-costs>
				<app-additional-info [parentForm]="freightInstallForm"></app-additional-info>
			</div>
		</div>

		<div class="flex flex-col w-full lg:w-2/3 xl:w-1/2 py-4 pr-4">
			<div class="mat-elevation-z4" *ngIf="this.freightInstallForm">
				<div class="p-8 self-center" matTooltip="Quote Preview" matTooltipPosition="above" id="quotePreview" #quotePreview>
					<div class="flex flex-row space-between w-full">
						<div class="flex flex-col w-1/2">
							<img src="./assets/mmi-logo.jpg" alt="MMI Logo" class="w-3/4" />
							<span class="ml-8">45 Turner Drive Suite 2A Middletown, NY 10941</span>
							<span class="ml-8">PH: (800) 784-8080 FX:</span>
						</div>
						<div class="flex flex-col font-bold w-1/2 mt-4">
							<span class="text-xl place-self-end"
								>SALES QUOTE <span class="font-normal">{{ quote.id }}</span></span
							>
							<span class="place-self-end"
								>Sales Quote Date: <span class="font-normal">{{ this.testQuote.quoteDate | date }}</span></span
							>
						</div>
					</div>

					<div class="flex flex-row justify-around my-8">
						<div class="flex flex-row space-x-2">
							<span>Sell To: </span>
							<div class="flex flex-col" *ngIf="client">
								<span>{{ client.name }}</span>
								<span>{{ client.address1 }}</span>
								<span>{{ client.city }} , {{ client.state }} {{ client.postal }}</span>
							</div>
						</div>

						<div class="flex flex-row space-x-2">
							<span>Ship To: </span>
							<div class="flex flex-col" *ngIf="this.quote.siteAddress1">
								<span>{{ this.quote.siteAddress1 }}, {{ this.quote.siteAddress2 }}</span>
								<span>
									{{ this.quote.siteCity }},
									{{ this.quote.siteState }}
									{{ this.quote.siteZip }}</span
								>
							</div>
						</div>
					</div>

					<div class="flex flex-row justify-between mb-2">
						<span class="self-end">Terms: {{ testQuote.quoteTerms }} days</span>
						<span class="self-end">Mobile Systems Gross Weight: {{ mobileItemsGrossWeight }} lbs</span>
						<div class="flex flex-row space-x-2">
							<div class="flex flex-col justify-end font-bold">
								<span
									>Sales Person: <span class="font-normal">{{ freightInstallForm.get('salesperson')?.value }}</span></span
								>
								<span
									>Quoter: <span class="font-normal">{{ freightInstallForm.get('quoter')?.value }}</span></span
								>
								<span
									>Account Manager: <span class="font-normal">{{ freightInstallForm.get('accountManager')?.value }}</span></span
								>
							</div>
							<div class="flex flex-col justify-end"></div>
						</div>
					</div>

					<!--table content-->
					<mat-divider></mat-divider>

					<div class="flex flex-row justify-between space-x-2 font-bold my-2">
						<span class="w-1/3">Item No.</span>
						<span class="w-1/2">Description</span>
						<span class="w-1/12">Unit</span>
						<span class="w-1/12">Quantity</span>
						<span class="w-1/6 text-end">Unit Price</span>
						<span class="w-1/6 text-end">Total</span>
					</div>

					<mat-divider></mat-divider>

					<div class="flex flex-col my-2">
						<div class="flex flex-row justify-between space-x-2" *ngFor="let item of freightInstallForm.get('lineItemParts')?.value">
							<span class="w-1/3">{{ item.erpItemRef }}</span>
							<span class="w-1/2">{{ item.description }}</span>
							<span class="w-1/12">{{ item.unitOfMeasure }}</span>
							<span class="w-1/12">{{ item.qty }}</span>
							<span class="w-1/6 text-end">{{ item.price ?? 0 | currency }}</span>
							<span class="w-1/6 text-end">{{ (item.price ?? 0) * (item.qty ?? 0) | currency }}</span>
						</div>

						<ng-container *ngIf="showFullPartList">
							<div class="flex flex-row justify-between space-x-2" *ngFor="let item of quote.parts">
								<span class="w-1/3">{{ item.description }}</span>
								<span class="w-1/2">{{ item.partName }}</span>
								<span class="w-1/12">{{ item.unit }}</span>
								<span class="w-1/12">{{ item.qty }}</span>
								<span class="w-1/6 text-end">{{ item.price ?? 0 | currency }}</span>
								<span class="w-1/6 text-end">{{ (item.price ?? 0) * (item.qty ?? 0) | currency }}</span>
							</div>
						</ng-container>

						<div class="flex flex-row justify-between space-x-2">
							<span class="w-1/3"></span>
							<span class="w-1/2">Rack Solid Stockroom Shelving</span>
							<span class="w-1/12"></span>
							<span class="w-1/12">1</span>
							<span class="w-1/6 text-end">{{ this.rackSolidItemTotal | currency }}</span>
							<span class="w-1/6 text-end">{{ this.rackSolidItemTotal | currency }}</span>
						</div>

						<div class="flex flex-row justify-between space-x-2">
							<span class="w-1/3"></span>
							<span class="w-1/2">Freight- AM With Liftgate</span>
							<span class="w-1/12"></span>
							<span class="w-1/12">1</span>
							<span class="w-1/6 text-end" *ngIf="this.calculated">${{ this.freightInstallForm.controls['freightCost'].value }}</span>
							<span class="w-1/6 text-end" *ngIf="this.calculated">${{ this.freightInstallForm.controls['freightCost'].value }}</span>
						</div>

						<div class="flex flex-row justify-between space-x-2">
							<span class="w-1/3"> </span>
							<span class="w-1/2">Install for System & Shelving</span>
							<span class="w-1/12"></span>
							<span class="w-1/12">1</span>
							<span class="w-1/6 text-end" *ngIf="this.calculated">${{ this.freightInstallForm.controls['installCost'].value }}</span>
							<span class="w-1/6 text-end" *ngIf="this.calculated">${{ this.freightInstallForm.controls['installCost'].value }}</span>
						</div>

						<div class="flex flex-row justify-between space-x-2" *ngFor="let associatedCost of associatedCostFormArray.controls">
							<span class="w-1/3"></span>
							<span class="w-1/2">{{ associatedCost.get('description')?.value }}</span>
							<span class="w-1/12"></span>
							<span class="w-1/12">1</span>
							<span class="w-1/6 text-end" *ngIf="associatedCost.get('price')?.value">${{ associatedCost.get('price')?.value }}</span>
							<span class="w-1/6 text-end" *ngIf="associatedCost.get('price')?.value">${{ associatedCost.get('price')?.value }}</span>
							<span class="w-1/6" *ngIf="!associatedCost.get('price')?.value"></span>
							<span class="w-1/6" *ngIf="!associatedCost.get('price')?.value"></span>
						</div>
					</div>

					<mat-divider></mat-divider>

					<div class="flex flex-row space-x-6 items-end mt-4">
						<span class="w-5/12 font-bold">Accepted:</span>
						<span class="w-1/6 text-end"></span>
						<span class="w-1/6"
							>Totals are in<br />
							US Dollar</span
						>
						<span class="w-1/4 font-bold">Subtotal: </span>
						<span class="w-1/6 text-end" *ngIf="this.calculated">{{ subTotal | currency }}</span>
					</div>

					<div class="flex flex-row space-x-6 mt-1 mb-2">
						<span class="w-5/12 font-bold">Amount Subject to Sales Tax: </span>
						<span class="w-1/6 text-end" *ngIf="this.freightInstallForm.get('taxSubjectAmount')?.value"
							>${{ this.freightInstallForm.get('taxSubjectAmount')?.value }}</span
						>
						<span class="w-1/6 text-end" *ngIf="!this.freightInstallForm.get('taxSubjectAmount')?.value">$0.00</span>
						<span class="w-1/6"></span>
						<span class="w-1/4 font-bold">Total Sales Tax: </span>
						<span class="w-1/6 text-end" *ngIf="this.freightInstallForm.get('taxes')?.value"
							>${{ this.freightInstallForm.get('taxes')?.value }}</span
						>
						<span class="w-1/6" *ngIf="!this.freightInstallForm.get('taxes')?.value">$0.00</span>
					</div>

					<mat-divider></mat-divider>

					<div class="flex flex-row space-x-6 mt-4">
						<span class="w-5/12 font-bold">Amount Exempt from Sales Tax: </span>
						<span class="w-1/6 text-end" *ngIf="this.calculated && this.freightInstallForm.get('taxExemptAmount')?.value"
							>${{ this.freightInstallForm.get('taxExemptAmount')?.value }}</span
						>
						<span class="w-1/6 text-end" *ngIf="this.calculated && !this.freightInstallForm.get('taxExemptAmount')?.value">$0.00</span>
						<span class="w-1/6"></span>
						<span class="w-1/4 font-bold">Grand Total: </span>
						<span class="w-1/6 text-end" *ngIf="this.calculated">{{ grandTotal | currency }}</span>
					</div>

					<div class="justify-self-center w-full mt-16">
						<span>{{ testQuote.quoteText }}</span>
					</div>
				</div>
			</div>
			<div class="flex flex-row w-full place-content-end mt-2 space-x-2">
				<button *ngIf="!createOrderMode && !submitted" mat-raised-button color="primary" (click)="save(true)">SAVE</button>
				<button *ngIf="!createOrderMode" mat-raised-button color="primary" (click)="requestApproval()">REQUEST APPROVAL</button>
				<button *ngIf="createOrderMode" [disabled]="salesOrderCreated()" mat-raised-button color="primary" (click)="createOrder()">
					<div *ngIf="!creatingSalesOrder">CREATE ORDER</div>
					<div *ngIf="creatingSalesOrder" class="w-full h-full flex justify-center m-2">
						<mat-spinner></mat-spinner>
					</div>
				</button>
			</div>
		</div>
	</div>
</div>
