import {HttpClient} from '@angular/common/http';
import {BehaviorSubject} from 'rxjs';
import {PriceLevel} from '../models/price-level.interface';
import {environment} from '../../../environments/environment';
import {Injectable} from '@angular/core';

@Injectable({
	providedIn: 'root'
})
export class PriceLevelService {
	private priceLevelDataSource = new BehaviorSubject<PriceLevel[]>([]);
	readonly priceLevels = this.priceLevelDataSource.asObservable();

	constructor(private http: HttpClient) {
		this.findAll();
	}

	findAll() {
		this.http
			.get<PriceLevel[]>(`${environment.url}/core/price-level`)
			.subscribe((priceLevels) => this.priceLevelDataSource.next(priceLevels));
	}
}
