import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Quote} from '../models/quote.interface';
import {environment} from '../../../environments/environment';
import {Role} from '../models/role.interface';

@Injectable({
	providedIn: 'root'
})
export class RolesService {
	baseUrl: string = `${environment.url}/core/roles`;

	constructor(private http: HttpClient) {}

	findAll() {
		return this.http.get<Role[]>(this.baseUrl);
	}
}
