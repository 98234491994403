<mat-card [formGroup]="contactForm" class="flex flex-col p-4 space-y-2">
	<mat-card-title> Send Approval Request </mat-card-title>

	<mat-label> {{ clientName }} </mat-label>

	<app-contacts
		(createContactEvent)="hideMessageInput = $event"
		[clientId]="2"
		[selectedContacts]="contacts"
		(selectedContactsChanged)="selectionChanged($event)"
	>
	</app-contacts>

	<mat-form-field appearance="outline" *ngIf="!hideMessageInput">
		<mat-label> Email Message </mat-label>
		<textarea formControlName="message" matInput></textarea>
	</mat-form-field>

	<div class="flex flex-row space-x-2 justify-end" *ngIf="!hideMessageInput">
		<button mat-raised-button color="primary" (click)="close()">CANCEL</button>
		<button mat-raised-button color="primary" (click)="onSubmit()">REQUEST APPROVAL</button>
	</div>
</mat-card>
