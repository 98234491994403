<div class="flex flex-col px-8 py-4">
	<div class="flex flex-row bg-gray-400 justify-between items-end h-11 px-4">
		<label class="w-1/2"></label>
		<label class="w-[12.5%]">Model</label>
		<label class="w-[12.5%] text-right">Qty</label>
		<label class="w-[12.5%] text-right">Weight</label>
		<label class="w-[12.5%] text-right">Price</label>
	</div>
	<div class="flex flex-col mt-2" *ngFor="let category of categories">
		<div *ngIf="!useReadOnlyView || getGeneratedPartsByCategory(category).length" class="flex flex-row items-center justify-between w-1/4">
			<label class="font-medium text-xl">{{ category | titlecase }}</label>
		</div>
		<div class="flex flex-col" [formGroup]="manualPartForm">
			<div class="flex flex-col justify-between items-center px-4 py-1 part-row" *ngFor="let part of getPartsByCategory(category)">
				<div class="flex flex-row w-full">
					<span class="w-1/2">{{ part.partName }}</span>
					<span class="w-[12.5%]">{{ part.modelNumber }}</span>
					<span class="w-[12.5%] text-right">{{ part.qty }}</span>
					<span class="w-[12.5%] text-right">{{ (part.weight ?? 0) * (part.qty ?? 0) | number }}</span>
					<span class="w-[12.5%] text-right">{{ (part.price ?? 0) * (part.qty ?? 0) | currency }}</span>
				</div>
				<div *ngIf="part.comment" class="flex flex-row w-full">
					<span class="ml-2">Note: {{ part.comment }}</span>
				</div>
			</div>
			<ng-container [formArrayName]="category">
				<div class="flex flex-row" *ngFor="let part of getManualPartsFormByCategory(category).controls; let i = index">
					<div class="flex flex-col w-full" [formGroupName]="i">
						<div class="flex flex-row justify-between items-center pr-4 py-1 w-full">
							<div class="w-1/4">
								<mat-form-field appearance="outline" subscriptSizing="dynamic" class="w-3/4">
									<mat-label>Part</mat-label>
									<mat-select formControlName="modelNumber" (selectionChange)="onPartSelect($event.value, part)">
										<mat-option *ngFor="let categoryPart of getCatalogPartsByCategory(category)" [value]="categoryPart.partNumber">
											{{ categoryPart.description }}
											{{ category === 'shelves' && categoryPart.type ? '(' + getConfigTypeName(categoryPart.type) + ')' : '' }}
										</mat-option>
									</mat-select>
								</mat-form-field>
								<button>
									<mat-icon (click)="deleteManualPart(category, i)">delete</mat-icon>
								</button>
							</div>
							<div class="w-1/4 flex flex-col" *ngIf="part.get('modelNumber')?.value">
								<mat-form-field appearance="outline" subscriptSizing="dynamic" class="w-11/12">
									<mat-label>Comment*</mat-label>
									<input type="text" matInput formControlName="comment" />
								</mat-form-field>
							</div>
							<div class="w-[12.5%] pl-2">{{ part.get('modelNumber')?.value }}</div>
							<span class="w-[12.5%] flex flex-col">
								<mat-form-field
									*ngIf="part.get('modelNumber')?.value"
									appearance="outline"
									subscriptSizing="dynamic"
									class="w-1/2 ml-auto qty-margin mat-form-qty-padding"
								>
									<mat-label>Qty</mat-label>
									<input class="text-right" type="number" min="0" matInput formControlName="qty" />
								</mat-form-field>
							</span>

							<div class="w-[12.5%] text-right" *ngIf="part.get('modelNumber')?.value">
								{{ (part.get('weight')?.value ?? 0) * (part.get('qty')?.value ?? 0) | number }}
							</div>
							<div class="w-[12.5%] text-right" *ngIf="part.get('modelNumber')?.value">
								{{ (part.get('price')?.value ?? 0) * (part.get('qty')?.value ?? 0) | currency }}
							</div>
						</div>
					</div>
				</div>
				<div *ngIf="!useReadOnlyView" class="flex flex-row mt-2">
					<button mat-raised-button color="primary" class="text-sm" (click)="addManualPart(category)">
						Add {{ category | titlecase }}
					</button>
				</div>
			</ng-container>
		</div>
	</div>
	<div class="flex flex-row justify-end items-center px-4">
		<label class="font-normal text-base mr-4">Total Price</label>
		<label class="font-normal text-2xl">{{ totalPrice | currency }}</label>
	</div>
</div>
