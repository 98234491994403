import {Component, OnInit, Input, ViewChild, ContentChild, AfterContentChecked} from '@angular/core';
import {Client} from '../../core/models/client.interface';
import {FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {InstallLocation} from '../../core/models/install-location.interface';
import {InstallLocationService} from '../../core/services/install-location.service';
import {MatCheckbox, MatCheckboxChange} from '@angular/material/checkbox';
import {ProjectsService} from '../../core/services/projects.service';
import {EstimateDateOption} from '../../core/models/estimate-date-option.interface';
import {ProjectDocument} from '../../core/models/project-document.interface';
import {Project} from '../../core/models/project.interface';
import {Template} from '../../core/models/template.interface';
import {TemplatesService} from '../../core/services/templates.service';
import {SnackbarActionEnum} from '../../core/enums/snackbar-action.enum';
import {MatSnackBar} from '@angular/material/snack-bar';
import {ConfirmationDialogComponent} from '../../shared/components/confirmation-dialog/confirmation-dialog.component';
import {MatDialog} from '@angular/material/dialog';

@Component({
	selector: 'app-project-details',
	templateUrl: './project-details.component.html',
	styleUrls: ['./project-details.component.scss']
})
export class ProjectDetailsComponent implements OnInit, AfterContentChecked {
	@ContentChild(ProjectDetailsComponent) projectDetails: ProjectDetailsComponent;
	@ViewChild('estimatedInstallCheckbox') estimatedInstallCheckbox: MatCheckbox;
	@ViewChild('nonUnionCheckbox') nonUnionCheckbox: MatCheckbox;
	@ViewChild('dayCheckbox') dayCheckbox: MatCheckbox;
	@ViewChild('nonSeismicCheckbox') nonSeismicCheckbox: MatCheckbox;
	@Input() client: Client | undefined;
	@Input() existingEstimate: boolean = false;
	@Input() form: FormGroup;
	@Input() project: Project | null;
	@Input() projectDocumentDtos: ProjectDocument[] = [];
	@Input() templateOptions: Template[] = [];
	@Input() selectedTemplate: Template;
	installLocations: InstallLocation[] = [];
	dateOptions: EstimateDateOption[] = [];
	estimatedInstall: boolean = false;
	prevSelectedTemplate: Template;
	constructor(
		private snackbar: MatSnackBar,
		private fb: FormBuilder,
		private projectsService: ProjectsService,
		private installLocationService: InstallLocationService,
		private templateService: TemplatesService,
		private dialog: MatDialog
	) {}

	ngOnInit() {
		this.dateOptions = this.projectsService.getDateRangeOptions();
		this.installLocationService.findAll().subscribe((response) => {
			this.installLocations = response;
		});
		this.projectsService.project.subscribe((project) => {
			this.project = project;
		});
	}

	ngAfterContentChecked() {
		if (this.dayCheckbox && !this.form.controls['nightWork'].value) {
			this.dayCheckbox.checked = true;
		}
		if (this.nonUnionCheckbox && !this.form.controls['union'].value && !this.form.controls['prevailingWage'].value) {
			this.nonUnionCheckbox.checked = true;
		}
	}
	applyTemplate(template: Template) {
		//Make call to the template service to get the project template.
		this.templateService.findTemplateEntity<Project>(template.id).subscribe(
			(project: Project) => {
				const surveyValue = this.form.controls['survey'].value;
				const projectWithoutLocations: Project = {...project};
				projectWithoutLocations.locations = [];
				projectWithoutLocations.survey = surveyValue;
				this.form.patchValue(projectWithoutLocations);
				if (project.estimateDate) {
					this.existingEstimate = true;
				}
			},
			(error) => {
				this.snackbar.open('Failed to Load Template', SnackbarActionEnum.ERROR);
			}
		);
		this.prevSelectedTemplate = template;
	}
	templateSelection(template: Template) {
		if (this.form.dirty) {
			const confirm = this.dialog.open(ConfirmationDialogComponent, {
				data: {
					title: 'Apply Template',
					message: 'Are you sure you would like apply this template? Any changes already made will be overwritten.'
				}
			});
			confirm.afterClosed().subscribe((result) => {
				if (result) {
					this.applyTemplate(template);
				} else {
					this.selectedTemplate = this.prevSelectedTemplate;
				}
			});
		} else {
			this.applyTemplate(template);
		}
	}
	unionOrWage(fieldName: string) {
		if (fieldName === 'union') {
			this.form.controls['union'].setValue(true);
			this.form.controls['prevailingWage'].setValue(false);
			this.nonUnionCheckbox.checked = false;
		} else if (fieldName === 'wage') {
			this.form.controls['union'].setValue(false);
			this.form.controls['prevailingWage'].setValue(true);
			this.nonUnionCheckbox.checked = false;
		} else {
			this.form.controls['union'].setValue(false);
			this.form.controls['prevailingWage'].setValue(false);
		}
	}

	dayOrNight(type: string) {
		if (type === 'night') {
			this.form.controls['nightWork'].setValue(true);
			this.dayCheckbox.checked = false;
		} else {
			this.form.controls['nightWork'].setValue(false);
			this.dayCheckbox.checked = true;
		}
	}
	seismicOrNonSeismic(type: string) {
		if (type === 'seismic') {
			this.form.controls['seismic'].setValue(true);
			this.form.controls['structuralCalcs'].setValue(false);
			this.nonSeismicCheckbox.checked = false;
		} else {
			this.form.controls['seismic'].setValue(false);
			this.form.controls['seismicCalcs'].setValue(false);
			this.form.controls['specialInspection'].setValue(false);
			this.nonSeismicCheckbox.checked = true;
		}
	}

	addNewLocationInput() {
		const locations = this.form.get('locations') as FormArray;
		locations.push(this.fb.control(null, Validators.required));
	}

	get locations() {
		return this.form.get('locations') as FormArray;
	}

	compareFn(c1: InstallLocation, c2: InstallLocation): boolean {
		return c1 && c2 ? c1.id === c2.id : c1 === c2;
	}

	stockroomSeismicCheck() {
		if (this.form.get('stockPermitReq')?.value) {
			this.form.controls['seismic'].setValue(true);
			this.nonSeismicCheckbox.checked = false;
			this.form.controls['structuralCalcs'].setValue(false);
		}
	}

	compareDates(date1: Date, date2: string): boolean {
		const parsedDate = new Date(date2);
		return date1.getTime() === parsedDate.getTime();
	}

	updateEstimateCheck(change: MatCheckboxChange) {
		this.form.controls['installDate'].setValue('');
		this.form.controls['estimateDate'].setValue('');
		this.existingEstimate = change.checked;
	}

	removeLocation(indexToRemove: number) {
		const locations = this.form.get('locations') as FormArray;
		const currentLocation = locations.controls[indexToRemove].value;
		if (this.projectDocumentDtos.filter((each) => each.installLocationId === currentLocation.id).length) {
			alert(
				'This location cannot be removed because there are files for this location in the dropzone! Please remove these files before deleting this location.'
			);
		} else if (this.project?.documents.filter((each) => each.installLocationId === currentLocation.id).length) {
			alert(
				'This location cannot be removed because there are files for this location in the dropzone! Please remove these files on the "Drafting Documents" tab before deleting this location.'
			);
		} else {
			locations.removeAt(indexToRemove);
		}
	}
}
