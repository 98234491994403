import {AfterViewChecked, ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {ProjectsService} from '../../core/services/projects.service';
import {ActivatedRoute} from '@angular/router';
import {MatSnackBar} from '@angular/material/snack-bar';
import {AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {addDays, differenceInDays, isSameDay, startOfDay, subDays} from 'date-fns';
import {Schedule} from '../../core/models/schedule.model';
import {ProjectTask} from '../../core/models/project-task.model';
import {ProductionHours} from '../../core/models/production-hours.interface';

@Component({
	selector: 'app-project-scheduling',
	templateUrl: './project-scheduling.component.html',
	styleUrls: ['./project-scheduling.component.scss']
})
export class ProjectSchedulingComponent implements OnInit, AfterViewChecked {
	projectScheduleForm = new FormGroup({
		installDate: new FormControl(new Date(), [Validators.required]),
		transitTime: new FormControl(0),
		projectTasks: this.fb.array([])
	});

	projectId: number | undefined;

	minDate = new Date();
	busy: boolean = false;

	projectInstallDate = new Date();

	header: {value: string; show: boolean}[] = [];

	transitTotal = 1;
	transitDays = 1;
	transitWeekends = 0;
	shippingBuffer = 2;

	shipDate: Date;
	productionEndDate: Date;

	productionHours: ProductionHours = {};

	scheduleDayHours = 8;

	singleCellWidth = '4rem';

	showDate: Date;

	constructor(
		private projectService: ProjectsService,
		private route: ActivatedRoute,
		private _snackBar: MatSnackBar,
		private fb: FormBuilder,
		private changeDetector: ChangeDetectorRef
	) {}

	ngOnInit() {
		let currentRoute = this.route;
		while (currentRoute.children[0]) {
			currentRoute = currentRoute.children[0];
		}
		currentRoute.params.subscribe((params) => {
			if (params['projectId']) {
				this.projectId = params['projectId'];
				this.getScheduleDetails();
			}
		});
	}

	ngAfterViewChecked() {
		this.changeDetector.detectChanges();
	}

	getScheduleDetails() {
		if (this.projectId) {
			this.projectService.getProjectSchedule(this.projectId).subscribe({
				next: (projectSchedule) => {
					if (projectSchedule.transitTime) {
						this.transitDays = projectSchedule.transitTime;
						this.transitTotal = this.transitDays + this.transitWeekends;
					}
					if (projectSchedule.installDate) {
						this.projectInstallDate = startOfDay(new Date(projectSchedule.installDate));
						this.shipDate = subDays(this.projectInstallDate, this.transitTotal);
						this.productionEndDate = subDays(this.shipDate, this.shippingBuffer);
						this.showDate = this.productionEndDate;
					}
					this.projectScheduleForm.patchValue(projectSchedule);
					this.productionHours = projectSchedule.productionHours;
					this.projectTasks.clear();
					if (projectSchedule.projectTasks) {
						projectSchedule.projectTasks.forEach((projectTask) => this.addProjectTask(projectTask));
					}
					for (let productionDate in projectSchedule.productionHours) {
						this.header.push({value: productionDate, show: differenceInDays(startOfDay(new Date(productionDate)), this.showDate) >= -2});
					}
				},
				error: (err) => {
					this._snackBar.open('Get Schedule Failed: ' + err.error.message);
					this.busy = false;
				}
			});
		}
	}

	addProjectTask(projectTask: ProjectTask): void {
		if (projectTask.taskType?.name && projectTask.taskType.name !== 'Shipping') {
			let minHours = 0;
			if (projectTask.hours) {
				minHours = projectTask.hours;
			}
			const newTask = this.fb.group({
				id: [projectTask.id],
				projectId: [projectTask.projectId],
				taskTypeId: [projectTask.taskTypeId],
				hours: [projectTask.hours],
				hoursScheduled: [0, [Validators.min(minHours)]],
				value: [projectTask.value],
				name: [projectTask.taskType.name],
				scheduledHours: this.fb.array([]),
				schedule: this.fb.array([]),
				padSize: [this.transitTotal + 3]
			});

			let hoursScheduled = 0;
			if (projectTask.hours) {
				if (projectTask.schedule?.length) {
					projectTask.schedule.sort((a, b) => {
						if (!b.date) {
							return -1;
						}
						if (!a.date) {
							return 1;
						}
						return a.date > b.date ? 1 : -1;
					});
					let lastTaskDate = subDays(this.projectInstallDate, this.transitTotal + 3);

					projectTask.schedule.forEach((schedule) => {
						if (schedule.date) {
							if (schedule.hours) {
								hoursScheduled += schedule.hours;
							}
							lastTaskDate = new Date(schedule.date);
							this.showDates(lastTaskDate);
							this.addProjectTaskSchedule(
								{
									id: schedule.id,
									projectTaskId: projectTask.id,
									date: lastTaskDate,
									hours: schedule.hours
								},
								newTask.controls.schedule
							);
						}
					});
					newTask.controls.padSize.setValue(differenceInDays(this.projectInstallDate, new Date(lastTaskDate)));
					newTask.controls.hoursScheduled.setValue(hoursScheduled);
				} else {
					let scheduleDate = this.productionEndDate;
					let unscheduledHours = projectTask.hours;
					let firstTask = true;
					while (unscheduledHours > 0) {
						scheduleDate = subDays(scheduleDate, 1);
						let availableHours = this.scheduleDayHours;
						if (projectTask.taskTypeId) {
							availableHours = this.getAvailableHours(scheduleDate, projectTask.taskTypeId);
						}
						let scheduleHours;
						if (unscheduledHours % availableHours) {
							scheduleHours = Math.round((unscheduledHours % availableHours) * 100) / 100;
						} else {
							scheduleHours = availableHours;
						}
						if (scheduleHours > 0 || !firstTask) {
							this.showDates(scheduleDate);
							this.addProjectTaskSchedule(
								{
									projectTaskId: projectTask.id,
									date: scheduleDate,
									hours: scheduleHours
								},
								newTask.controls.schedule,
								false
							);
							if (firstTask) {
								newTask.controls.padSize.setValue(differenceInDays(this.projectInstallDate, new Date(scheduleDate)));
								firstTask = false;
							}
						}
						unscheduledHours -= scheduleHours;
						hoursScheduled += scheduleHours;
					}
					newTask.controls.hoursScheduled.setValue(hoursScheduled);
				}
			}

			this.projectTasks.push(newTask);
		}
	}

	get projectTasks(): FormArray {
		return this.projectScheduleForm.controls.projectTasks;
	}

	projectTaskPadSize(projectTask: AbstractControl<any>): FormControl {
		return (projectTask as FormGroup).controls['padSize'] as FormControl;
	}

	projectTaskSchedule(projectTask: AbstractControl<any>): FormArray {
		return (projectTask as FormGroup).controls['schedule'] as FormArray;
	}

	addProjectTaskSchedule(schedule: Schedule, scheduleArray: FormArray, atEnd: boolean = true): void {
		const newSchedule: any = {
			projectTaskId: [schedule.projectTaskId],
			date: [schedule.date],
			hours: [schedule.hours, (control: AbstractControl) => this.validateHours(control, this.productionHours)]
		};
		if (schedule.id) {
			newSchedule.id = [schedule.id];
		}
		if (atEnd) {
			scheduleArray.push(this.fb.group(newSchedule));
		} else {
			scheduleArray.insert(0, this.fb.group(newSchedule));
		}
	}

	cellWidth(size: number) {
		return 'calc(' + this.singleCellWidth + '*' + size + ')';
	}

	shiftTaskBack(t: AbstractControl<any>) {
		const task = t as FormGroup;
		(task.controls['schedule'] as FormArray).controls.forEach((d) => {
			const day = d as FormGroup;
			if (day.controls['date']) {
				day.controls['date'].setValue(subDays(day.controls['date'].value, 1));
				this.showDates(day.controls['date'].value);
				day.controls['hours'].updateValueAndValidity();
			}
		});
		task.controls['padSize'].setValue(task.controls['padSize'].value + 1);
	}

	shiftTaskForward(t: AbstractControl<any>) {
		const task = t as FormGroup;
		(task.controls['schedule'] as FormArray).controls.forEach((d) => {
			const day = d as FormGroup;
			if (day.controls['date']) {
				day.controls['date'].setValue(addDays(day.controls['date'].value, 1));
				day.controls['hours'].updateValueAndValidity();
			}
		});
		task.controls['padSize'].setValue(task.controls['padSize'].value - 1);
	}

	addDayBefore(t: AbstractControl<any>) {
		const task = t as FormGroup;
		const firstDate = this.getFirstDate(task);
		if (firstDate) {
			this.showDates(subDays(firstDate, 1));
			(task.controls['schedule'] as FormArray).insert(
				0,
				this.fb.group({
					projectTaskId: task.controls['projectId'].value,
					date: subDays(firstDate, 1),
					hours: [0, (control: AbstractControl) => this.validateHours(control, this.productionHours)]
				})
			);
		}
	}

	addDayAfter(t: AbstractControl<any>) {
		const task = t as FormGroup;
		const lastDate = this.getLastDate(task);
		if (lastDate) {
			(task.controls['schedule'] as FormArray).push(
				this.fb.group({
					projectTaskId: task.controls['projectId'].value,
					date: addDays(lastDate, 1),
					hours: [0, (control: AbstractControl) => this.validateHours(control, this.productionHours)]
				})
			);
			task.controls['padSize'].setValue(task.controls['padSize'].value - 1);
		}
	}

	showDates(date: Date) {
		if (differenceInDays(date, this.showDate) < 0) {
			this.showDate = date;
			this.header.filter((head) => !head.show && differenceInDays(new Date(head.value), date) >= -1).forEach((head) => (head.show = true));
		}
	}

	increaseTransit() {
		this.transitDays++;
		this.transitTotal = this.transitDays + this.transitWeekends;
		this.productionEndDate = subDays(this.projectInstallDate, this.transitTotal + this.shippingBuffer);
		this.projectTasks.controls.forEach((pTask) => {
			const projectTask = pTask as FormGroup;
			const lastDate = this.getLastDate(projectTask);
			if (lastDate) {
				const dayAdjust = differenceInDays(lastDate, subDays(this.productionEndDate, 1));
				if (dayAdjust > 0) {
					this.projectTaskSchedule(projectTask).controls.forEach((d) => {
						const day = d as FormGroup;
						day.controls['date'].setValue(subDays(day.controls['date'].value, dayAdjust));
						day.controls['hours'].updateValueAndValidity();
						this.showDates(day.controls['date'].value);
					});
					projectTask.controls['padSize'].setValue(projectTask.controls['padSize'].value + dayAdjust);
				}
			}
		});
	}

	decreaseTransit() {
		if (this.transitDays > 1) {
			this.transitDays--;
			this.transitTotal = this.transitDays + this.transitWeekends;
			this.productionEndDate = subDays(this.projectInstallDate, this.transitTotal + this.shippingBuffer);
		}
	}

	getLastDate(task: FormGroup) {
		const schedule = task.controls['schedule'] as FormArray;
		return (schedule.controls[schedule.controls.length - 1] as FormGroup).controls['date'].value;
	}

	getFirstDate(task: FormGroup) {
		return ((task.controls['schedule'] as FormArray).controls[0] as FormGroup).controls['date'].value;
	}

	get visibleHeaderLength() {
		return this.header.filter((header) => header.show).length;
	}

	saveSchedule() {
		let projectTasks: ProjectTask[] = [];
		Object.assign(projectTasks, ...[this.projectTasks.value]);
		projectTasks.forEach((projectTask) => {
			if (projectTask.schedule?.length) {
				let hours = projectTask.schedule[0].hours;
				while (projectTask.schedule.length && (!hours || hours <= 0)) {
					projectTask.schedule.splice(0, 1);
					hours = projectTask.schedule[0].hours;
				}
				hours = projectTask.schedule[projectTask.schedule.length - 1].hours;
				while (projectTask.schedule.length && (!hours || hours <= 0)) {
					projectTask.schedule.splice(-1);
					hours = projectTask.schedule[projectTask.schedule.length - 1].hours;
				}
			}
		});
		if (this.projectId) {
			this.projectService.updateProjectSchedule(this.projectId, projectTasks).subscribe({
				next: (projectSchedule) => {
					console.log(projectSchedule);
				},
				error: (err) => {
					this._snackBar.open('Save Schedule Failed: ' + err.error.message);
					this.busy = false;
				}
			});
		}
	}

	getProductionHours(date: string, task: ProjectTask) {
		if (task.taskTypeId) {
			return this.productionHours[date][task.taskTypeId].hours;
		}
		return 0;
	}

	getProductionSchedule(date: string, task: ProjectTask) {
		let scheduledHours = 0;
		if (task.taskTypeId) {
			scheduledHours = this.productionHours[date][task.taskTypeId].scheduled;
			const schedule = task.schedule?.find((schedule) => schedule.date && schedule.date.toISOString().substring(0, 10) === date);
			if (schedule && schedule.hours) {
				scheduledHours += schedule.hours;
			}
		}
		return scheduledHours;
	}

	updateHours(t: AbstractControl<any>) {
		const task = t as FormGroup;
		let hoursScheduled = 0;
		(task.controls['schedule'] as FormArray).controls.forEach((d) => {
			const day = d as FormGroup;
			if (day.controls['hours']) {
				hoursScheduled += day.controls['hours'].value;
			}
		});
		task.controls['hoursScheduled'].setValue(hoursScheduled);
	}

	taskHours(t: AbstractControl<any>) {
		const task = t as FormGroup;
		return '(' + task.value.hoursScheduled + '/' + task.value.hours + ')';
	}

	validateHours(hours: AbstractControl, productionHours: ProductionHours) {
		let invalid = true;
		const scheduleForm = hours.parent as FormGroup;
		if (scheduleForm) {
			const scheduleArray = scheduleForm.parent as FormArray;
			const date = scheduleForm.controls['date'].value;
			if (scheduleArray) {
				const projectTaskForm = scheduleArray.parent as FormGroup;
				if (projectTaskForm) {
					const taskTypeId = projectTaskForm.controls['taskTypeId'].value;
					invalid =
						hours.value > 0 &&
						productionHours[date.toISOString().substring(0, 10)][taskTypeId].hours <
							productionHours[date.toISOString().substring(0, 10)][taskTypeId].scheduled + hours.value;
				}
			}
		}
		return invalid ? {hours} : null;
	}

	getAvailableHours(date: Date, taskTypeId: number) {
		return Math.max(
			0,
			Math.min(
				this.scheduleDayHours,
				this.productionHours[date.toISOString().substring(0, 10)][taskTypeId].hours -
					this.productionHours[date.toISOString().substring(0, 10)][taskTypeId].scheduled
			)
		);
	}

	maxHours(taskIndex: number, s: AbstractControl<any>) {
		const scheduleForm = s as FormGroup;
		const date = scheduleForm.controls['date'].value;
		const taskForm = this.projectTasks.controls[taskIndex] as FormGroup;
		const taskTypeId = taskForm.controls['taskTypeId'].value;
		return Math.max(
			0,
			this.productionHours[date.toISOString().substring(0, 10)][taskTypeId].hours -
				this.productionHours[date.toISOString().substring(0, 10)][taskTypeId].scheduled
		);
	}

	inputFocused(e: Event) {
		const input = e.target as HTMLInputElement;
		input.select();
	}
}
