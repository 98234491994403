import {Component, Input} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {CommaRemovalPipe} from '../../../shared/pipes/comma-removal.pipe';
import {CurrencyPipe, DecimalPipe} from '@angular/common';

@Component({
	selector: 'app-additional-info',
	templateUrl: './additional-info.component.html',
	styleUrls: ['./additional-info.component.scss']
})
export class AdditionalInfoComponent {
	@Input() parentForm: FormGroup;

	constructor(private format: CommaRemovalPipe, private decimalPipe: DecimalPipe, private currencyPipe: CurrencyPipe) {}

	checkForTrailingZero(element: HTMLElement) {
		const name = element.getAttribute('formControlName');
		if (name) {
			this.parentForm.get(name)?.setValue(this.format.transform(this.parentForm.get(name)?.value, false));
		}
	}

	get estimatedTaxes() {
		return this.currencyPipe.transform(parseFloat(this.format.transform(this.parentForm.get('taxes')?.value, true)));
	}
}
