<mat-accordion [formGroup]="freightInstallForm">
	<mat-expansion-panel expanded="false">
		<mat-expansion-panel-header>
			<mat-panel-title> Associated Costs </mat-panel-title>
			<mat-label class="mr-4">{{ this.associatedCostsTotal | currency }}</mat-label>
		</mat-expansion-panel-header>

		<div class="flex flex-col">
			<ng-container [formGroup]="freightInstallForm">
				<div class="flex flex-row ml-4 mb-2">
					<span class="font-bold font-bold w-5/12"> Description </span>
					<span class="font-bold font-bold w-1/3 ml-2"> Cost </span>
				</div>

				<mat-divider></mat-divider>

				<div formArrayName="associatedCosts" class="ml-4" *ngFor="let cost of associatedCostFormArray.controls; let i = index">
					<div class="flex flex-row space-y-4 items-center" [formGroupName]="i">
						<span class="mt-4 w-5/12">{{ cost.get('description')?.value }}</span>
						<mat-form-field appearance="outline" subscriptSizing="dynamic" class="w-1/3">
							<mat-label>Price</mat-label>
							<span matPrefix class="ml-3 text-gray-500">$</span>
							<input matInput formControlName="price" IsDecimal (blur)="checkForTrailingZero(i)" class="text-end" />
						</mat-form-field>
					</div>
				</div>
			</ng-container>
		</div>
	</mat-expansion-panel>
</mat-accordion>
