import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {Status} from '../models/status.interface';
import {Part} from '../models/part.interface';

@Injectable({
	providedIn: 'root'
})
export class StatusService {
	statusDataSource: BehaviorSubject<Status[]> = new BehaviorSubject<Status[]>([]);
	readonly statuses: Observable<Status[]> = this.statusDataSource.asObservable();

	constructor(private http: HttpClient) {
		this.findAll();
	}

	findAll(): void {
		this.http.get<Status[]>(`${environment.url}/core/statuses`).subscribe((statuses) => {
			this.statusDataSource.next(statuses);
		});
	}
}
