<div class="mt-4" [hidden]="(maxFileLength == 1 && uploadedFiles?.length) || (maxFileLength == 1 && files.length)">
	<mat-expansion-panel expanded>
		<mat-expansion-panel-header>
			<mat-panel-title> {{ type ? type.name : 'Choose a Location...' }} </mat-panel-title>
		</mat-expansion-panel-header>
		<ngx-dropzone
			(change)="onFilesAdded($event)"
			[expandable]="true"
			[ngStyle]="{
				minHeight: '200px',
				height: 'fit-content',
				overflow: 'auto',
				alignItems: this.files.length || this.uploadedFiles?.length ? 'start' : 'center'
			}"
		>
			<ngx-dropzone-label>
				<div class="bg-violet-300 p-2 circle">
					<mat-icon>upload_file</mat-icon>
				</div>
				<span class="text-violet-600 underline">Click to upload&nbsp;</span><span>or drag and drop.</span>
				<p class="text-gray-600 mt-2">PDF, CAD, or ZIP</p>
			</ngx-dropzone-label>
			<!--      File Previews-->
			<ngx-dropzone-preview
				*ngFor="let f of files"
				[file]="f"
				[removable]="true"
				(removed)="onRemove(f)"
				(click)="stopPropagation($event)"
				class="cursor-default border border-black relative"
			>
				<ngx-dropzone-label class="text-black"
					>{{ f.name }} ({{ f.type }})
					<span class="absolute bottom-0 right-1 italic text-red">New</span>
				</ngx-dropzone-label>
			</ngx-dropzone-preview>
			<!--      Already Uploaded Previews-->
			<ngx-dropzone-preview
				class="border border-black relative"
				*ngFor="let file of uploadedFiles"
				[removable]="true"
				(click)="downloadFile($event, file.documentHash)"
				(removed)="openDeleteModal(file)"
			>
				<ngx-dropzone-label class="text-black"
					>{{ file.documentName }} ({{ file.type }})
					<span class="absolute bottom-0 right-1 italic text-green">Uploaded</span>
				</ngx-dropzone-label>
			</ngx-dropzone-preview>
		</ngx-dropzone>
	</mat-expansion-panel>
</div>
<!--Survey New File Preview-->
<div class="mt-4" *ngIf="maxFileLength == 1 && files.length">
	<ngx-dropzone-preview
		*ngFor="let f of files"
		[file]="f"
		[removable]="true"
		(removed)="onRemove(f)"
		(click)="stopPropagation($event)"
		class="flex justify-center cursor-default border border-black relative"
	>
		<ngx-dropzone-label class="text-black text-center">
			<span class="absolute top-0 left-1 italic text-gray-500">Survey Doc:</span>
			{{ f.name }} ({{ f.type }})
			<span class="absolute bottom-0 right-1 italic text-red">New</span>
		</ngx-dropzone-label>
	</ngx-dropzone-preview>
</div>
<!--Survey Uploaded File Preview-->
<div class="mt-4" *ngIf="maxFileLength == 1 && uploadedFiles?.length && this.subType == 'Survey'">
	<ngx-dropzone-preview
		*ngFor="let file of uploadedFiles"
		[removable]="true"
		(click)="downloadFile($event, file.documentHash)"
		(removed)="openDeleteModal(file)"
		class="flex justify-center cursor-pointer border border-black relative"
	>
		<ngx-dropzone-label class="text-black text-center">
			<span class="absolute top-0 left-1 italic text-gray-500">Survey Doc:</span>
			{{ file.documentName }} ({{ file.type }})
			<span class="absolute bottom-0 right-1 italic text-green">Uploaded</span>
		</ngx-dropzone-label>
	</ngx-dropzone-preview>
</div>
