import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpResponse} from '@angular/common/http';
import {BehaviorSubject, catchError, map, Observable, throwError} from 'rxjs';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Router} from '@angular/router';
import {environment} from '../../../environments/environment';
import {User} from '../models/user.interface';
import {ResetPassword} from '../models/reset-password.interface';

@Injectable()
export class AuthService {
	// store the URL so we can redirect after logging in
	public redirectUrl: string | null = null;
	public userLoggedIn: boolean = false;
	attemptedUser: string;

	public userDataSource: BehaviorSubject<User | null> = new BehaviorSubject<User | null>(null);
	readonly user: Observable<User | null> = this.userDataSource.asObservable();

	public resetPasswordDataSource: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	readonly resetPassword: Observable<boolean> = this.resetPasswordDataSource.asObservable();

	constructor(private http: HttpClient, private router: Router, private snackBar: MatSnackBar) {}

	login(credentials: any): Observable<HttpResponse<any>> {
		return this.http
			.post(
				`${environment.url}/login`,
				{
					username: credentials.username,
					password: credentials.password
				},
				{withCredentials: true, observe: 'response'}
			)
			.pipe(
				map((res: any) => {
					if (res?.body?.challenge === 'NEW_PASSWORD_REQUIRED') {
						this.resetPasswordDataSource.next(true);
						this.attemptedUser = credentials.username;
						this.router.navigate(['/new-login']);
						return;
					} else {
						this.userLoggedIn = true;
						if (this.router.url === '/login') {
							if (this.redirectUrl && this.redirectUrl !== '/login') {
								this.router.navigate([this.redirectUrl]);
								this.redirectUrl = null;
							} else {
								//Default app landing page
								this.router.navigate(['/projects']);
							}
						}
						return res;
					}
				}),
				catchError((error) => {
					return throwError(() => new HttpErrorResponse(error));
				})
			);
	}

	getCurrentUser(): Observable<User> {
		return this.http.get<User>(`${environment.url}/users/current`);
	}

	firstLoginResetPassword(payload: ResetPassword): Observable<void> {
		return this.http.post<void>(`${environment.url}/login/new`, payload);
	}

	requestPasswordReset(username: string): Observable<void> {
		return this.http.post<void>(`${environment.url}/login/forgot`, {username});
	}

	resetUserPassword(payload: ResetPassword): Observable<void> {
		return this.http.post<void>(`${environment.url}/login/reset`, payload);
	}

	logout() {
		this.http.post(`${environment.url}/logout`, {}).subscribe({
			next: (response) => this.clearUserDetails(),
			error: (error) => this.clearUserDetails(error)
		});
	}

	clearUserDetails(error?: string) {
		this.redirectUrl = null;
		this.userLoggedIn = false;
		this.userDataSource.next(null);
		this.router.navigate(['/login']);

		if (error) {
			// Since the user will still be logged out locally, and if there's an error that means that weren't globally signed
			// out, just log error
			console.error(error);
		}
	}
}
