import {Component} from '@angular/core';
import {AuthService} from '../../services/auth.service';
import {User} from '../../models/user.interface';
import {navData, navItem} from './nav-options';
import {Router} from '@angular/router';
import {MatDialog} from '@angular/material/dialog';

@Component({
	selector: 'app-main-toolbar',
	templateUrl: './main-toolbar.component.html',
	styleUrls: ['./main-toolbar.component.scss']
})
export class MainToolbarComponent {
	user: User | null;
	navOptions: navItem[];
	constructor(private authService: AuthService, private router: Router, private dialog: MatDialog) {}

	ngOnInit() {
		this.authService.user.subscribe((user) => {
			this.user = user;
		});
		this.navOptions = navData;
	}

	getInitials(name: string | undefined): string {
		if (!name) {
			return '';
		}
		return name
			.split(' ')
			.map((n) => n[0])
			.join('');
	}

	getColor(name: string | undefined): string {
		if (!name) {
			return '';
		}
		const color = name.split('').reduce((acc, curr) => acc + curr.charCodeAt(0), 0) % 360;
		return `hsl(${color}, 70%, 50%)`;
	}

	navigate(item: navItem): void {
		this.router.navigate([item.routerLink], item.extras);
	}

	logout() {
		this.authService.logout();
	}
}
