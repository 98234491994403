import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Subject, Subscription} from 'rxjs';
import {ContactService} from '../../../core/services/contact.service';
import {Contact} from '../../../core/models/contact.model';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {SnackbarActionEnum} from '../../../core/enums/snackbar-action.enum';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
	selector: 'app-contacts',
	templateUrl: './contacts.component.html',
	styleUrls: ['./contacts.component.scss']
})
export class ContactsComponent implements OnInit {
	@Input() clientId: number;
	@Input() selectedContacts: Contact[] = [];
	@Input() parentContactForm: FormGroup;

	@Output() createContactEvent = new EventEmitter<boolean>();
	@Output() selectedContactsChanged = new EventEmitter<Contact[]>();

	contactForm: FormGroup;
	contactSubscription: Subscription;
	contactDeletedSubject: Subject<number> = new Subject<number>();
	contactCreatedSubject: Subject<Contact> = new Subject<Contact>();
	busy: boolean = true;
	contacts: Contact[] = [];
	createContact: boolean = false;

	newContactForm: FormGroup;
	parentForm: FormGroup;

	constructor(private contactService: ContactService, private fb: FormBuilder, private snackbar: MatSnackBar) {}

	ngOnInit(): void {
		this.getContacts();
		this.buildContactForm();
	}

	ngOnDestroy(): void {
		this.contactSubscription.unsubscribe();
	}

	buildContactForm(): void {
		this.contactForm = this.fb.group({
			name: [null, Validators.required],
			email: [null, [Validators.required, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
			phone: [null],
			phoneExt: [null]
		});
	}

	getContacts(): void {
		this.contactSubscription = this.contactService.findByClient(this.clientId, false).subscribe({
			next: (contacts: Contact[]) => {
				this.contacts = contacts;
				this.busy = false;
			},
			error: (err: Error) => {
				this.snackbar.open('Failed to retrieve contacts for this client', SnackbarActionEnum.ERROR);
			}
		});
	}

	selectChange(event: any): void {
		this.selectedContacts = [...event.data];
		this.selectedContactsChanged.emit(this.selectedContacts);
	}

	delete(contactToDelete: Contact): void {
		if (this.selectedContacts) {
			const index: number = this.selectedContacts.findIndex((contact: Contact) => contact === contactToDelete);
			if (index !== -1) {
				this.selectedContacts.splice(index, 1);
				this.selectedContactsChanged.emit(this.selectedContacts);
			}
			this.contactDeletedSubject.next(contactToDelete.id!);
		}
	}

	saveContact(): void {
		let contact: Contact = {};
		Object.assign(contact, this.contactForm.value);
		contact.typeId = this.clientId;
		contact.contactType = 'CLIENT';

		this.contactService.create(contact).subscribe({
			next: (contact: Contact) => {
				this.getContacts();
				this.selectedContacts.push(contact);
				this.selectedContactsChanged.emit(this.selectedContacts);
				this.contactCreatedSubject.next(contact);
				this.buildContactForm();
				this.onCancelCreate();
				this.snackbar.open('Saved new contact', SnackbarActionEnum.SUCCESS);
			},
			error: (err: Error) => this.snackbar.open('Failed to save new contact', SnackbarActionEnum.ERROR)
		});
	}

	onCreateContact(): void {
		this.createContact = true;
		this.createContactEvent.emit(true);
	}

	onCancelCreate(): void {
		this.createContact = false;
		this.createContactEvent.emit(false);
	}
}
