<b id="title" class="ml-12">
	{{ stage.description | titlecase }} Quick Status
	<mat-icon id="edit-button" (click)="openModal()" class="cursor-pointer material-icons-outlined"> mode_edit_outline </mat-icon>
</b>

<div class="flex justify-between items-center h-full w-3/5 m-auto relative mt-8">
	<div
		*ngFor="let status of stage.statuses"
		class="circle"
		[ngClass]="project.workflowStatus.predecessor >= status.predecessor ? 'green-step' : 'gray-step'"
	>
		<mat-icon *ngIf="project.workflowStatus.predecessor > status.predecessor"> check_circle_outline </mat-icon>
		<span class="status-description">{{ status.description }}</span>
	</div>
	<div class="line"></div>
</div>
