import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {HttpClientModule} from '@angular/common/http';
import {ProjectsService} from './core/services/projects.service';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MAT_FORM_FIELD_DEFAULT_OPTIONS, MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatIconModule} from '@angular/material/icon';
import {MatTableModule} from '@angular/material/table';
import {MatCardModule} from '@angular/material/card';
import {MatSortModule} from '@angular/material/sort';
import {MatPaginatorModule} from '@angular/material/paginator';
import {ProjectsModule} from './projects/projects.module';
import {ReactiveFormsModule} from '@angular/forms';
import {ClientSiteService} from './core/services/client-site.service';
import {ContactService} from './core/services/contact.service';
import {LoginComponent} from './core/components/login/login.component';
import {AuthInterceptorProviders} from './core/interceptors/auth.interceptor';
import {MatDialogModule} from '@angular/material/dialog';
import {MatButtonModule} from '@angular/material/button';
import {AuthService} from './core/services/auth.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {CookieService} from 'ngx-cookie-service';
import {AppInitProvider, AppInitService} from './core/services/app-init.service';
import {QuoteService} from './core/services/quote.service';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {SharedModule} from './shared/shared.module';
import {CodeService} from './core/services/code.service';
import {ProjectModule} from './project/project.module';
import {MatRadioModule} from '@angular/material/radio';
import {ProjectCreateModule} from './project-create/project-create.module';
import {MainToolbarComponent} from './core/components/main-toolbar/main-toolbar.component';
import {MatMenuModule} from '@angular/material/menu';
import {MatBadgeModule} from '@angular/material/badge';
import {SideNavComponent} from './core/components/side-nav/side-nav.component';
import {MatSidenavModule} from '@angular/material/sidenav';
import {FooterComponent} from './core/components/footer/footer.component';
import {UsersModule} from './users/users.module';
import {QuoteModule} from './quote/quote.module';
import {ChangePasswordRequestComponent} from './core/components/change-password-request/change-password-request.component';
import {FirstLoginResetPasswordComponent} from './core/components/first-login-reset-password/first-login-reset-password.component';

@NgModule({
	declarations: [
		AppComponent,
		LoginComponent,
		MainToolbarComponent,
		SideNavComponent,
		FooterComponent,
		FirstLoginResetPasswordComponent,
		ChangePasswordRequestComponent
	],
	imports: [
		BrowserModule,
		AppRoutingModule,
		HttpClientModule,
		BrowserAnimationsModule,
		MatCardModule,
		MatTableModule,
		MatFormFieldModule,
		MatInputModule,
		MatSortModule,
		MatIconModule,
		MatTableModule,
		ProjectCreateModule,
		MatPaginatorModule,
		ProjectModule,
		ProjectsModule,
		ReactiveFormsModule,
		MatDialogModule,
		MatButtonModule,
		MatAutocompleteModule,
		SharedModule,
		MatRadioModule,
		MatMenuModule,
		MatBadgeModule,
		MatSidenavModule,
		UsersModule,
		QuoteModule
	],
	providers: [
		ProjectsService,
		QuoteService,
		AuthService,
		AuthInterceptorProviders,
		MatSnackBar,
		CookieService,
		AppInitProvider,
		AppInitService,
		ClientSiteService,
		ContactService,
		CodeService,

		{provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: {appearance: 'outline'}}
	],
	bootstrap: [AppComponent]
})
export class AppModule {}
