import {Component, OnInit, ViewChild} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {UserService} from '../../core/services/user.service';
import {MatSort} from '@angular/material/sort';
import {CreateUserComponent} from '../create-user/create-user.component';
import {MatDialog} from '@angular/material/dialog';
import {RolesService} from '../../core/services/roles.service';
import {Role} from '../../core/models/role.interface';

@Component({
	selector: 'app-users-list',
	templateUrl: './users-list.component.html',
	styleUrls: ['./users-list.component.scss'],
	animations: [
		trigger('detailExpand', [
			state('collapsed', style({height: '0px', minHeight: '0'})),
			state('expanded', style({height: '*'})),
			transition('expanded <=> collapsed', animate('200ms cubic-bezier(0.4, 0.0, 0.2, 1)'))
		])
	]
})
export class UsersListComponent implements OnInit {
	@ViewChild(MatSort) set matSort(ms: MatSort) {
		this.sort = ms;
		this.dataSource.sort = this.sort;
	}
	sort: MatSort;
	dataSource: MatTableDataSource<any> = new MatTableDataSource<any>();
	columnsToDisplay: string[] = ['name', 'email', 'phone'];
	isLoadingUsers: boolean = true;
	roles: Role[] = [];
	constructor(private userService: UserService, private dialog: MatDialog, private rolesService: RolesService) {}

	ngOnInit() {
		this.dataSource = new MatTableDataSource();
		this.userService.findAll();
		this.userService.usersSubject.subscribe((response) => {
			// Store the expanded state of each row before updating the data source
			const expandedStates: boolean[] = [];
			for (let i = 0; i < this.dataSource.data.length; i++) {
				expandedStates[i] = this.dataSource.data[i].expanded;
			}
			// Update the data source with new data
			for (const updatedRow of response) {
				const index = this.dataSource.data.findIndex((row) => row.id === updatedRow.id);
				if (index !== -1) {
					// Preserve the expanded state of the row before updating it
					updatedRow.expanded = this.dataSource.data[index].expanded;
					this.dataSource.data[index] = updatedRow;
				} else {
					// Add the new row to the data source
					this.dataSource.data.push(updatedRow);
				}
			}
			// Restore the expanded state of each row after updating the data source
			for (let i = 0; i < this.dataSource.data.length; i++) {
				this.dataSource.data[i].expanded = expandedStates[i];
			}
			// Refresh the data source to update the table
			this.dataSource.data = [...this.dataSource.data];
			this.isLoadingUsers = false;
		});
		this.rolesService.findAll().subscribe((response) => {
			this.roles = response;
		});
	}

	applyFilter(event: Event) {
		const filterValue = (event.target as HTMLInputElement).value;
		this.dataSource.filter = filterValue.trim().toLowerCase();
	}

	openCreateUserModal() {
		const dialogRef = this.dialog.open(CreateUserComponent, {
			width: '70vw',
			disableClose: true
		});
		dialogRef.componentInstance.roles = this.roles;
	}
}
