import {Component, OnInit, Input, OnDestroy} from '@angular/core';
import {Project} from 'src/app/core/models/project.interface';
import {StatusService} from '../../core/services/status.service';
import {Status} from '../../core/models/status.interface';
import {Subscription} from 'rxjs';
import {QuickStatusEditComponent} from './project-quick-status-edit/quick-status-edit.component';
import {MatDialog} from '@angular/material/dialog';
import {ProjectsService} from '../../core/services/projects.service';
import {UpdateProject} from '@angular/cdk/schematics';
@Component({
	selector: 'app-project-quick-status',
	templateUrl: './project-quick-status.component.html',
	styleUrls: ['./project-quick-status.component.scss']
})
export class ProjectQuickStatusComponent implements OnInit, OnDestroy {
	@Input() project: Project;
	statusSubscription: Subscription;
	statuses: Status[] = [];
	stage: Status;

	constructor(private statusService: StatusService, private dialog: MatDialog, private projectService: ProjectsService) {}

	ngOnInit() {
		this.statusSubscription = this.statusService.statusDataSource.subscribe((response) => {
			this.statuses = response;
			this.stage = this.statuses.find((status) => status.id === this.project.workflowStatus.subParentId)!;
		});
	}

	openModal() {
		const dialogRef = this.dialog.open(QuickStatusEditComponent, {
			width: '300px',
			disableClose: true
		});
		dialogRef.componentInstance.currentStatusId = this.project.workflowStatus.id;
		dialogRef.componentInstance.currentDepartmentId = this.stage.id;
		dialogRef.componentInstance.allStatuses = this.statuses;

		//Listen for the update event which will
		dialogRef.componentInstance.updatesStatusId.subscribe((result) => {
			const project = <Project>this.project;
			project.workflowStatus.id = result;
			//Send only the new status ID
			this.projectService.update({id: project.id, workflowStatus: project.workflowStatus});
			dialogRef.componentInstance.closeModal();
		});
	}

	ngOnDestroy() {
		this.statusSubscription.unsubscribe();
	}
}
