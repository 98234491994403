<h2 mat-dialog-title>Create User</h2>
<mat-dialog-content class="mat-typography m-2" [formGroup]="form">
	<mat-form-field class="w-2/5 pt-2">
		<mat-label>Email</mat-label>
		<input matInput formControlName="email" />
	</mat-form-field>
	<mat-form-field class="w-2/5 pt-2">
		<mat-label>Name</mat-label>
		<input matInput formControlName="name" />
	</mat-form-field>
	<div class="w-2/5 h-20 flex justify-between">
		<mat-form-field class="w-8/12">
			<mat-label>Phone</mat-label>
			<input matInput formControlName="phone" />
		</mat-form-field>
		<mat-form-field class="w-3/12">
			<mat-label>Ext</mat-label>
			<input matInput formControlName="phoneExt" />
		</mat-form-field>
	</div>
	<mat-form-field class="w-2/5">
		<mat-label>Description</mat-label>
		<input matInput formControlName="description" />
	</mat-form-field>
	<fieldset class="w-2/5 border border-gray-500 rounded">
		<legend class="ml-4 px-2">User Type*</legend>
		<mat-radio-group aria-label="Select an option" color="primary" formControlName="type">
			<mat-radio-button class="w-1/3 pl-2" [value]="option" *ngFor="let option of userTypeOptions">{{
				option | titlecase
			}}</mat-radio-button>
		</mat-radio-group>
	</fieldset>
	<mat-form-field class="w-2/5" (click)="form.enable()" tabindex="0">
		<mat-label>Role</mat-label>
		<mat-select formControlName="roleId">
			<mat-option *ngFor="let role of roles" [value]="role.id">{{ role.name }}</mat-option>
		</mat-select>
	</mat-form-field>
</mat-dialog-content>
<div class="flex justify-end p-4">
	<button mat-button mat-dialog-close class="mr-2">Cancel</button>
	<button [disabled]="form.invalid" mat-raised-button color="primary" (click)="createUser()">Create</button>
</div>
