<mat-accordion [formGroup]="freightInstallForm">
	<mat-expansion-panel expanded="false">
		<mat-expansion-panel-header>
			<mat-panel-title> Installation Details </mat-panel-title>
			<mat-label class="mr-4">{{ this.installCost }}</mat-label>
		</mat-expansion-panel-header>

		<div class="flex flex-row space-x-4">
			<mat-label class="w-1/3 mt-4"> Percentage of Items Total </mat-label>

			<ng-container [formGroup]="percentageGroup">
				<mat-form-field appearance="outline" class="w-1/4">
					<mat-label> Percentage</mat-label>
					<input
						matInput
						type="text"
						formControlName="percentageControl"
						IsDecimal
						(keyup)="percentageChanged()"
						(blur)="trailingZeroCheck()"
					/>
					<span matSuffix class="mr-3 text-gray-500">%</span>
				</mat-form-field>
			</ng-container>

			<mat-form-field appearance="outline" class="w-1/2">
				<span matPrefix class="ml-3 text-gray-500">$</span>
				<mat-label> Estimated Install Cost</mat-label>
				<input matInput type="text" formControlName="installCost" IsDecimal (keyup)="estimatedCostChanged()" (blur)="trailingZeroCheck()" />
				<mat-error *ngIf="freightInstallForm.controls['installCost'].touched && freightInstallForm.controls['installCost'].invalid">
					Estimated Cost is required
				</mat-error>
			</mat-form-field>
		</div>
	</mat-expansion-panel>
</mat-accordion>
