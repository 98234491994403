<mat-expansion-panel [expanded]="true">
	<mat-expansion-panel-header>
		<mat-panel-title> Client </mat-panel-title>
	</mat-expansion-panel-header>

	<mat-form-field appearance="outline" class="w-1/3" color="primary">
		<mat-label>Search</mat-label>
		<input
			id="client-searchbar"
			type="text"
			(keyup)="applyFilter($event)"
			placeholder="Search by name, sales rep or location."
			matInput
			#input
		/>
	</mat-form-field>
	<div class="border border-gray-500 rounded overflow-hidden min-h-[275px]">
		<table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8 w-full">
			<!-- Checkbox Column -->
			<ng-container matColumnDef="select">
				<th mat-header-cell *matHeaderCellDef></th>
				<td mat-cell *matCellDef="let row">
					<mat-checkbox [disableRipple]="true" color="primary" [checked]="row.id === selectedClient?.id"> </mat-checkbox>
				</td>
			</ng-container>

			<!-- Name, otherwise known as Id, Column -->
			<ng-container matColumnDef="id">
				<th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by name">Name</th>
				<td mat-cell *matCellDef="let element">{{ element.id ? (element.id | titlecase) : 'N/A' }}</td>
			</ng-container>

			<!-- Contact Column -->
			<ng-container matColumnDef="contactName">
				<th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by rep">Contact</th>
				<td mat-cell *matCellDef="let element">
					{{ element.contactName ? (element.contactName | titlecase) : 'N/A' }}
				</td>
			</ng-container>

			<!-- Location Column -->
			<ng-container matColumnDef="location">
				<th mat-header-cell *matHeaderCellDef>Location</th>
				<td mat-cell *matCellDef="let element">
					<mat-icon class="relative -bottom-1 text-gray-300">location_pin</mat-icon>
					<!--{{ element.postal ? element.postal + ',' : '' }}-->
					{{ element.state ? (element.state | state) : 'N/A' }}
				</td>
			</ng-container>

			<!-- Rows  -->
			<tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
			<tr
				mat-row
				*matRowDef="let row; columns: displayedColumns"
				class="cursor-pointer"
				(click)="setSelectedClient(row)"
				[ngClass]="selectedClient && selectedClient.id === row.id ? '!bg-gray-200' : 'inherit'"
			></tr>

			<!-- Row shown when there is no matching data. -->
			<tr class="mat-row" *matNoDataRow>
				<td class="mat-cell p-4" colspan="4">No data matching the filter "{{ input.value }}"</td>
			</tr>
		</table>
		<div class="h-[240px] w-full flex justify-center align-middle items-center" *ngIf="isLoadingClients">
			<mat-spinner></mat-spinner>
		</div>
		<mat-paginator
			class="flex justify-between"
			pageSize="5"
			[pageSizeOptions]="[5, 10, 25]"
			aria-label="Select page of users"
			showFirstLastButtons
		></mat-paginator>
	</div>
</mat-expansion-panel>
