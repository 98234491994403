<div class="m-8">
	<div class="flex-1 spacers" *ngFor="let activity of projectActivities">
		<div class="flex flex-row text-base">
			<div class="flex justify-center bg-primary-500 h-10 w-10 rounded-3xl">
				<div class="text-light leading-10 text-lg">{{ activity.userInitial }}</div>
			</div>
			<div class="mat-step leading-10 ml-2">{{ activity.description }} {{ activity.dateTimeFormatted }}</div>
		</div>
		<div class="spacer-line"></div>
	</div>
</div>
