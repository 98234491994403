import {AfterViewInit, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {ProjectDocument} from '../../core/models/project-document.interface';
import {InstallLocation} from '../../core/models/install-location.interface';
import {ProjectDocumentService} from '../../core/services/project-document.service';
import {ProjectDraftingDeleteComponent} from '../../project/project-drafting/project-drafting-delete/project-drafting-delete.component';
import {MatDialog} from '@angular/material/dialog';
import {SnackbarActionEnum} from '../../core/enums/snackbar-action.enum';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Subscription} from 'rxjs';
import {ProjectsService} from '../../core/services/projects.service';

@Component({
	selector: 'app-project-file-upload',
	templateUrl: './project-file-upload.component.html',
	styleUrls: ['./project-file-upload.component.scss']
})
export class ProjectFileUploadComponent implements OnInit, OnChanges, AfterViewInit {
	@Input() projectId: number;
	@Input() type: InstallLocation;
	@Input() departmentId: string;
	@Input() uploadedFiles: ProjectDocument[] | undefined;
	@Input() fileChanges: ProjectDocument[] = [];
	@Input() maxFileLength: number;
	@Input() subType: string;
	@Output('fileAddEmitter') fileAddEmitter = new EventEmitter<File[]>();
	@Output('fileRemoveEmitter') fileRemoveEmitter = new EventEmitter<File>();
	@Output('documentDtoEmitter') documentDtoEmitter = new EventEmitter<ProjectDocument[]>();
	files: File[] = [];
	projectDocumentDtos: ProjectDocument[] = [];
	surveyFileUploadSubscription: Subscription;

	constructor(
		private projectDocumentsService: ProjectDocumentService,
		private projectsService: ProjectsService,
		private dialog: MatDialog,
		private snackbar: MatSnackBar
	) {}
	ngOnInit() {}
	ngAfterViewInit() {
		if (this.maxFileLength == 1) {
			this.uploadedFiles = this.uploadedFiles ? this.uploadedFiles.filter((file) => file.subType == 'Survey') : this.uploadedFiles;
			this.surveyFileUploadSubscription = this.projectDocumentsService.surveyFileUploadedSubject.subscribe((response) => {
				if (!this.uploadedFiles) this.uploadedFiles = [];
				this.uploadedFiles.push(this.projectDocumentDtos[0]);
				this.files = [];
			});
		}
	}

	ngOnChanges(changes: SimpleChanges) {
		if (this.uploadedFiles) {
			this.uploadedFiles = this.uploadedFiles.filter((each: ProjectDocument) => {
				return each.installLocationId === this.type.id;
			});
		}
		if (this.fileChanges.length) {
			this.files = this.files.filter((each: File, i: number) => {
				let fileMatch = this.fileChanges.find((doc) => {
					return doc.documentName === each.name;
				});
				return !fileMatch;
			});

			this.fileChanges.forEach((each: ProjectDocument) => {
				if (this.uploadedFiles?.includes(each) || each.installLocationId !== this.type.id) {
					return;
				} else {
					this.uploadedFiles?.push(each);
				}
			});
		}
	}

	//this any type is here because this is a custom event and the docs provide no actual type for it.
	onFilesAdded(event: any) {
		if (!this.type) {
			alert("Please choose a location in the 'Project Details' before uploading files!");
			return;
		}
		const fileArray: File[] = [];
		fileArray.push(...event.addedFiles);
		fileArray.forEach((each: File, i: number) => {
			const validFile = this.detectFileType(fileArray[i].name);
			if (validFile) {
				this.files.push(fileArray[i]);
				this.projectDocumentDtos.push({
					documentName: fileArray[i].name,
					type: fileArray[i].type,
					subType: this.subType ? this.subType : '',
					version: '',
					installLocationId: this.type.id,
					workflowStatusId: this.departmentId ? +this.departmentId : 0,
					documentHash: '',
					versionSeq: 1,
					archived: false,
					projectId: this.projectId ? +this.projectId : 0,
					userId: 0
				});
			} else {
				alert(`File: ${fileArray[i].name} is of an invalid type! Please upload only PDF, CAD, or Zip files.`);
			}
		});

		this.fileAddEmitter.emit(fileArray);
		this.documentDtoEmitter.emit(this.projectDocumentDtos);
		if (this.maxFileLength == 1 && this.files.length) {
			this.projectDocumentsService.surveyFileSubject.next(fileArray[0]);
			this.projectDocumentsService.surveyDtoSubject.next(this.projectDocumentDtos[0]);
		}
	}

	detectFileType(name: string) {
		let allowedExtensions = /(\.pdf|\.cad|\.zip)$/i;
		return allowedExtensions.exec(name);
	}

	onRemove(file: File) {
		if (this.maxFileLength == 1) {
			this.projectDocumentsService.surveyFileRemoveSubject.next(file);
			this.projectDocumentsService.surveyDtoRemoveSubject.next(this.projectDocumentDtos[0]);
			this.projectDocumentDtos = [];
			this.uploadedFiles = [];
		} else {
			this.fileRemoveEmitter.emit(file);
		}
		this.files.splice(this.files.indexOf(file), 1);
	}

	downloadFile(event: Event, fileName: string) {
		event.stopPropagation();
		this.projectDocumentsService.findOne(this.projectId, fileName).subscribe((response) => {
			//window.open() was opening then closing the tab, this way just simply downloads the file.
			const a = document.createElement('a');
			a.style.display = 'none';
			a.href = response;
			a.download = fileName;
			document.body.appendChild(a);
			a.click();
			window.URL.revokeObjectURL(a.href);
			document.body.removeChild(a);
		});
	}

	stopPropagation(event: Event) {
		event.stopPropagation();
	}

	deleteFile(dto: ProjectDocument) {
		const toDeleteFile = this.files[0];
		this.projectDocumentsService.delete(dto.id!).then(
			(response: void) => {
				if (this.maxFileLength == 1) {
					this.projectDocumentsService.surveyFileRemoveSubject.next(toDeleteFile);
					this.projectDocumentsService.surveyDtoRemoveSubject.next(this.projectDocumentDtos[0]);
					this.uploadedFiles = [];
					this.projectDocumentDtos = [];
				}
				this.snackbar.open('Document Deleted', SnackbarActionEnum.SUCCESS);
				// update projectService Project Subject to remove deleted files
				const currentProject = this.projectsService.projectDataSource.getValue()!;
				currentProject.documents = currentProject?.documents.filter((doc) => doc.id !== dto.id);
				this.projectsService.projectDataSource.next(currentProject);
				//remove deleted file from this.uploadedFiles
				this.uploadedFiles = this.uploadedFiles?.filter((document: ProjectDocument) => document.id !== dto.id);
			},
			(error) => {
				this.snackbar.open('Failed to Delete Document', SnackbarActionEnum.ERROR);
			}
		);
	}

	openDeleteModal(dto: ProjectDocument) {
		const dialogRef = this.dialog.open(ProjectDraftingDeleteComponent, {
			width: '300px',
			disableClose: true
		});
		dialogRef.afterClosed().subscribe((result) => {
			if (result === 'delete') {
				this.deleteFile(dto);
			} else {
				return;
			}
		});
	}
}
