<mat-form-field appearance="outline" class="w-1/2">
	<mat-label>Load Project Template</mat-label>
	<mat-select [(ngModel)]="selectedTemplate" (selectionChange)="templateSelection($event.value)">
		<mat-option *ngFor="let template of templateOptions" [value]="template">{{ template.description }}</mat-option>
		<mat-option *ngIf="templateOptions.length === 0" disabled>No Templates for Client</mat-option>
	</mat-select>
</mat-form-field>
<form [formGroup]="form" class="w-3/5">
	<mat-form-field class="w-full" appearance="outline" hideRequiredMarker="true">
		<mat-label>Name</mat-label>
		<input matInput formControlName="name" />
		<mat-error *ngIf="form.get('name')?.hasError('required')"> Name is required </mat-error>
	</mat-form-field>
	<div>
		<ng-container *ngIf="!existingEstimate">
			<mat-form-field class="w-1/2" appearance="outline" hideRequiredMarker="true">
				<mat-label>Install Date</mat-label>
				<input matInput [matDatepicker]="picker" formControlName="installDate" />
				<mat-error *ngIf="form.get('installDate')?.hasError('required')"> Install Date is required </mat-error>
				<mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
				<mat-datepicker #picker></mat-datepicker>
			</mat-form-field>
		</ng-container>

		<ng-container *ngIf="existingEstimate">
			<mat-form-field class="w-1/2" appearance="outline" hideRequiredMarker="true">
				<mat-label>Estimated Date</mat-label>
				<mat-select [compareWith]="compareDates" formControlName="estimateDate">
					<mat-option *ngFor="let option of dateOptions" [value]="option.date">{{ option.description }}</mat-option>
				</mat-select>
				<mat-error *ngIf="form.get('installDate')?.hasError('required')"> Estimate Date is required </mat-error>
			</mat-form-field>
		</ng-container>
		<mat-checkbox
			class="w-1/2 m-auto"
			[checked]="existingEstimate"
			(change)="updateEstimateCheck($event)"
			color="primary"
			#estimatedInstallCheckbox
			>Estimated Install</mat-checkbox
		>
	</div>

	<mat-form-field class="w-full" appearance="outline" hideRequiredMarker="true">
		<mat-label>Site Address</mat-label>
		<input matInput formControlName="address1" />
		<mat-error *ngIf="form.get('address1')?.hasError('required')"> Site Address is required </mat-error>
	</mat-form-field>

	<mat-form-field class="w-full" appearance="outline">
		<mat-label>Site Address 2</mat-label>
		<input matInput formControlName="address2" />
	</mat-form-field>

	<div class="flex gap-4">
		<mat-form-field class="w-1/3" appearance="outline" hideRequiredMarker="true">
			<mat-label>City</mat-label>
			<input matInput formControlName="city" />
			<mat-error *ngIf="form.get('city')?.hasError('required')"> City is required </mat-error>
		</mat-form-field>

		<mat-form-field class="w-1/6" appearance="outline" hideRequiredMarker="true">
			<mat-label>State</mat-label>
			<input matInput formControlName="state" />
			<mat-error *ngIf="form.get('state')?.hasError('required')"> State is required </mat-error>
		</mat-form-field>

		<mat-form-field class="w-1/6" appearance="outline" hideRequiredMarker="true">
			<mat-label>Zip</mat-label>
			<input matInput formControlName="postal" />
			<mat-error *ngIf="form.get('postal')?.hasError('required')"> Zip is required </mat-error>
		</mat-form-field>
		<mat-form-field class="w-1/5" appearance="outline" hideRequiredMarker="true">
			<mat-label>Country</mat-label>
			<input matInput formControlName="country" />
			<mat-error *ngIf="form.get('country')?.hasError('required')"> Country is required </mat-error>
		</mat-form-field>
	</div>
	<mat-divider></mat-divider>

	<div class="flex flex-col flex-wrap">
		<mat-checkbox class="w-1/2" color="primary" formControlName="stockPermitReq" (change)="stockroomSeismicCheck()"
			>Stockroom Permit</mat-checkbox
		>

		<div class="flex">
			<mat-checkbox class="w-1/2" color="primary" formControlName="seismic" (click)="seismicOrNonSeismic('seismic')">Seismic</mat-checkbox>
			<mat-checkbox class="w-1/2" color="primary" #nonSeismicCheckbox (click)="seismicOrNonSeismic('non-seismic')"
				>Non-Seismic</mat-checkbox
			>
		</div>
		<div *ngIf="form.controls['seismic'].value" class="ml-12 bg-gray-200 p-1 rounded">
			<mat-checkbox class="w-1/2" color="primary" formControlName="seismicCalcs">Seismic Calcs</mat-checkbox>
			<mat-checkbox class="w-1/2" color="primary" formControlName="specialInspection">Special Inspection</mat-checkbox>
		</div>
		<div *ngIf="nonSeismicCheckbox.checked" class="ml-12 bg-gray-200 p-1 rounded">
			<mat-checkbox class="w-1/2" color="primary" formControlName="structuralCalcs">Structural Calcs</mat-checkbox>
		</div>

		<mat-checkbox class="w-1/2" color="primary" formControlName="includeInstall">Include Install</mat-checkbox>
		<div *ngIf="form.controls['includeInstall'].value" class="ml-12 bg-gray-200 p-1 rounded">
			<span class="italic text-gray-600">Union</span><br />
			<mat-checkbox class="w-1/3" color="primary" (click)="unionOrWage('none')" #nonUnionCheckbox>Non-Union</mat-checkbox>
			<mat-checkbox class="w-1/3" color="primary" (click)="unionOrWage('union')" ngDefaultControl formControlName="union"
				>Union</mat-checkbox
			>
			<mat-checkbox class="w-1/3" color="primary" (click)="unionOrWage('wage')" ngDefaultControl formControlName="prevailingWage"
				>Prevailing Wage</mat-checkbox
			>
			<mat-divider></mat-divider>
			<span class="italic text-gray-600">Day/Night Work</span><br />
			<mat-checkbox class="w-1/3" color="primary" ngDefaultControl formControlName="nightWork" (click)="dayOrNight('night')"
				>Night</mat-checkbox
			>
			<mat-checkbox
				class="w-1/3"
				color="primary"
				ngDefaultControl
				(click)="dayOrNight('day')"
				#dayCheckbox
				[checked]="!form.controls['nightWork'].value"
				>Day</mat-checkbox
			>
		</div>
		<mat-checkbox class="w-1/2" color="primary" formControlName="demo">Demolition</mat-checkbox>
		<mat-checkbox class="w-1/2" color="primary" formControlName="demoRemoval">Discard</mat-checkbox>
		<mat-checkbox class="w-1/2" color="primary" formControlName="survey">Survey</mat-checkbox>
		<!--    Survey File Upload (maxFileLength = 1, subType = survey)-->
		<app-project-file-upload
			*ngIf="form.controls['survey'].value"
			[maxFileLength]="1"
			[type]="{id: 0, code: '', name: 'Survey File', active: 0}"
			subType="Survey"
			[uploadedFiles]="project?.documents"
		></app-project-file-upload>
	</div>

	<mat-divider class="mb-2"></mat-divider>

	<p id="install-locations">Install Locations</p>
	<div formArrayName="locations" class="w-1/2">
		<div *ngFor="let location of locations.controls; index as i" class="flex">
			<div>
				<mat-form-field class="w-full" appearance="outline">
					<mat-label>Select an option</mat-label>
					<mat-select [compareWith]="compareFn" [formControlName]="i">
						<mat-option *ngFor="let location of installLocations" [value]="location">{{ location.name }}</mat-option>
					</mat-select>
				</mat-form-field>
			</div>
			<button *ngIf="i > 0" (click)="removeLocation(i)" type="button" class="ml-2"><mat-icon>delete</mat-icon></button>
		</div>
	</div>
	<button mat-fab color="primary" type="button" (click)="addNewLocationInput()">
		<mat-icon>add</mat-icon>
	</button>
</form>
