import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {Quote} from '../../core/models/quote.interface';
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {QuoteService} from '../../core/services/quote.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MatDialog} from '@angular/material/dialog';
import {RequestApprovalDialogComponent} from './dialogs/request-approval-dialog/request-approval-dialog.component';
import {DecimalPipe, Location, TitleCasePipe} from '@angular/common';
import {SnackbarActionEnum} from '../../core/enums/snackbar-action.enum';
import JSPDF from 'jspdf';
import html2canvas from 'html2canvas';
import {QuoteApprovalRequest} from '../../core/models/quote-approval-request.interface';
import {ProjectsService} from '../../core/services/projects.service';
import {Observable, Subject} from 'rxjs';
import {Project} from '../../core/models/project.interface';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {ConfirmationDialogComponent} from '../../shared/components/confirmation-dialog/confirmation-dialog.component';
import {CommaRemovalPipe} from '../../shared/pipes/comma-removal.pipe';
import {AssociatedCostEnum} from '../../core/enums/associated-cost.enum';
import {QuoteLineCategoryEnum} from '../../core/enums/quote-line-category.enum';
import {QuoteLine} from '../../core/models/quote-line.interface';
import {Contact} from '../../core/models/contact.model';
import {TaxTypeEnum} from '../../core/enums/tax-type.enum';
import {QuoteAccessorial} from '../../core/models/quote-accessorial.interface';
import {ClientPreview} from '../../core/models/client-preview.interface';
import {ClientService} from '../../core/services/client.service';
import {PartService} from '../../core/services/part.service';
import {CreateOrderResponseComponent} from './dialogs/create-order-response/create-order-response.component';
import {SalesOrderResponse} from '../../core/models/sales-order-response.model';

@Component({
	selector: 'app-project-quote',
	templateUrl: './project-quote.component.html',
	styleUrls: ['./project-quote.component.scss']
})
export class ProjectQuoteComponent implements OnInit {
	@ViewChild('quotePreview', {static: false}) quotePreview: ElementRef;
	quote: Quote;
	isLoading: Observable<boolean>;
	creatingSalesOrder: boolean = false;
	freightInstallForm: FormGroup = new FormGroup({
		siteAddress1: new FormControl(''),
		siteLocation: new FormControl(''),
		clientName: new FormControl(''),
		siteCity: new FormControl(''),
		siteState: new FormControl(''),
		siteZip: new FormControl('', [Validators.pattern(/^\d{5}(-\d{4})?$|^[A-Z]\d[A-Z] \d[A-Z]\d$/)]),
		freightWeight: new FormControl('', [Validators.required]),
		freightCost: new FormControl('0.00', Validators.required),
		note: new FormControl(''),
		accessorials: this.fb.array([]),
		installCost: new FormControl('0.00', Validators.required),
		taxes: new FormControl('0.00'),
		taxExemptAmount: new FormControl('0.00'),
		taxSubjectAmount: new FormControl('0.00'),
		manuallyAddedPartTotal: new FormControl('0.00'),
		salesperson: new FormControl(''),
		quoter: new FormControl(''),
		accountManager: new FormControl(''),
		po: new FormControl(''),
		lineItemParts: new FormArray([]),
		associatedCosts: new FormArray([])
	});
	itemTotalSubject: Subject<number> = new Subject<number>();
	manuallyAddedPartTotal: number = 0;
	rackSolidItemTotal: number = 0;
	calculated: boolean = false;
	showFullPartList: boolean = false;
	editMode: boolean = true;

	testQuote = {
		quoteTerms: 30,
		quoteText:
			'***QUOTE IS ONLY VALID FOR 15 DAYS*** LEAD TIMES ON ALL ORDERS SHIPPING 5-6 WEEKS FROM DATE OF PO. ORDER MUST SHIP WITHIN 6 ' +
			'WEEKS OF PO PLACEMENT. MMI RESERVES THE RIGHT TO RE-QUOTE ANY PROJECT PAST THE 6 WEEK WINDOW.',
		quoteDate: new Date()
	};

	contactForm: FormGroup;
	mobileItemsGrossWeight: number = 0;
	quoteApprovalRequest: QuoteApprovalRequest = {};
	associatedCostTypes: string[] = Object.values(AssociatedCostEnum);
	associatedCostLines: QuoteLine[] | undefined;
	associatedCostTotal: number;
	createOrderMode: boolean = false;
	submitted: boolean = false;
	project: Project;
	quoteSelected: boolean = false;

	loaded: boolean = false;
	client: ClientPreview;

	viewQuoteList: boolean = false;

	constructor(
		private fb: FormBuilder,
		private quoteService: QuoteService,
		private projectsService: ProjectsService,
		private snackbar: MatSnackBar,
		private dialog: MatDialog,
		private decimalPipe: DecimalPipe,
		private route: ActivatedRoute,
		private router: Router,
		private location: Location,
		private format: CommaRemovalPipe,
		private titleCasePipe: TitleCasePipe,
		private clientService: ClientService,
		private partService: PartService
	) {}

	ngOnInit(): void {
		this.editMode = true;

		this.route.params.subscribe((params: Params) => {
			this.projectsService.project.subscribe({
				next: (project: Project | null) => {
					if (project) {
						this.project = project;
						if (this.route.snapshot.routeConfig?.path === 'new') {
							this.quoteSelected = true;
							this.viewQuoteList = false;
							this.quote = {};
							this.initializeQuote();
						} else {
							if (params['quoteId']) {
								this.quoteService.findOne(params['quoteId']).subscribe({
									next: (quote: Quote) => {
										this.quote = quote;
										this.quoteSelected = true;
										this.viewQuoteList = false;
										this.initializeQuote();
										if (this.route.snapshot.routeConfig?.path === ':quoteId/create-order') {
											this.createOrderMode = true;
											this.editMode = false;
										}
									},
									error: (err) => console.error(err)
								});
							} else {
								if (!this.quote) this.viewQuoteList = true;
							}
						}
					}
				},
				error: (err) => console.error(err)
			});
		});
	}

	watchForManuallyAddedPartChanges() {
		this.freightInstallForm.get('lineItemParts')?.valueChanges.subscribe({
			next: () => {
				const array: QuoteLine[] = this.freightInstallForm.get('lineItemParts')?.value;
				this.manuallyAddedPartTotal =
					array?.reduce((accumulator: number, {price, qty}) => {
						return accumulator + (price ?? 0) * (qty ?? 0);
					}, 0) ?? 0;
				this.itemTotalSubject.next(this.rackSolidItemTotal + this.manuallyAddedPartTotal);
				this.calculateMobileSysGrossWeight(array.filter((line: QuoteLine) => line.category === QuoteLineCategoryEnum.MOBILE_PART));
			},
			error: (error) => {
				console.error(error);
			}
		});
	}

	resetForm(): void {
		this.freightInstallForm.get('freightWeight')?.reset();
		this.freightInstallForm.get('freightCost')?.reset();
		this.freightInstallForm.get('note')?.reset();
		this.freightInstallForm.get('accessorials')?.reset();
		this.freightInstallForm.get('installCost')?.reset();
	}

	goToRackSolid(): void {
		if (this.freightInstallForm.dirty) {
			const dialog = this.dialog.open(ConfirmationDialogComponent, {
				data: {message: 'Unsaved changes will be lost. Are you sure you want to continue?'}
			});
			dialog.afterClosed().subscribe((result: boolean) => {
				if (result) {
					this.router.navigate([`/project/${this.project.id}/quote/${this.quote.id}rack-solid`]);
				}
			});
		} else {
			this.router.navigate([`/project/${this.project.id}/quote/${this.quote.id}/rack-solid`]);
		}
	}

	updatePageUrl(projectId: number): void {
		const url: string = this.router.createUrlTree([`/project/${projectId}/quote`]).toString();
		this.location.go(url);
	}

	initializeQuote(): void {
		this.resetForm();
		this.freightInstallForm.patchValue(this.quote);
		this.watchForManuallyAddedPartChanges();

		if (this.project.clientId) {
			this.clientService.findOneFromErp(this.project.clientId).subscribe({
				next: (client: ClientPreview) => {
					if (client) {
						this.client = client;
					}
				},
				error: (err) => {
					console.error(err);
				}
			});
		}

		this.freightInstallForm.get('freightCost')?.setValue(this.decimalPipe.transform(this.quote.freightCost ?? '0.00', '.2'));
		this.freightInstallForm.get('installCost')?.setValue(this.decimalPipe.transform(this.quote.installCost ?? '0.00', '.2'));

		this.setTaxValues(TaxTypeEnum.TAXES);
		this.setTaxValues(TaxTypeEnum.TAX_SUBJECT);
		this.setTaxValues(TaxTypeEnum.TAX_EXEMPT);

		if (this.quote.approvals?.length) {
			this.freightInstallForm.get('po')?.setValue(this.quote.approvals[0].po);
		}

		if (!this.quote.siteAddress1 && this.project.address1) {
			this.quote.siteAddress1 = this.project.address1;
			this.quote.siteAddress2 = this.project.address2;
			this.quote.siteCity = this.project.city;
			this.quote.siteState = this.project.state;
			this.quote.siteCountry = this.project.country;
			this.quote.siteZip = this.project.postal;
		}

		const accessorials = this.freightInstallForm.get('accessorials') as FormArray;
		accessorials.clear();
		this.quote.accessorials?.forEach((accessorial: QuoteAccessorial) => {
			accessorials.push(new FormControl(accessorial));
		});

		(this.freightInstallForm.get('lineItemParts') as FormArray).clear();

		this.quote.lines?.forEach((part: QuoteLine) => {
			if (
				part.category === QuoteLineCategoryEnum.MOBILE_PART ||
				part.category === QuoteLineCategoryEnum.GENERAL_PART ||
				part.category === QuoteLineCategoryEnum.MISC
			) {
				let group: FormGroup = new FormGroup({
					item: new FormControl(part.item),
					erpItemRef: new FormControl(part.erpItemRef),
					description: new FormControl(part.description),
					category: new FormControl(part.category),
					qty: new FormControl(part.qty),
					price: new FormControl(part.price),
					unitOfMeasure: new FormControl(part.unitOfMeasure)
				});
				(this.freightInstallForm.get('lineItemParts') as FormArray).push(group);
			}
		});

		this.calculateMobileSysGrossWeight(
			this.quote.lines?.filter(
				(line: QuoteLine) =>
					line.category === QuoteLineCategoryEnum.MOBILE_PART ||
					line.category === QuoteLineCategoryEnum.GENERAL_PART ||
					line.category === QuoteLineCategoryEnum.MISC
			) ?? []
		);

		/*this.quote = {
      // TODO price level selector should be on this page
      priceLevelId: 1
    };*/
		this.calculateRackSolidTotal();
		this.calculated = true;

		if (this.project) {
			this.buildAssociatedCostsForm(this.project);
		}
	}

	calculateRackSolidTotal() {
		if (this.quote?.parts) {
			this.rackSolidItemTotal =
				this.quote.parts?.reduce((accumulator: number, {price, qty}) => {
					return accumulator + (price ?? 0) * (qty ?? 0);
				}, 0) ?? 0;
		}
	}

	cancelEdit() {
		this.editMode = false;
		this.initializeQuote();
	}

	setTaxValues(type: string) {
		let taxAmount = this.quote.lines?.find((line: QuoteLine) => line.type === type)?.price;
		if (taxAmount) {
			this.freightInstallForm.get(type)?.setValue(this.decimalPipe.transform(taxAmount, '.2'));
		}
	}

	buildAssociatedCostsForm(project: Project) {
		const projectMap: Map<string, any> = new Map(Object.entries(project));
		const projectKeys: string[] = Object.keys(project);
		this.associatedCostFormArray.clear();
		this.associatedCostLines = this.quote.lines?.filter((line: QuoteLine) => line.category === QuoteLineCategoryEnum.ASSOCIATED_COST);
		this.associatedCostTypes.forEach((type: string) => {
			if (projectKeys.includes(type) && !!projectMap.get(type)) {
				const line = this.associatedCostLines?.find((line) => line.type === type);
				this.associatedCostFormArray.push(
					new FormGroup({
						description: new FormControl(this.getAssociatedCostDescriptionByType(type as AssociatedCostEnum)),
						type: new FormControl(type),
						category: new FormControl(QuoteLineCategoryEnum.ASSOCIATED_COST),
						price: new FormControl(this.decimalPipe.transform(line?.price, '.2') ?? '0.00'),
						qty: new FormControl(1)
					})
				);
			}
		});
		if (this.submitted) {
			this.freightInstallForm.disable();
		}
	}

	getAssociatedCostDescriptionByType(type: AssociatedCostEnum) {
		const key: string = Object.keys(AssociatedCostEnum)[Object.values(AssociatedCostEnum).indexOf(type)];
		if (!key) {
			return '';
		}

		return this.titleCasePipe.transform(key.replace('_', ' '));
	}

	get associatedCostFormArray() {
		return this.freightInstallForm.controls['associatedCosts'] as FormArray;
	}

	calculateMobileSysGrossWeight(mobileItems: QuoteLine[]) {
		this.mobileItemsGrossWeight = parseFloat(
			mobileItems
				.filter((part: QuoteLine) => part.category === QuoteLineCategoryEnum.MOBILE_PART)
				.reduce((sum: number, part: QuoteLine) => sum + (part.weight ?? 0) * (part.qty ?? 0), 0)
				.toFixed(2)
		);
	}

	formValue(formControlName: string): string | number {
		return this.freightInstallForm.controls[formControlName].value;
	}

	save(saveOnly: boolean): void {
		if (this.freightInstallForm.pristine) {
			if (saveOnly) {
				this.displayMessage('upToDate');
				return;
			} else {
				this.displayMessage('quoteSubmitted');
				return;
			}
		} else if (this.freightInstallForm.invalid) {
			this.displayMessage('formErrors');
			return;
		}

		this.updateQuote(saveOnly);
	}

	processLineItems() {
		const formValue = this.freightInstallForm.value;
		formValue.lines = [];

		if (formValue?.associatedCosts?.length) {
			formValue.lines = formValue.lines.concat(formValue.associatedCosts);
			delete formValue.associatedCosts;
		}

		return formValue;
	}

	updateQuote(saveOnly: boolean): void {
		Object.assign(this.quote, this.processLineItems());

		this.quote.freightCost = parseFloat(this.format.transform(this.freightInstallForm.controls['freightCost'].value, true));
		this.quote.installCost = parseFloat(this.format.transform(this.freightInstallForm.controls['installCost'].value, true));

		// Add line item parts back to quote lines
		const array: QuoteLine[] = this.freightInstallForm.get('lineItemParts')?.value;
		array.forEach((part: QuoteLine) => this.quote.lines?.push(part));

		// Last check to verify all prices are numbers
		this.quote.lines?.forEach((line: QuoteLine) => {
			if (line.price) {
				line.price = parseFloat(this.format.transform(line.price.toString(), true));
			}
		});

		// Add taxes as associated cost
		this.addTaxInfo(parseFloat(this.format.transform(this.freightInstallForm.get('taxes')?.value, true)), TaxTypeEnum.TAXES, 'Taxes');
		this.addTaxInfo(
			parseFloat(this.format.transform(this.freightInstallForm.get('taxExemptAmount')?.value, true)),
			TaxTypeEnum.TAX_EXEMPT,
			'Amount Exempt from Taxes'
		);
		this.addTaxInfo(
			parseFloat(this.format.transform(this.freightInstallForm.get('taxSubjectAmount')?.value, true)),
			TaxTypeEnum.TAX_SUBJECT,
			'Amount Subject to Taxes'
		);

		if (!this.quote.projectId) {
			this.quote.projectId = this.project.id;
			if (!this.quote.priceLevelId) {
				this.quote.priceLevelId = 1;
			}
			this.quoteService.create(this.quote).subscribe({
				next: (quoteResponse) => {
					this.quote = quoteResponse;
					this.location.go(`project/${this.project.id}/quote/${this.quote.id}`);
					this.projectsService.updateLocalQuoteByProjectId(quoteResponse.projectId, quoteResponse);
					saveOnly ? this.displayMessage('quoteSaved') : this.displayMessage('quoteSubmitted');
					this.freightInstallForm.markAsPristine();
				},
				error: (err) => {
					this.displayMessage('saveFailed');
					console.error(err);
				}
			});
		} else {
			this.quoteService.update(this.quote).subscribe({
				next: (quoteResponse: Quote) => {
					this.quote = quoteResponse;
					this.projectsService.updateLocalQuoteByProjectId(quoteResponse.projectId, quoteResponse);
					saveOnly ? this.displayMessage('quoteSaved') : this.displayMessage('quoteSubmitted');
					this.freightInstallForm.markAsPristine();
				},
				error: (err) => {
					this.displayMessage('saveFailed');
					console.error(err);
				}
			});
		}
	}

	addTaxInfo(value: number, type: TaxTypeEnum, description: string) {
		this.quote.lines?.push({
			type: type,
			description: description,
			category: QuoteLineCategoryEnum.GENERAL_LEDGER,
			qty: 1,
			price: value,
			weight: 0,
			unitOfMeasure: 'EACH'
		});
	}

	openDialog(): void {
		const dialogRef = this.dialog.open(RequestApprovalDialogComponent, {
			data: {
				form: this.contactForm,
				client: this.client.name
			},
			width: '30vw'
		});

		dialogRef.afterClosed().subscribe((result) => {
			if (!!result) {
				this.quote.contacts = [];
				result.contacts.forEach((contact: Contact) => {
					this.quote.contacts?.push({
						quoteId: this.quote.id!,
						contactId: contact.id!
					});
				});
				const emails: string[] = [];
				result.contacts.forEach((contact: Contact) => emails.push(contact.email!));
				this.quoteApprovalRequest = {
					quote: this.quote,
					message: result.message,
					emails: emails
				};
				this.buildPdf(false);
			}
		});
	}

	requestApproval(): void {
		this.freightInstallForm.invalid ? this.displayMessage('formErrors') : this.openDialog();
	}

	get grandTotal(): number {
		return this.subTotal + parseFloat(this.format.transform(this.freightInstallForm.get('taxes')?.value, true));
	}

	get subTotal(): number {
		return (
			parseFloat(this.format.transform(this.freightInstallForm.get('freightCost')?.value, true)) +
			parseFloat(this.format.transform(this.freightInstallForm.get('installCost')?.value, true)) +
			this.manuallyAddedPartTotal +
			this.associatedCostTotal +
			this.rackSolidItemTotal
		);
	}

	displayMessage(option: string) {
		switch (option) {
			case 'quoteSaved':
				this.snackbar.open('Successfully saved quote', SnackbarActionEnum.SUCCESS);
				break;
			case 'upToDate':
				this.snackbar.open('Quote is up to date', SnackbarActionEnum.ALERT);
				break;
			case 'quoteSubmitted':
				this.snackbar.open('Quote has been submitted', SnackbarActionEnum.SUCCESS);
				break;
			case 'saveFailed':
				this.snackbar.open('Failed to save quote', SnackbarActionEnum.ERROR);
				break;
			case 'formErrors':
				this.snackbar.open('Please fix form errors before continuing', SnackbarActionEnum.ERROR);
				break;
		}
	}

	public async buildPdf(saveOnly: boolean) {
		const doc = new JSPDF('p', 'px', 'letter');

		html2canvas(this.quotePreview.nativeElement).then((canvas) => {
			const imgWidth = 440;
			const imgHeight = 480;
			const contentDataUrl = canvas.toDataURL('image/png');
			doc.addImage(contentDataUrl, 'png', 0, 0, imgWidth, imgHeight);

			const pdfData = doc.output('blob');
			const timestamp = new Date().toISOString();

			if (saveOnly) {
				// If they want to save a quote preview without submitting quote
				doc.save('MMI_dev_quote_preview' + this.quote.id + timestamp);
			} else {
				const blob = new Blob([pdfData], {type: 'application/pdf'});

				this.quoteApprovalRequest.quote = this.quote;

				let fd: FormData = new FormData();
				fd.append('file', blob);
				fd.append('body', JSON.stringify(this.quoteApprovalRequest));

				this.buildApprovalRequest(fd);
			}
		});
	}

	buildApprovalRequest(fd: FormData) {
		if (this.quote.id) {
			this.quoteService.requestApproval(fd, this.quote.id).subscribe((next) => {
				console.error(next);
			});
		}
	}

	newProject(): void {
		this.router.navigate(['/create-project']);
	}

	createOrder() {
		if (this.quote.id) {
			this.creatingSalesOrder = true;
			const projectId: number = this.project.id;
			this.quoteService.approveQuote(this.quote.id, projectId).subscribe({
				next: (response: SalesOrderResponse) => {
					this.dialog.open(CreateOrderResponseComponent, {
						panelClass: 'create-order-response',
						data: {
							isSuccessful: true,
							salesOrder: response
						}
					});
					this.quote.salesOrder = response.id;
					this.creatingSalesOrder = false;
				},
				error: (response: any) => {
					this.dialog.open(CreateOrderResponseComponent, {
						panelClass: 'create-order-response',
						data: {
							isSuccessful: false,
							error: response.error
						}
					});
					this.creatingSalesOrder = false;
				}
			});
		} else {
			this.snackbar.open('Please Create a Quote first.', SnackbarActionEnum.ERROR);
			this.creatingSalesOrder = false;
		}
	}

	salesOrderCreated(): boolean {
		return !!this.quote.salesOrder || this.creatingSalesOrder;
	}
}
