import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {Shipment} from '../models/shipment.model';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {ProjectFreight} from '../models/project-freight.interface';

@Injectable({
	providedIn: 'root'
})
export class ShipmentService {
	baseUrl: string = `${environment.url}/shipments`;
	constructor(private http: HttpClient, private fb: FormBuilder) {}

	create(shipment: Shipment): Observable<Shipment> {
		return this.http.post<Shipment>(this.baseUrl, shipment);
	}

	findAll(): Observable<Shipment[]> {
		return this.http.get<Shipment[]>(this.baseUrl);
	}

	findOne(id: number): Observable<Shipment> {
		return this.http.get<Shipment>(`${this.baseUrl}/${id}`);
	}

	update(shipmentWithId: Shipment): Observable<Shipment> {
		return this.http.put<Shipment>(`${this.baseUrl}/${shipmentWithId.id}`, shipmentWithId);
	}

	patch(shipmentWithId: Shipment): Observable<Shipment> {
		return this.http.patch<Shipment>(`${this.baseUrl}/${shipmentWithId.id}`, shipmentWithId);
	}

	remove(id: number): Observable<void> {
		return this.http.delete<void>(`${this.baseUrl}/${id}`);
	}

	getProjectShipments(id: number): Observable<ProjectFreight> {
		return this.http.get<ProjectFreight>(`${this.baseUrl}/project/${id}`);
	}

	validateShipment(shipment: Shipment): boolean {
		const validationForm = new FormGroup({
			equipmentType: new FormControl(''),
			originSiteCode: new FormControl(''),
			originName: new FormControl('', [Validators.required]),
			originAddress1: new FormControl('', [Validators.required]),
			originAddress2: new FormControl(''),
			originCity: new FormControl('', [Validators.required]),
			originState: new FormControl('', [Validators.required]),
			originPostal: new FormControl('', [Validators.required]),
			originCountry: new FormControl('', [Validators.required]),
			originContactName: new FormControl('', [Validators.required]),
			originContactEmail: new FormControl('', [Validators.email]),
			originContactPhone: new FormControl('', [Validators.required, Validators.pattern('^(\\+\\d{1,3}[- ]?)?\\d{10}$')]),
			originContactPhoneExt: new FormControl(''),
			destinationSiteCode: new FormControl(''),
			destinationName: new FormControl('', [Validators.required]),
			destinationAddress1: new FormControl('', [Validators.required]),
			destinationAddress2: new FormControl(''),
			destinationCity: new FormControl('', [Validators.required]),
			destinationState: new FormControl('', [Validators.required]),
			destinationPostal: new FormControl('', [Validators.required]),
			destinationCountry: new FormControl('', [Validators.required]),
			destinationContactName: new FormControl('', [Validators.required]),
			destinationContactEmail: new FormControl('', [Validators.email]),
			destinationContactPhone: new FormControl('', [Validators.required, Validators.pattern('^(\\+\\d{1,3}[- ]?)?\\d{10}$')]),
			destinationContactPhoneExt: new FormControl(''),
			deliveryDateTime: new FormControl(new Date(), [Validators.required]),
			shipmentAccessorials: this.fb.array([]),
			shipmentHandlingUnits: this.fb.array([]),
			totalLinearDimension: new FormControl(Number(undefined), [Validators.required, Validators.min(1)]),
			dUnit: new FormControl('', [Validators.required]),
			totalWeight: new FormControl(Number(undefined), [Validators.required, Validators.min(1)]),
			wUnit: new FormControl('', [Validators.required]),
			shipmentReferences: this.fb.array([]),
			instructions: new FormControl('')
		});

		validationForm.patchValue(shipment);

		return validationForm.valid;
	}
}
