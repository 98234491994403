import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Contact} from '../../../../core/models/contact.model';
import {map, Observable, startWith, Subject} from 'rxjs';
import {FormControl} from '@angular/forms';
import {ContactData} from '../contact-data.interface';
import {SortPipe} from '../../../pipes/sort.pipe';

@Component({
	selector: 'app-contacts-input',
	templateUrl: './contacts-input.component.html',
	styleUrls: ['./contacts-input.component.scss']
})
export class ContactsInputComponent implements OnInit {
	@Input() contactsOptions: Contact[] = [];
	@Input() placeholder: string = 'Select Contacts';
	@Input() key: string = '';

	@Input() contactDeletedSubject: Subject<number>;
	@Input() contactCreatedSubject: Subject<Contact>;
	@Output() result = new EventEmitter<{key: string; data: Contact[]}>();

	selectControl = new FormControl();
	filteredData: Observable<ContactData[]>;
	selectedContacts: ContactData[] = [];
	rawContactData: ContactData[] = [];

	filterString = '';

	constructor(private sortPipe: SortPipe) {
		this.filterFunction();
	}

	filterFunction(): void {
		this.filteredData = this.selectControl.valueChanges.pipe(
			startWith(''),
			map((value) => (typeof value === 'string' ? value : this.filterString)),
			map((filter) => this.filter(filter))
		);
	}

	ngOnInit(): void {
		this.contactsOptions = this.sortPipe.transform(this.contactsOptions, 'asc', 'name');
		this.contactsOptions.forEach((contact: Contact) => this.rawContactData.push({contact, selected: false}));

		this.contactDeletedSubject.subscribe((event: number) => {
			let index = this.rawContactData.findIndex((data: ContactData) => data.contact.id === event);
			this.rawContactData[index].selected = false;

			index = this.selectedContacts.findIndex((data: ContactData) => data.contact.id === event);
			this.selectedContacts.splice(index);
		});

		this.contactCreatedSubject.subscribe((contact: Contact) => {
			this.rawContactData.push({contact, selected: true});
			this.selectedContacts.push({contact, selected: true});
			this.rawContactData = this.sortPipe.transform(this.rawContactData, 'asc', 'contact', 'name');
			this.filterFunction();
		});
	}

	ngOnDestroy() {
		this.contactDeletedSubject.unsubscribe();
		this.contactCreatedSubject.unsubscribe();
	}

	filter(filter: string): ContactData[] {
		this.filterString = filter;
		if (filter.length > 0) {
			return this.rawContactData.filter((option: ContactData) => {
				if (option.contact.name) {
					return option.contact.name.toLowerCase().indexOf(filter.toLowerCase()) >= 0;
				} else {
					return;
				}
			});
		} else {
			return this.rawContactData.slice();
		}
	}

	optionClicked(event: Event, contactData: ContactData): void {
		event.stopPropagation();
		this.toggleSelection(contactData);
	}

	toggleSelection(contactData: ContactData): void {
		contactData.selected = !contactData.selected;

		if (contactData.selected) {
			this.selectedContacts.push(contactData);
		} else {
			const i = this.selectedContacts.findIndex((value: ContactData) => value.contact === contactData.contact);
			this.selectedContacts.splice(i, 1);
		}

		this.emitAdjustedData();
	}

	emitAdjustedData() {
		let results: Contact[] = [];
		this.selectedContacts.forEach((contactData: ContactData) => {
			results.push(contactData.contact);
		});
		this.result.emit({key: this.key, data: results});
	}

	displayFn(): string {
		return '';
	}
}
