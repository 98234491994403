import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute} from '@angular/router';
import {ProjectsService} from '../../core/services/projects.service';
import {Project} from '../../core/models/project.interface';
import {ProjectDocument} from '../../core/models/project-document.interface';
import {Subscription} from 'rxjs';
import {MatSnackBar} from '@angular/material/snack-bar';
import {ProjectDocumentService} from '../../core/services/project-document.service';
import {SnackbarActionEnum} from '../../core/enums/snackbar-action.enum';
import {TemplateCreateDialogComponent} from '../../shared/components/template-create-dialog/template-create-dialog.component';
import {Template, TemplateUpdate} from '../../core/models/template.interface';
import {TemplatesService} from '../../core/services/templates.service';
import {MatDialog} from '@angular/material/dialog';
import {ClientService} from '../../core/services/client.service';
import {TemplateTypeEnum} from '../../core/enums/template-type.enum';
import {ProjectTypeEnum} from '../../core/enums/project-type.enum';
@Component({
	selector: 'app-project-overview',
	templateUrl: './project-overview.component.html',
	styleUrls: ['./project-overview.component.scss']
})
export class ProjectOverviewComponent implements OnInit, OnDestroy {
	form: FormGroup;
	project: Project | null;
	projectId: number;
	existingEstimate: boolean = false;
	surveyFile?: File;
	surveyFileDto: ProjectDocument[] = [];
	surveyFileSubscription: Subscription;
	surveyFileDtoSubscription: Subscription;
	surveyFileRemoveSubscription: Subscription;
	surveyDtoRemoveSubscription: Subscription;
	templateOptions: Template[] = [];
	selectedTemplate: Template;

	constructor(
		private fb: FormBuilder,
		private route: ActivatedRoute,
		private projectsService: ProjectsService,
		private projectDocumentService: ProjectDocumentService,
		private snackbar: MatSnackBar,
		private clientService: ClientService,
		private templatesService: TemplatesService,
		private dialog: MatDialog
	) {}

	ngOnInit() {
		this.form = this.fb.group(
			{
				name: ['', Validators.required],
				installDate: [''],
				estimateDate: [''],
				address1: ['', Validators.required],
				address2: [''],
				city: ['', Validators.required],
				state: ['', Validators.required],
				postal: ['', Validators.required],
				country: ['', [Validators.required, Validators.maxLength(10)]],
				union: [false],
				stockPermitReq: [false],
				prevailingWage: [false],
				seismic: [false],
				includeInstall: [false],
				structuralCalcs: [false],
				seismicCalcs: [false],
				specialInspection: [false],
				nightWork: [false],
				survey: [false],
				demo: [false],
				demoRemoval: [false],
				locations: this.fb.array([])
			},
			{validator: this.installOrEstDateReq}
		);
		this.projectsService.project.subscribe((project) => {
			if (project) {
				//Check if the estimateDate field has a value, if it does set existingEstimate to true
				if (project.estimateDate) {
					this.existingEstimate = true;
				}
				this.projectId = project.id;
				this.project = project;
				const locationsToReplace = project.locations?.map((each) => each.type);
				this.form.patchValue(project);
				this.form.setControl('locations', this.fb.array(locationsToReplace));

				//Based on the projects clientId, load templates.
				this.clientService.findAllTemplatesForClient(project.clientId, 'project').subscribe(
					(response) => {
						this.templateOptions = response;
					},
					(error) => {
						this.snackbar.open('Failed to Load Project Templates', SnackbarActionEnum.ERROR);
					}
				);
			}
		});
		this.surveyFileDtoSubscription = this.projectDocumentService.surveyDtoSubject.subscribe((response) => {
			this.surveyFileDto.push(response);
		});
		this.surveyFileSubscription = this.projectDocumentService.surveyFileSubject.subscribe((response) => {
			this.surveyFile = response;
		});
		this.surveyFileRemoveSubscription = this.projectDocumentService.surveyFileRemoveSubject.subscribe((file) => {
			this.handleSurveyFileRemoveEmit(file);
		});
		this.surveyDtoRemoveSubscription = this.projectDocumentService.surveyDtoRemoveSubject.subscribe((dto) => {
			this.handleSurveyDtoRemoveEmit(dto);
		});
	}

	installOrEstDateReq(form: FormGroup) {
		if (form.value.installDate === '' && form.value.estimateDate === '') {
			return {installEstimateDateReq: true};
		}
		return null;
	}
	handleSurveyDtoRemoveEmit(document: ProjectDocument) {
		this.surveyFileDto = this.surveyFileDto.filter((doc) => document.documentName !== doc.documentName);
	}

	handleSurveyFileRemoveEmit(file: any) {
		let index = this.project?.documents.findIndex((doc) => doc.subType === 'Survey');
		if (typeof index !== 'undefined' && index !== null) {
			this.project?.documents.splice(index, 1);
		}
		this.surveyFileDto = [];
		this.surveyFile = undefined;
	}

	updateProject() {
		if (this.surveyFileDto && this.surveyFile) {
			this.surveyFileDto[0].projectId = this.projectId;
			const surveyFileForm = new FormData();
			surveyFileForm.append('files', this.surveyFile, this.surveyFile.name);
			surveyFileForm.append('body', JSON.stringify(this.surveyFileDto));
			this.projectDocumentService.create(surveyFileForm, this.projectId).subscribe(
				(response) => {
					//no success snackbar here bc the projects service opens it up upon successful call
					//just want to catch the error for the file upload
					this.projectDocumentService.surveyFileUploadedSubject.next(true);
					this.project?.documents.push(response[0]);
					const mergedProject = {...this.project, ...this.form.getRawValue()};

					this.projectsService.update(mergedProject);
				},
				(error) => {
					this.snackbar.open('Something went wrong uploading the Survey File!', SnackbarActionEnum.ERROR);
				}
			);
		} else {
			const mergedProject = {...this.project, ...this.form.getRawValue()};

			this.projectsService.update(mergedProject);
		}
	}

	ngOnDestroy() {
		this.surveyFileDtoSubscription.unsubscribe();
		this.surveyFileSubscription.unsubscribe();
	}

	saveTemplate() {
		const dialog = this.dialog.open(TemplateCreateDialogComponent);
		dialog.componentInstance.templateOptions = this.templateOptions;

		dialog.afterClosed().subscribe((result) => {
			if (result?.templateName) {
				//We're creating a new template and project
				let project: Project = {...this.project, ...this.form.getRawValue()};
				project.type = ProjectTypeEnum['TEMPLATE'];

				const projectForm = new FormData();
				projectForm.append('body', JSON.stringify(project));

				this.projectsService.create(projectForm).subscribe(
					(response: Project) => {
						// Create template record to save
						const template: TemplateUpdate = {
							description: result?.templateName,
							projectId: response.id,
							clientId: this.project?.clientId,
							type: TemplateTypeEnum['PROJECT']
						};

						this.templatesService.create(template).subscribe(
							(newTemplate: Template) => {
								this.snackbar.open('Template Saved', SnackbarActionEnum.SUCCESS);
								this.templateOptions.push(newTemplate);
								this.selectedTemplate = newTemplate;
							},
							() => {
								this.snackbar.open('Failed to Save Template', SnackbarActionEnum.ERROR);
							}
						);
					},
					(error) => {
						this.snackbar.open('Failed to Save Project for Template', SnackbarActionEnum.ERROR);
					}
				);
				return;
			}
			if (result?.selectedTemplate) {
				// Updating an existing project template
				const project = this.form.getRawValue();
				project.id = result.selectedTemplate.projectId;
				project.type = ProjectTypeEnum['TEMPLATE'];
				this.projectsService.update(project, 'Project Template Updated');
				this.selectedTemplate = result.selectedTemplate;
			}
		});
	}
}
