<div [formGroup]="freightInstallForm">
	<mat-accordion>
		<mat-expansion-panel expanded="false" class="w-full">
			<mat-expansion-panel-header>
				<mat-panel-title> Freight Details </mat-panel-title>
				<mat-label class="mr-4" *ngIf="!!this.freightCostControl.value">{{ this.freightCost }}</mat-label>
			</mat-expansion-panel-header>

			<div class="flex flex-row space-x-4">
				<mat-form-field appearance="outline" class="w-5/12">
					<mat-label> Weight</mat-label>
					<input matInput type="number" formControlName="freightWeight" />
					<span matSuffix class="mr-3 text-gray-500">lbs</span>
					<mat-error *ngIf="freightInstallForm.controls['freightWeight'].touched && freightInstallForm.controls['freightWeight'].invalid">
						Weight is required
					</mat-error>
				</mat-form-field>

				<mat-form-field appearance="outline" class="w-5/12">
					<span matPrefix class="ml-3 text-gray-500">$</span>
					<mat-label> Estimated Cost</mat-label>
					<input matInput type="text" formControlName="freightCost" IsDecimal (blur)="checkForTrailingZero()" />
					<mat-error *ngIf="freightInstallForm.controls['freightCost'].touched && freightInstallForm.controls['freightCost'].invalid">
						Estimated Cost is required
					</mat-error>
				</mat-form-field>
				<form [formGroup]="noFreightCostForm" class="mt-2 ml-2 w-1/2">
					<mat-label *ngIf="!submitted"> 3rd Party Freight</mat-label>
					<mat-label *ngIf="submitted" class="text-gray-500"> 3rd Party Freight</mat-label>
					<mat-checkbox formControlName="noFreightCost"></mat-checkbox>
				</form>
			</div>

			<ng-container *ngIf="quote.accessorials">
				<app-accessorials
					[selectedAccessorialArray]="quote.accessorials"
					[submitted]="submitted"
					[isNestedForm]="false"
					componentFormArrayName="accessorials"
					[quoteId]="quote.id!"
				></app-accessorials>
			</ng-container>
		</mat-expansion-panel>
	</mat-accordion>
</div>
