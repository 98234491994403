import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {QuoteService} from '../../core/services/quote.service';
import {Subscription} from 'rxjs';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {QuoteHeader} from '../../core/models/quote-header.interface';
import {DatePipe} from '@angular/common';
import {ProjectsService} from '../../core/services/projects.service';
import {MatDialog} from '@angular/material/dialog';
import {ProjectSelectDialogComponent} from '../project-select/project-select-dialog.component';

@Component({
	selector: 'app-quote-header-list',
	templateUrl: './quote-header-list.component.html',
	styleUrls: ['./quote-header-list.component.scss']
})
export class QuoteHeaderListComponent implements OnInit {
	@ViewChild(MatSort) set matSort(ms: MatSort) {
		this.sort = ms;
		this.dataSource.sort = this.sort;
	}
	sort: MatSort;
	@Input() projectId: number;
	quotesSubscription: Subscription;
	dataSource: MatTableDataSource<QuoteHeader> = new MatTableDataSource<QuoteHeader>();
	columnsToDisplay: string[] = ['quoteNumber', 'quoteDate', 'project.clientId', 'create-order'];
	isLoadingQuotes: boolean = true;

	constructor(
		private quotesService: QuoteService,
		private projectService: ProjectsService,
		private datePipe: DatePipe,
		private dialog: MatDialog,
		private router: Router
	) {}

	ngOnInit() {
		if (this.projectId) {
			this.quotesSubscription = this.projectService.findQuotesAssociatedWithProject(this.projectId).subscribe({
				next: (response: QuoteHeader[]) => this.buildDataSource(response),
				error: (err: any) => console.error(err)
			});
		} else {
			this.quotesSubscription = this.quotesService.findAllQuotes().subscribe({
				next: (response: QuoteHeader[]) => this.buildDataSource(response),
				error: (err: any) => console.error(err)
			});
		}
	}

	buildDataSource(response: QuoteHeader[]): void {
		this.dataSource = new MatTableDataSource<QuoteHeader>(response);
		this.dataSource.sortingDataAccessor = (item: QuoteHeader, property: string) => {
			switch (property) {
				case 'project.clientId':
					return item.project.clientId;
				case 'quoteDate':
					return new Date(item.quoteDate);
				default:
					return item[property];
			}
		};
		this.isLoadingQuotes = false;
	}

	applyFilter(event: Event) {
		const filterValue = (event.target as HTMLInputElement).value;
		this.dataSource.filter = filterValue.trim().toLowerCase();
		this.dataSource.filterPredicate = (data: QuoteHeader, filter: string) => {
			const clientName = data.project.clientId.toLowerCase();
			//string and numeric so users can type out number date or month name in search bar
			const quoteDateString = this.datePipe.transform(data.quoteDate, 'longDate')?.toLowerCase();
			const quoteDateNumeric = this.datePipe.transform(data.quoteDate, 'MM/dd/y')?.toString();
			return (
				clientName.includes(filter) ||
				(quoteDateString && quoteDateString.includes(filter)) ||
				(quoteDateNumeric && quoteDateNumeric.includes(filter)) ||
				data.quoteNumber.toString().includes(filter) ||
				false
			);
		};
	}

	openProjectSelectDialog() {
		if (!this.projectId) {
			this.dialog.open(ProjectSelectDialogComponent);
			return;
		}
		this.router.navigate(['project', this.projectId, 'quote', 'new']);
	}

	goToOrder(quote: QuoteHeader) {
		this.router.navigate(['project', this.projectId ?? quote['projectId'], 'quote', quote.id, 'create-order']);
	}
}
