<div class="my-4">
	<mat-accordion>
		<mat-expansion-panel>
			<mat-expansion-panel-header>
				<mat-panel-title> External Team Members </mat-panel-title>
			</mat-expansion-panel-header>
			<div class="flex justify-center">
				<mat-form-field appearance="outline" class="w-3/4">
					<input placeholder="Search for people and organizations" [(ngModel)]="searchTerm" matInput (keyup)="filterUsers()" />
				</mat-form-field>
			</div>
			<div class="flex">
				<app-user-card
					(unchecked)="userUnchecked($event)"
					(checked)="userChecked($event)"
					*ngFor="let user of filteredList"
					[user]="user"
				></app-user-card>
			</div>
		</mat-expansion-panel>
	</mat-accordion>
</div>
