import {Component, EventEmitter, Input, Output} from '@angular/core';
import {FormArray, FormControl, FormGroup} from '@angular/forms';
import {Subject} from 'rxjs';
import {QuoteLine} from '../../../core/models/quote-line.interface';

@Component({
	selector: 'app-manual-part-entry',
	templateUrl: './manual-part-entry.component.html',
	styleUrls: ['./manual-part-entry.component.scss']
})
export class ManualPartEntryComponent {
	@Input() partTypeCategory: string = '';
	@Input() startExpanded: boolean = false;
	@Input() parentForm: FormGroup;
	@Input() submitted: boolean;
	@Output() showPartListEvent = new EventEmitter<boolean>();

	partDeletedSubject: Subject<string> = new Subject<string>();
	filterMobilePartsSubject: Subject<boolean> = new Subject<boolean>();
	filterMobileParts: boolean = false;
	partType: string = '';
	loaded: boolean = false;

	get lineItemPartsFormArray() {
		return this.parentForm.controls['lineItemParts'] as FormArray;
	}

	selectChange(event: any): void {
		this.parentForm.markAsDirty();
		event.data.filter((part: QuoteLine) => {
			const array: QuoteLine[] = this.lineItemPartsFormArray.value;
			const found = array.find((selectedPart: QuoteLine) => selectedPart.erpItemRef === part.erpItemRef);
			if (!found) {
				let lineItemPart: FormGroup = new FormGroup({
					item: new FormControl(part.item),
					erpItemRef: new FormControl(part.erpItemRef),
					category: new FormControl(part.category),
					description: new FormControl(part.description),
					price: new FormControl(part.price),
					qty: new FormControl(part.qty ?? 1),
					unitOfMeasure: new FormControl(part.unitOfMeasure),
					weight: new FormControl(part.weight)
				});
				this.lineItemPartsFormArray.push(lineItemPart);
			}
		});
	}

	delete(index: number): void {
		this.parentForm.markAsDirty();
		this.partDeletedSubject.next(this.lineItemPartsFormArray.at(index).get('description')?.value ?? '');
		this.lineItemPartsFormArray.removeAt(index);
	}

	flipMobilePartsFilter() {
		this.filterMobileParts = !this.filterMobileParts;
		this.filterMobilePartsSubject.next(this.filterMobileParts);
	}
}
