import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AbstractControl, FormArray, FormBuilder, FormGroup, FormGroupDirective} from '@angular/forms';
import {ConfigType} from '../../../core/models/type-options.model';
import {Part} from '../../../core/models/part.interface';
import {ConfigTypeEnum} from '../../../core/enums/config-type.enum';
import {QuoteConfigShelf} from '../../../core/models/quote-config-shelf.interface';

@Component({
	selector: 'app-quote-config',
	templateUrl: './quote-config.component.html',
	styleUrls: ['./quote-config.component.scss']
})
export class QuoteConfigComponent implements OnInit {
	@Input() index: number = 0;
	@Input() formArrayName: string = '';
	@Input() typeOptions: ConfigType[] = [];
	@Input() partArray: Part[] = [];

	@Output() copyConfigEvent = new EventEmitter<number>();
	@Output() deleteConfigEvent = new EventEmitter<number>();
	@Output() typePlaceHolderEvent = new EventEmitter<ConfigTypeEnum>();

	configForm: FormGroup = new FormGroup({});

	constructor(private rootFormGroup: FormGroupDirective, private fb: FormBuilder) {}

	ngOnInit(): void {
		this.initializeForm();

		if (!this.quoteConfigShelves.length) {
			this.addShelfConfig();
		}
	}

	initializeForm(): void {
		this.configForm = (this.rootFormGroup.control.controls[this.formArrayName] as FormArray).controls[this.index] as FormGroup;
	}

	get configFormControl() {
		return ((this.rootFormGroup.control.controls['configs'] as FormArray).controls[this.index] as FormGroup).controls;
	}

	get dimensions(): Part[] {
		return this.partArray.filter((part) => part.type === this.configFormControl['type'].value);
	}

	addShelfConfig(): void {
		const quoteConfigShelf: FormGroup = this.fb.group({
			partId: [null],
			starters: [{value: null, disabled: true}],
			adders: [{value: null, disabled: true}]
		});

		this.quoteConfigShelves.push(quoteConfigShelf);

		this.quoteConfigShelves.valueChanges.subscribe((value) => {
			for (let i = 0; i < value.length; i++) {
				if (value[i]['partId'] !== null) {
					(this.quoteConfigShelves.at(i) as FormGroup).controls['starters'].enable({emitEvent: false});
					(this.quoteConfigShelves.at(i) as FormGroup).controls['adders'].enable({emitEvent: false});
				}
			}
		});
	}

	get quoteConfigShelves(): FormArray {
		return ((this.rootFormGroup.control.controls[this.formArrayName] as FormArray).controls[this.index] as FormGroup).controls[
			'quoteConfigShelves'
		] as FormArray;
	}

	deleteShelfConfig(index: number): void {
		this.quoteConfigShelves.removeAt(index);
		this.rootFormGroup.control.markAsDirty();
	}

	deleteConfig(): void {
		this.deleteConfigEvent.emit(this.index);
		this.rootFormGroup.control.markAsDirty();
	}

	copyConfig(): void {
		this.copyConfigEvent.emit(this.index);
		this.rootFormGroup.control.markAsDirty();
	}

	setPlaceholder(newTypeSelection: ConfigTypeEnum): void {
		this.typePlaceHolderEvent.emit(newTypeSelection);
		this.trickleDownTypeSelection(newTypeSelection);
	}

	trickleDownTypeSelection(newTypeSelection: ConfigTypeEnum): void {
		this.quoteConfigShelves.controls.forEach((control: AbstractControl<any>) => {
			if (control.value.partId === '') {
				return;
			}

			const selectedPart: QuoteConfigShelf = control.value;
			const previousPart: Part | undefined = this.partArray.find((part: Part) => part.id === selectedPart.partId);

			if (previousPart) {
				const newPart: Part | undefined = this.partArray.find(
					(part: Part) => part.width === previousPart.width && part.depth === previousPart.depth && part.type === newTypeSelection
				);
				if (newPart) {
					control.setValue({
						partId: newPart.id,
						starters: selectedPart.starters,
						adders: selectedPart.adders
					});
				} else {
					console.error({
						message: `Failed to lookup part using previous part id: ${previousPart.id} and newly selected type: ${newTypeSelection}`
					});
				}
			} else {
				console.error({
					message: `Failed to lookup part based on the following part id: ${selectedPart.partId}`
				});
			}
		});
	}
}
