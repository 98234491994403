import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
	name: 'phone'
})
export class PhonePipe implements PipeTransform {
	transform(phoneNumber: string): string {
		if (!phoneNumber) {
			return '';
		}

		// Remove any non-digit characters from the phone number string
		const cleaned = phoneNumber.replace(/\D/g, '');

		// Format the cleaned phone number string with hyphens
		return `${cleaned.slice(0, 3)}-${cleaned.slice(3, 6)}-${cleaned.slice(6)}`;
	}
}
