import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {FormGroup, FormGroupDirective} from '@angular/forms';
import {ClientSite} from '../../../core/models/client-site.model';
import {MatSelectChange} from '@angular/material/select';
import {ClientSiteService} from '../../../core/services/client-site.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {ClientSitesComponentContextEnum} from '../../../core/enums/client-sites-component.enum';
import {Subscription} from 'rxjs';
import {ProjectsService} from '../../../core/services/projects.service';
import {Project} from '../../../core/models/project.interface';

@Component({
	selector: 'app-client-site',
	templateUrl: './client-site.component.html',
	styleUrls: ['./client-site.component.scss']
})
export class ClientSiteComponent implements OnInit, OnChanges {
	@Input() componentFormGroupName: string = '';
	@Input() context: ClientSitesComponentContextEnum = ClientSitesComponentContextEnum.DEFAULT;
	@Input() contextId: string = '';
	@Input() siteCode: string = 'siteCode';
	@Input() name: string = 'name';
	@Input() address1: string = 'address1';
	@Input() address2: string = 'address2';
	@Input() city: string = 'city';
	@Input() state: string = 'state';
	@Input() postal: string = 'postal';
	@Input() country: string = 'country';
	@Input() allowCreate: boolean = true;
	@Input() allowModify: boolean = true;
	@Input() readOnly: boolean = false;
	@Input() disableSelection: boolean = false;
	@Input() projectId: number | undefined;

	busy: boolean = false;

	siteForm: FormGroup | undefined; // = new FormGroup({});

	clientSites: ClientSite[] = [];
	selectedSite: ClientSite = {};

	siteSubscription: Subscription;

	constructor(
		private rootFormGroup: FormGroupDirective,
		private clientSiteService: ClientSiteService,
		private projectsService: ProjectsService,
		private _snackBar: MatSnackBar
	) {}

	ngOnInit(): void {
		this.siteForm = this.rootFormGroup.form as FormGroup;
		if (this.componentFormGroupName > '') {
			this.siteForm = this.siteForm.get(this.componentFormGroupName) as FormGroup;
		}
		this.getSites();
	}

	ngOnChanges(changes: SimpleChanges) {
		if (changes['contextId'] && this.contextId) {
			this.getSites();
		}
	}

	getSites() {
		switch (this.context) {
			case ClientSitesComponentContextEnum.INTERNAL:
				this.busy = true;
				if (this.siteSubscription) {
					this.siteSubscription.unsubscribe();
				}
				this.siteSubscription = this.clientSiteService.getInternal().subscribe({
					next: (sites) => {
						this.clientSites = sites;
						this.setSelectedSite();
						this.updateSite(this.selectedSite);
						this.busy = false;
					}
				});
				break;
			case ClientSitesComponentContextEnum.CLIENT:
				if (this.projectId) {
					this.projectsService.findOne(this.projectId).subscribe({
						next: (project: Project) => {
							const site = new ClientSite();
							site.id = project.siteId;
							site.name = project.clientId;
							site.address1 = project.address1;
							site.address2 = project.address2;
							site.city = project.city;
							site.state = project.state;
							site.postal = project.postal;
							site.country = project.country;
							this.updateSite(site);
						}
					});
				}
				break;
			default:
				this.clientSites = [];
		}
	}

	clearSite() {
		this.siteForm?.patchValue({
			[this.siteCode]: 0,
			[this.name]: '',
			[this.address1]: '',
			[this.address2]: '',
			[this.city]: '',
			[this.state]: '',
			[this.postal]: '',
			[this.country]: ''
		});
	}

	updateSite(site: ClientSite) {
		this.siteForm?.patchValue({
			[this.siteCode]: site.id ? site.id : 0,
			[this.name]: site.name ? site.name : '',
			[this.address1]: site.address1 ? site.address1 : '',
			[this.address2]: site.address2 ? site.address2 : '',
			[this.city]: site.city ? site.city : '',
			[this.state]: site.state ? site.state : '',
			[this.postal]: site.postal ? site.postal : '',
			[this.country]: site.country ? site.country : ''
		});
	}

	setSelectedSite() {
		const selectedSite = this.clientSites.find((site) => site.id === this.siteForm?.controls[this.siteCode].value);
		if (selectedSite) {
			this.selectedSite = selectedSite;
		}
	}

	selectSite(event: MatSelectChange) {
		const site = event.value;
		if (site) {
			this.selectedSite = site;
			this.updateSite(site);
		} else {
			this.selectedSite = {};
			this.clearSite();
		}
		this.siteForm?.markAsDirty();
	}

	createSite() {
		const newSite: ClientSite = {
			clientId: this.contextId,
			name: this.siteForm?.get(this.name)?.value.trim(),
			address1: this.siteForm?.get(this.address1)?.value.trim(),
			address2: this.siteForm?.get(this.address2)?.value.trim(),
			city: this.siteForm?.get(this.city)?.value.trim(),
			state: this.siteForm?.get(this.state)?.value.trim(),
			postal: this.siteForm?.get(this.postal)?.value.trim(),
			country: this.siteForm?.get(this.country)?.value.trim()
		};
		this.busy = true;
		this.clientSiteService.create(newSite).subscribe({
			next: (site) => {
				this.clientSites.push(site);
				this.selectedSite = site;
				this.busy = false;
			},
			error: (err) => {
				this._snackBar.open('Create Client Site Failed: ' + err.error.message);
				this.busy = false;
			}
		});
	}

	modifySite() {
		const modifiedSite: ClientSite = {
			id: this.selectedSite.id,
			clientId: this.selectedSite.clientId,
			name: this.siteForm?.get(this.name)?.value.trim(),
			address1: this.siteForm?.get(this.address1)?.value.trim(),
			address2: this.siteForm?.get(this.address2)?.value.trim(),
			city: this.siteForm?.get(this.city)?.value.trim(),
			state: this.siteForm?.get(this.state)?.value.trim(),
			postal: this.siteForm?.get(this.postal)?.value.trim(),
			country: this.siteForm?.get(this.country)?.value.trim()
		};
		this.busy = true;
		this.clientSiteService.update(modifiedSite).subscribe({
			next: (site) => {
				this.clientSites = this.clientSites.filter((site) => site.id !== modifiedSite.id);
				this.clientSites.push(site);
				this.selectedSite = site;
				this.busy = false;
			},
			error: (err) => {
				this._snackBar.open('Modify Client Site Failed: ' + err.error.message);
				this.busy = false;
			}
		});
	}

	revertSite() {
		this.updateSite(this.selectedSite);
	}

	get canCreate(): boolean {
		return this.context !== ClientSitesComponentContextEnum.INTERNAL && this.contextId !== '' && this.allowCreate;
	}

	get canModify(): boolean {
		return this.allowModify;
	}

	get createDisabled(): boolean {
		return (
			this.siteForm?.get(this.name)?.value.trim() === '' &&
			this.siteForm?.get(this.address1)?.value.trim() === '' &&
			this.siteForm?.get(this.address2)?.value.trim() === '' &&
			this.siteForm?.get(this.city)?.value.trim() === '' &&
			this.siteForm?.get(this.state)?.value.trim() === '' &&
			this.siteForm?.get(this.postal)?.value.trim() === '' &&
			this.siteForm?.get(this.country)?.value.trim() === ''
		);
	}

	get modifyDisabled(): boolean {
		return (
			this.siteForm?.get(this.name)?.value.trim() === this.selectedSite.name?.trim() &&
			this.siteForm?.get(this.address1)?.value.trim() === this.selectedSite.address1?.trim() &&
			this.siteForm?.get(this.address2)?.value.trim() === this.selectedSite.address2?.trim() &&
			this.siteForm?.get(this.city)?.value.trim() === this.selectedSite.city?.trim() &&
			this.siteForm?.get(this.state)?.value.trim() === this.selectedSite.state?.trim() &&
			this.siteForm?.get(this.postal)?.value.trim() === this.selectedSite.postal?.trim() &&
			this.siteForm?.get(this.country)?.value.trim() === this.selectedSite.country?.trim()
		);
	}
}
