import {Component, OnDestroy, OnInit, ViewChild, ChangeDetectorRef} from '@angular/core';
import {ProjectPreview} from '../core/models/project-preview.interface';
import {Status} from '../core/models/status.interface';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {ProjectsService} from '../core/services/projects.service';
import {StatusService} from '../core/services/status.service';
import {MatTableDataSource} from '@angular/material/table';
import {MatSort} from '@angular/material/sort';
import {format} from 'date-fns';
import {BehaviorSubject, finalize, Subscription, take} from 'rxjs';

@Component({
	selector: 'app-projects',
	templateUrl: './projects.component.html',
	styleUrls: ['./projects.component.scss'],
	animations: [
		trigger('detailExpand', [
			state('collapsed', style({height: '0px', minHeight: '0'})),
			state('expanded', style({height: '*'})),
			transition('expanded <=> collapsed', animate('200ms cubic-bezier(0.4, 0.0, 0.2, 1)'))
		])
	]
})
export class ProjectsComponent implements OnInit, OnDestroy {
	dataSource: MatTableDataSource<ProjectPreview> = new MatTableDataSource<ProjectPreview>();
	sort: MatSort;
	@ViewChild(MatSort) set matSort(ms: MatSort) {
		this.sort = ms;
		this.dataSource.sort = this.sort;
	}
	isLoading: boolean = true;
	statuses: Status[];
	activeFilter: string | null;
	nonZeroStatuses: Status[];
	projectPreviews: ProjectPreview[] = [];
	colors: string[] = ['maroon', 'darkBlue', 'purple', 'green', 'yellow'];
	columnsToDisplay = ['name', 'clientId', 'stage', 'project.workflowStatus.description', 'installDate'];
	statusSubscription: Subscription;
	constructor(private projectsService: ProjectsService, private statusService: StatusService, private cdRef: ChangeDetectorRef) {}

	ngOnInit() {
		//	this.statusService.findAll()
		this.statusSubscription = this.statusService.statuses.subscribe((statuses) => {
			this.statuses = statuses;
			this.projectsService.findAll();
			this.projectsService.projects.subscribe((projects) => {
				this.projectPreviews = projects;
				this.dataSource.data = this.projectPreviews;
				this.dataSource.sortingDataAccessor = (item: ProjectPreview, property: string) => {
					switch (property) {
						case 'project.workflowStatus.description':
							return item.workflowStatus.description;
						case 'installDate':
							return item.installDate ? new Date(item.installDate) : new Date(item.estimateDate);
						default:
							return item[property];
					}
				};
				this.countStageQuantities();
			});
		});
	}

	applyFilter(event: Event) {
		const filterValue = (event.target as HTMLInputElement).value;
		this.dataSource.filter = filterValue.trim().toLowerCase();
		this.dataSource.filterPredicate = (data, filter) => {
			const installDate = data.installDate ? new Date(data.installDate) : null;
			const estimateDate = data.estimateDate ? new Date(data.estimateDate) : null;
			// Convert estimateDate/installDate to month name to compare it to the filter value.
			const estimateDateString = estimateDate ? format(estimateDate, 'MMMM').toLowerCase() : null;
			const installDateString = installDate ? format(installDate, 'MMMM').toLowerCase() : null;
			//Convert installDate to MM/DD/YYYY to compare it to the filter value.
			const installNumericDate = installDate ? format(installDate, 'MM/dd/yyyy').toString() : null;
			// Check if either "install date", "estimate date", "name", or "status" matches the filter value
			return (
				(!estimateDate && installDateString && installDateString.includes(filter)) ||
				(!installDate && estimateDateString && estimateDateString.includes(filter)) ||
				(installDate && estimateDate && installDateString && installDateString.includes(filter)) ||
				(installDate && installNumericDate && installNumericDate.includes(filter)) ||
				data.workflowStatus.description.toLowerCase().includes(filter) ||
				data.name.toLowerCase().includes(filter) ||
				data.clientId.toLowerCase().includes(filter)
			);
		};
		this.cdRef.detectChanges();
	}

	setActiveFilter(status: Status) {
		if (this.activeFilter === status.description) {
			this.activeFilter = null;
			this.dataSource.data = this.projectPreviews;
		} else {
			this.activeFilter = status.description;
		}
	}

	clearActiveFilter() {
		this.activeFilter = null;
		this.dataSource.data = this.projectPreviews;
	}

	filterTable(stageId: number): void {
		this.dataSource.data = this.projectPreviews.filter((project) => {
			return project.workflowStatus.subParentId === stageId;
		});
	}

	countStageQuantities() {
		this.statuses.forEach((status: Status) => (status.projectCount = 0));
		for (let project of this.projectPreviews) {
			let stage = this.statuses.find((status: Status) => {
				return status.id === project.workflowStatus.subParentId;
			});
			if (stage) {
				stage.projectCount!++;
			}
		}
		this.nonZeroStatuses = this.statuses.filter((status) => status.projectCount !== 0);
		//FIXME this is not triggering the loading spinner correctly because it's inside the subscribe block.
		this.isLoading = false;
	}

	getStage(parentId: number): string {
		const stage = this.statuses.find((status: Status) => status.id === parentId);
		if (stage) {
			return stage.description;
		} else {
			return 'unknown';
		}
	}

	getWidth(status: Status, index: number): string {
		if (index === this.nonZeroStatuses.length - 1) {
			const width = Math.ceil((status.projectCount! / this.projectPreviews.length) * 100) - 1;
			return width.toString() + '%';
		} else {
			const width = Math.ceil((status.projectCount! / this.projectPreviews.length) * 100);
			return width.toString() + '%';
		}
	}

	getEstimateText(date: Date): string {
		const dateConverted = new Date(date);
		return (+format(dateConverted, 'd') <= 15 ? 'Beginning of ' : 'End of ') + format(dateConverted, 'MMMM');
	}

	ngOnDestroy() {
		this.statusSubscription.unsubscribe();
	}
}
