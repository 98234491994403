import {Component, OnInit} from '@angular/core';
import {ProjectsService} from '../../core/services/projects.service';
import {ActivatedRoute} from '@angular/router';
import {ProjectTask} from '../../core/models/project-task.model';
import {MatSnackBar} from '@angular/material/snack-bar';
import {FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms';

@Component({
	selector: 'app-project-estimate',
	templateUrl: './project-estimate.component.html',
	styleUrls: ['./project-estimate.component.scss']
})
export class ProjectEstimateComponent implements OnInit {
	projectTaskForm = new FormGroup({
		projectTasks: this.fb.array([])
	});

	projectId: number | undefined;

	disableCalc: boolean[] = [];

	busy: boolean = false;

	constructor(
		private projectService: ProjectsService,
		private route: ActivatedRoute,
		private _snackBar: MatSnackBar,
		private fb: FormBuilder
	) {}

	ngOnInit() {
		let currentRoute = this.route;
		while (currentRoute.children[0]) {
			currentRoute = currentRoute.children[0];
		}
		currentRoute.params.subscribe((params) => {
			if (params['projectId']) {
				this.projectId = params['projectId'];
				this.getTasks();
			}
		});
	}

	getTasks() {
		if (this.projectId) {
			this.busy = true;
			this.projectService.getProjectTasks(this.projectId).subscribe({
				next: (projectTasks) => {
					this.projectTasks.clear();
					this.disableCalc = [];
					projectTasks.forEach((projectTask) => this.addProjectTask(projectTask));
					this.busy = false;
				},
				error: (err) => {
					this._snackBar.open('Get Project Tasks Failed: ' + err.error.message);
					this.busy = false;
				}
			});
		}
	}

	// TODO: remove this call and associated button/service when tasks are generated as part of the status flow
	calculateTasks() {
		if (this.projectId) {
			this.busy = true;
			this.projectService.calculateProjectTasks(this.projectId).subscribe({
				next: (projectTasks) => {
					this.projectTasks.clear();
					this.disableCalc = [];
					projectTasks.forEach((projectTask) => this.addProjectTask(projectTask));
					this.busy = false;
				},
				error: (err) => {
					this._snackBar.open('Calculate Project Tasks Failed: ' + err.error.message);
					this.busy = false;
				}
			});
		}
	}

	updateTasks() {
		if (this.projectId) {
			const projectTasks: ProjectTask[] = this.projectTaskForm.controls.projectTasks.value as ProjectTask[];
			this.busy = true;
			this.projectService.updateProjectTasks(this.projectId, projectTasks).subscribe({
				next: (projectTasks) => {
					this.projectTasks.clear();
					this.disableCalc = [];
					projectTasks.forEach((projectTask) => this.addProjectTask(projectTask));
					this.busy = false;
				},
				error: (err) => {
					this._snackBar.open('Update Project Tasks Failed: ' + err.error.message);
					this.busy = false;
				}
			});
		}
	}

	get projectTasks(): FormArray {
		return this.projectTaskForm.controls.projectTasks;
	}

	addProjectTask(projectTask: ProjectTask) {
		this.disableCalc.push(true);
		this.projectTasks.push(
			this.fb.group({
				id: [projectTask.id],
				projectId: [projectTask.projectId],
				taskTypeId: [projectTask.taskTypeId],
				value: [projectTask.value, [Validators.required]],
				hours: [projectTask.hours, [Validators.required]],
				taskType: [projectTask.taskType]
			})
		);
	}

	taskDescription(i: number) {
		return this.projectTasks.controls[i].value.taskType?.name;
	}

	recalculateHours(i: number) {
		const projectTask = this.projectTasks.controls[i].value;
		console.log(projectTask);
		projectTask.hours = projectTask.value * projectTask.taskType.multiplier;
		console.log(projectTask);
		this.projectTasks.controls[i].patchValue(projectTask);
		this.disableCalc[i] = true;
	}

	dirtyRecalculate(i: number) {
		this.disableCalc[i] = false;
	}
}
