import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FormBuilder, FormGroup} from '@angular/forms';
import {Contact} from '../../../../core/models/contact.model';

@Component({
	selector: 'app-request-approval-dialog',
	templateUrl: './request-approval-dialog.component.html',
	styleUrls: ['./request-approval-dialog.component.scss']
})
export class RequestApprovalDialogComponent {
	contactForm: FormGroup;
	clientName: '';
	contacts: Contact[] = [];
	hideMessageInput: boolean = false;
	multipleEmailRegex =
		/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))(,\s*(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,})))*$/;
	DEFAULT_MESSAGE: string = 'MMI Storage Solutions is requesting you review a quote for a project.';

	constructor(
		@Inject(MAT_DIALOG_DATA) public data: any,
		private dialogRef: MatDialogRef<RequestApprovalDialogComponent>,
		private fb: FormBuilder
	) {
		this.contactForm = this.fb.group({
			message: ['']
		});
	}

	onSubmit() {
		if (this.contactForm.valid) {
			const approvalInfo = {
				contacts: this.contacts,
				message: this.contactForm.get('message')?.value
			};
			this.dialogRef.close(approvalInfo);
		}
	}

	close() {
		this.dialogRef.close();
	}

	selectionChanged(event: Contact[]) {
		this.contacts = event;
	}
}
