<div *ngIf="isLoading | async" class="w-full h-full flex justify-center m-2">
	<mat-spinner></mat-spinner>
</div>
<div [ngClass]="{hidden: (isLoading | async)}">
	<div class="flex flex-row m-4">
		<mat-form-field appearance="outline" class="w-1/2">
			<mat-label>Load Rack Solid Template</mat-label>
			<mat-select [(ngModel)]="selectedTemplate" (selectionChange)="templateSelection($event.value)">
				<mat-option *ngFor="let template of templateOptions" [value]="template">{{ template.description }}</mat-option>
				<mat-option *ngIf="templateOptions.length === 0" disabled>No Templates for Client</mat-option>
			</mat-select>
		</mat-form-field>
	</div>
	<div [formGroup]="quoteForm" class="m-4">
		<mat-accordion>
			<mat-expansion-panel expanded="true" class="w-5/6">
				<mat-expansion-panel-header>
					<mat-panel-title class="font-bold"> Options </mat-panel-title>
				</mat-expansion-panel-header>

				<div class="flex flex-row flex-wrap space-x-2">
					<mat-checkbox formControlName="isShelvingOnly" class="mt-6 w-34"> Shelving Only </mat-checkbox>
					<mat-checkbox formControlName="needsExtraParts" class="mt-6 w-34"> Extra Parts </mat-checkbox>

					<div class="flex flex-col">
						<div class="flex flex-row mb-2">
							<mat-label class="ml-4"># of Static Units</mat-label>
						</div>

						<div class="flex flex-row flex-wrap space-x-2">
							<mat-form-field appearance="outline" class="ml-2">
								<mat-label>Starters</mat-label>
								<input matInput type="number" min="0" formControlName="staticStarters" />
							</mat-form-field>

							<mat-form-field appearance="outline">
								<mat-label>Adders</mat-label>
								<input matInput type="number" min="0" formControlName="staticAdders" />
							</mat-form-field>

							<mat-form-field appearance="outline">
								<mat-label>Price Level</mat-label>
								<mat-select formControlName="priceLevelId">
									<mat-option *ngFor="let priceLevel of priceLevels | async" [value]="priceLevel.id">
										{{ priceLevel.name }}
									</mat-option>
								</mat-select>
							</mat-form-field>

							<mat-form-field appearance="outline">
								<mat-label>B2B Kits</mat-label>
								<input min="0" matInput formControlName="b2bKits" />
							</mat-form-field>
						</div>
					</div>
				</div>
			</mat-expansion-panel>
		</mat-accordion>

		<app-quote-config
			*ngFor="let configForm of configs.controls; let i = index"
			formArrayName="configs"
			[index]="i"
			[typeOptions]="typeOptions"
			[partArray]="shelves"
			(copyConfigEvent)="copyConfig($event)"
			(deleteConfigEvent)="deleteConfig($event)"
			(typePlaceHolderEvent)="setPlaceholder($event)"
		></app-quote-config>

		<div class="flex flex-row w-5/6 justify-end mt-2">
			<button mat-raised-button color="primary" (click)="addConfig()">ADD CONFIG</button>
		</div>

		<div class="w-5/6">
			<app-part-list
				*ngIf="partCatalog | async as parts"
				[quote]="quoteForm.valueChanges | async"
				[partCatalog]="parts"
				(updateQuoteParts)="updateQuoteParts($event)"
			></app-part-list>
		</div>

		<div class="flex flex-row w-5/6 justify-end space-x-2">
			<button (click)="saveTemplate()" mat-button>SAVE AS TEMPLATE</button>
			<button mat-raised-button color="primary" (click)="saveQuote()">SAVE QUOTE</button>
			<button mat-raised-button color="primary" (click)="addItemsToQuote()">ADD ITEMS TO QUOTE</button>
		</div>
	</div>
</div>
